import React, { useEffect, useState } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import { Box, Button, Flex, Spacer, Text, useToast } from "@chakra-ui/react";
import i18next from "i18next";
import {
  CreateOrUpdateRuleFormSchema,
  RuleViewModel,
  viewModel2CreateOrUpdateRuleCommand,
} from "../common/schema";
import { GenericInput } from "app/shared/forms/GenericInput";
import { ErrorResponse, isErrorResponse } from "models";
import { handleFormError } from "app/shared/forms/utils";
import { observer } from "mobx-react";
import { CriteriaSelectionField, RULE_LABELS_WIDTH } from "../common";
import { useCriteriaStoresContext } from "app/criteria/stores/CriteriaStore";
import { FormActions } from "app/shared/forms/FormActions";
import { CriteriaType } from "app/criteria/models";
import { SubsidyUnit, SubsidyUnitComponent } from "../common/subsidy-unit";
import { handleAxiosError } from "utils/ErrorEventHandler";
import { useRuleStoreContext } from "app/rules/stores";
import { RuleItemType } from "app/rules/models";

const emptyState: RuleViewModel = {
  name: "",
  baseSubsidy: 0,
  distanceSubsidy: null,
  maxSubsidy: 0,
  items: [],
  percentageSubsidy: false,
};

interface Props {
  programmeId: string;
  onFormSubmitted: () => void;
  onFormSubmitionCanceled: () => void;
  onFormSubmitionError: (errorResponse: ErrorResponse, msg: string) => void;
}

const CreateRuleFormBase: React.FC<Props> = (props: Props) => {
  const { programmeId } = props;
  const toast = useToast();

  const formI18nPrefix = "rules:form.create";
  const { ruleStore } = useRuleStoreContext();
  const { criteriaStore } = useCriteriaStoresContext();
  const [, setLoaded] = useState(false);

  useEffect(() => {
    criteriaStore
      .fetchCriteriaPage(programmeId, 0, 10000, "name", "asc")
      .then(() => setLoaded(true))
      .catch((error) => handleAxiosError(error));
  }, [criteriaStore, programmeId]);

  const enoughCriteriaToCreateRule = (): boolean => {
    return (
      criteriaStore.criteria &&
      criteriaStore.criteria.length > 1 &&
      criteriaStore.criteria.some((c) => c.type === CriteriaType.Geo) &&
      criteriaStore.criteria.some((c) => c.type === CriteriaType.Schedule)
    );
  };

  const submitHandler = async (
    formValues: RuleViewModel,
    helpers: FormikHelpers<any>
  ) => {
    if (ruleStore.state === "pending") return;
    const cmd = viewModel2CreateOrUpdateRuleCommand(formValues);
    if (
      cmd.items.length > 1 &&
      cmd.items.some((c) => c.type === RuleItemType.Geographic) &&
      cmd.items.some((c) => c.type === RuleItemType.Generic)
    ) {
      helpers.setSubmitting(true);
      const response = await ruleStore.createRule(programmeId, cmd);
      console.log(response);
      helpers.setSubmitting(false);
      if (isErrorResponse(response)) {
        handleFormError(
          response,
          helpers,
          props.onFormSubmitionError,
          formI18nPrefix
        );
      } else {
        props.onFormSubmitted();
      }
    } else {
      toast({
        title: i18next.t("rules:form.create.notEnoughCriteria"),
        status: "error",
        isClosable: true,
      });
    }
  };

  return (
    <Box>
      {!enoughCriteriaToCreateRule() && (
        <Box>
          <Text>{i18next.t("rules:form.create.notEnoughCriteria")}</Text>
          <Flex>
            <Spacer />
            <Button onClick={props.onFormSubmitionCanceled} mt={3}>
              {i18next.t("shared:form.close")}
            </Button>
          </Flex>
        </Box>
      )}
      {enoughCriteriaToCreateRule() && (
        <Formik
          initialValues={emptyState}
          validationSchema={CreateOrUpdateRuleFormSchema}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={submitHandler}
        >
          {(formikProps) => (
            <Form>
              <Box py={2}>
                <GenericInput
                  i18nextPrefix="rules"
                  formName="common"
                  fieldName="name"
                  fieldType="string"
                  labelWidth={RULE_LABELS_WIDTH}
                />
                <SubsidyUnitComponent
                  formikProps={formikProps}
                  defaultSusbsidyUnit={SubsidyUnit.MonetaryValue}
                />
              </Box>
              <CriteriaSelectionField
                criteria={criteriaStore.criteria}
                initialRuleItems={[]}
                onRuleItemListUpdated={(items: any) => {
                  return formikProps.setFieldValue("items", items);
                }}
                fieldName="items"
              />
              <FormActions
                submitText={i18next.t("rules:form.create.submit")}
                cancelHandler={props.onFormSubmitionCanceled}
                disableSubmit={ruleStore.state === "pending"}
              />
            </Form>
          )}
        </Formik>
      )}
    </Box>
  );
};

export const CreateRuleForm = observer(CreateRuleFormBase);
