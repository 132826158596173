import { ErrorResponse } from "./../../../../models/index";
import {
  Account,
  AccountListItem,
  CreateOrUpdateAccountCommand,
} from "./../models/commands";
import { PageResponse, SortOrder } from "models";
import { axios } from "network";
import { handleAxiosError } from "utils/ErrorEventHandler";

export const getAccounts = async (
  page: number,
  size: number,
  sortField?: string,
  sortOrder?: SortOrder
): Promise<PageResponse<AccountListItem>> => {
  try{
  const axiosInstance = await axios();
  const paginationParams = `/admin/accounts?page[number]=${page}&page[size]=${size}${
    sortField && sortOrder ? `&sort[${sortField}]=${sortOrder}` : ""
  }`;
  const rawResponse = await axiosInstance.get<PageResponse<AccountListItem>>(
    paginationParams
  );
  return { ...rawResponse.data };}
    catch (error) {
    handleAxiosError(error);
    return error.response.data;
  }
};

export const createAccount = async (
  cmd: CreateOrUpdateAccountCommand
): Promise<Account | void> => {
  const axiosInstance = await axios();
  return axiosInstance
    .post<Account>("/admin/accounts", cmd)
    .then((response) => response.data)
    .catch((error) => {
      handleAxiosError(error);
      return error.response.data;
    });
};

export const deleteAccount = async (
  accountId: string
): Promise<unknown | ErrorResponse> => {
  const axiosInstance = await axios();
  return axiosInstance
    .delete<void>(`/admin/accounts/${accountId}`)
    .then((response) => response.data)
    .catch((error) => {
      handleAxiosError(error);
      return error.response.data;
    });
};
