import {
  Box,
  Button,
  Center,
  Flex,
  Grid,
  Heading,
  Icon,
  ListItem,
  Stack,
  Text,
  UnorderedList,
  Table,
  Tbody,
  Tr,
  Th,
  Td,
  TableCaption,
  Select,
} from "@chakra-ui/react";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { format, formatDate } from "utils";
import { useProgrammeStoreContext } from "../stores/ProgrammeStore";
import i18next from "i18next";
import { SortOrder } from "models";
import { BiPencil } from "react-icons/bi";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { TripRequest } from "../models";
import { ContentArea } from "app/shared";
import { NavLink, RouteComponentProps } from "react-router-dom";

interface Props {
  programmeId: string;
}

const TripRequestTelemetryBase: React.FC<Props> = ({ programmeId }: Props) => {
  const { programmeStore } = useProgrammeStoreContext();
  const [page, setPage] = useState(0);
  const [sortField, setSortField] = useState("created_at");
  const [sortOrder, setSortOrder] = useState<SortOrder>("desc");
  const [sortType, setSortType] = useState("");
  const resultsSize = 30;

  useEffect(() => {
    programmeStore.fetchProgrammeTripRequests(
      programmeId,
      page,
      resultsSize,
      sortField,
      sortOrder,
      sortType
    );
  }, [programmeId, page, sortOrder, sortField, programmeStore, sortType]);

  const dateTimeTemplate = (row: TripRequest) => {
    return format(row.createdAt, "YYYY-MM-DD HH:mm:ss");
  };
  return (
    <Box>
      <Flex flexDir="row" justifyContent="space-between" alignItems="center">
        <Heading mb={4}>
          {i18next.t("programmes:dashboard.requestsList.title")}
        </Heading>
        <Select
          onChange={(e: any) => setSortType(e.target.value)}
          width="15%"
          placeholder="All"
          size="lg"
        >
          <option value="Confirm">Confirmed</option>
        </Select>
      </Flex>
      {programmeStore.tripRequestState !== "error" && (
        <DataTable
          paginatorTemplate="RowsPerPageDropdown PageLinks FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          paginator
          lazy={true}
          rows={resultsSize}
          value={programmeStore.tripRequests}
          first={page * resultsSize}
          onPage={(e: any) => {
            setPage(e.page);
          }}
          totalRecords={programmeStore.totalTripRequests}
          sortField={sortField}
          sortOrder={sortOrder === "asc" ? 1 : -1}
          onSort={(e) => {
            setSortField(e.sortField);
            setSortOrder(e.sortOrder === 1 ? "asc" : "desc");
          }}
        >
          <Column
            field="createdAt"
            header={i18next.t("programmes:dashboard.requestsList.createdAt")}
            sortable={true}
            sortField="created_at"
            body={dateTimeTemplate}
          />
          <Column
            field={"requestType"}
            header={i18next.t("programmes:dashboard.requestsList.requestType")}
            sortable={true}
            sortField="type"
          />
          <Column
            field="rideProvider"
            header={i18next.t("programmes:dashboard.requestsList.rideProvider")}
          />
          <Column
            field={"totalSubsidies"}
            header={i18next.t(
              "programmes:dashboard.requestsList.totalSubsidies"
            )}
          />
        </DataTable>
      )}
    </Box>
  );
};
const TripRequestTelemetry = observer(TripRequestTelemetryBase);

const ProgrammeDescriptiveInfoBase: React.FC<Props> = ({ programmeId }) => {
  const { programmeStore } = useProgrammeStoreContext();

  const dateTemplate = (date: string | undefined) => {
    return date ? formatDate(date) : "";
  };

  const calculateRemainingBudgetPercent = () => {
    return programmeStore.selectedProgramme
      ? (
          (programmeStore.selectedProgramme!!.remainingBudget * 100) /
          programmeStore.selectedProgramme!!.budget
        ).toFixed(2)
      : null;
  };
  return (
    <>
      <Grid templateColumns="repeat(2, 1fr)">
        <div>
          <Stack direction="row">
            <Text fontSize="2xl" fontWeight="600">
              {programmeStore?.selectedProgramme?.name}
            </Text>
          </Stack>

          <Table
            className="itemBorderColor"
            borderWidth="2px"
            borderRadius="0"
            size="sm"
            variant="simple"
            colorScheme="teal"
          >
            <TableCaption placement="top">Dates</TableCaption>
            <Tbody>
              <Tr>
                <Th>{i18next.t("programmes:dashboard.timezone")}</Th>
                <Td>{programmeStore.selectedProgramme?.timezone}</Td>
              </Tr>
              <Tr>
                <Th>{i18next.t("programmes:dashboard.startDate")}</Th>
                <Td>
                  {dateTemplate(programmeStore.selectedProgramme?.startDate)}
                </Td>
              </Tr>
              <Tr>
                <Th>{i18next.t("programmes:dashboard.endDate")}</Th>
                <Td>
                  {dateTemplate(programmeStore.selectedProgramme?.endDate)}
                </Td>
              </Tr>
            </Tbody>
          </Table>

          <Table
            className="itemBorderColor"
            borderWidth="2px"
            borderRadius="0"
            size="sm"
            variant="simple"
            colorScheme="teal"
          >
            <TableCaption placement="top"> Monetary </TableCaption>
            <Tbody>
              <Tr>
                <Th>{i18next.t("programmes:dashboard.budget")}</Th>
                <Td>{programmeStore.selectedProgramme?.budget}</Td>
              </Tr>
              <Tr>
                <Th>{i18next.t("programmes:dashboard.remainingBudget")}</Th>
                <Td>{calculateRemainingBudgetPercent()} %</Td>
              </Tr>
              <Tr>
                <Th>{i18next.t("programmes:dashboard.currency")}</Th>
                <Td>{programmeStore.selectedProgramme?.currency}</Td>
              </Tr>
            </Tbody>
          </Table>
        </div>
        <div>
          <Box
            maxW="sm"
            className="rideProviderBox"
            borderWidth="2px"
            borderRadius="0"
            overflow="hidden"
          >
            <Box p="1">
              <Box display="flex" alignItems="right"></Box>
              <Box
                mt="1"
                fontWeight="semibold"
                as="h4"
                lineHeight="tight"
                isTruncated
              >
                <Text textAlign="center">
                  {i18next.t("programmes:dashboard.rideProviders")}
                </Text>
                <UnorderedList pl={3}>
                  {programmeStore.selectedProgramme?.rideProviders?.map(
                    (rp) => (
                      <ListItem key={rp.id}>{rp.name}</ListItem>
                    )
                  )}
                </UnorderedList>
              </Box>
            </Box>
          </Box>
        </div>
        <NavLink className="dashboardLink" to={`/programs/${programmeId}/details`}>
          <Button marginTop="3rem" border="2px" borderColor="#12a19a">
            {i18next.t("programmes:dashboard.edit")}
            <Icon as={BiPencil} boxSize="20px" />
          </Button>
        </NavLink>
      </Grid>
    </>
  );
};

const ProgrammeDescriptiveInfo = observer(ProgrammeDescriptiveInfoBase);

const TripFunnelBase: React.FC<Props> = ({ programmeId }) => {
  const { programmeStore } = useProgrammeStoreContext();
  const [numRender, setNumRender] = useState(0);

  useEffect(() => {
    setNumRender(numRender + 1);
      programmeStore.fetchTripRequestStats(programmeId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programmeStore, programmeId]);

  return (
    <>
      <Text fontSize="2xl" fontWeight="600">
        Trip request statistics
      </Text>
      <Grid templateColumns="repeat(3, 1fr)" gap={6}>
        <Box w="100%" p={4} bg="gray.200">
          <Stack>
            <Center>
              <Text fontSize="md">Quotes</Text>
            </Center>
            <Center>
              <Text fontSize="2xl" fontWeight="600">
                {programmeStore.stats.quoteCount} (
                {programmeStore.stats.quotePct.toFixed(2)}%)
              </Text>
            </Center>
          </Stack>
        </Box>
        <Box w="100%" p={4} bg="gray.300">
          <Stack>
            <Center>
              <Text fontSize="md">Initiated</Text>
            </Center>
            <Center>
              <Text fontSize="2xl" fontWeight="600">
                {programmeStore.stats.initiateCount} (
                {programmeStore.stats.initiatePct.toFixed(2)}%)
              </Text>
            </Center>
          </Stack>
        </Box>
        <Box w="100%" p={4} bg="gray.400">
          <Stack>
            <Center>
              <Text fontSize="md">Confirmed</Text>
            </Center>
            <Center>
              <Text fontSize="2xl" fontWeight="600">
                {programmeStore.stats.confirmCount} (
                {programmeStore.stats.confirmPct.toFixed(2)}%)
              </Text>
            </Center>
          </Stack>
        </Box>
      </Grid>
    </>
  );
};

const TripFunnel = observer(TripFunnelBase);

interface Params {
  id: string;
}

type Propss = RouteComponentProps<Params>;

const DashboardBase: React.FC<Propss> = ({ match }) => {
  const programmeId = match.params.id;
  return (
    <ContentArea>
      <Stack dir="column" spacing="3">
        <Grid templateColumns="repeat(2, 1fr)" gap={2}>
          <Box>
            <ProgrammeDescriptiveInfo programmeId={programmeId} />
          </Box>
          <Box>
            <TripFunnel programmeId={programmeId} />
          </Box>
        </Grid>

        {/* Trip request telemetry */}
        <TripRequestTelemetry programmeId={programmeId} />
      </Stack>
    </ContentArea>
  );
};

export const Dashboard = observer(DashboardBase);
