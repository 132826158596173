import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertStatus,
  Box,
  Button,
  Flex,
  Heading,
  useToast,
  PopoverContent,
  Popover,
  PopoverCloseButton,
  PopoverTrigger,
  PopoverArrow,
  PopoverBody
} from "@chakra-ui/react";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";
import { ManagedModal } from "app/shared/modal";
import i18next from "i18next";
import { observer } from "mobx-react";
import { ErrorResponse, isErrorResponse, SortOrder } from "models";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import { BiPencil, BiTrash } from "react-icons/bi";
import { Subsidizer } from "./models";
import { deleteSubsidizer } from "./services";
import { useSubsidizerStoreContext } from "./stores";
import { UpdateSubsidizerForm } from "./forms/update";
import { ListActionButton } from "app/shared/list/ListActionButton";
import { InfoOutlineIcon } from "@chakra-ui/icons";

const SubsidizerTableBase: React.FC = () => {
  const { subsidizerStore } = useSubsidizerStoreContext();
  const [page, setPage] = useState(0);
  const [sortField, setSortField] = useState("name");
  const [sortOrder, setSortOrder] = useState<SortOrder>("asc");
  const resultsSize = 30;
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [clickedRow, setClickedRow] = useState<Subsidizer | null>(null);
  const [openUpdateSubsidizerModal, setOpenUpdateSubsidizerModal] = useState(
    false
  );
  const cancelRef = undefined;
  const toast = useToast();

  useEffect(() => {
    subsidizerStore.fetchSubsidizers(page, resultsSize, sortField, sortOrder);
  }, [subsidizerStore, page, resultsSize, sortField, sortOrder]);

  const onCancelDeleteSubsidizer = () => {
    setOpenDeleteDialog(false);
    setClickedRow(null);
  };

  const deleteSubsidizerAction = async () => {
    if (clickedRow !== null) {
      const subsidizer = clickedRow;
      const response = await deleteSubsidizer(subsidizer.id);
      setClickedRow(null);
      setOpenDeleteDialog(false);
      if (isErrorResponse(response)) {
        let msgTitle = 'subsidizers:subsidizerTable.delete.error';
        let status: AlertStatus = 'error'
        if(response.httpStatusCode === 409){
          msgTitle = 'subsidizers:subsidizerTable.delete.isBeingUsed';
          status = 'warning'
        }
        
        toast({
          title: i18next.t(msgTitle),
          status: status,
          isClosable: true
        });
      } else {
        toast({
          title: i18next.t("subsidizers:subsidizerTable.delete.success"),
          status: "success",
          isClosable: true,
        });
        subsidizerStore.fetchSubsidizers(
          page,
          resultsSize,
          sortField,
          sortOrder
        );
      }
    } else {
      console.warn("Expecting row to have non-null content.");
    }
  };

  const deleteButtonTemplate = (rowData: Subsidizer) => {
    return (
      <ListActionButton
        icon={BiTrash}
        clickHandler={() => {
          setOpenDeleteDialog(true);
          setClickedRow(rowData);
        }}
      />
    );
  };

  const updateButtonTemplate = (rowData: Subsidizer) => {
    return (
      <ListActionButton
        icon={BiPencil}
        clickHandler={() => {
          setClickedRow(rowData);
          setOpenUpdateSubsidizerModal(true);
        }}
      />
    );
  };

  const onSubsidizerUpdateSuccess = () => {
    toast({
      title: i18next.t("subsidizers:form.update.success"),
      status: "success",
      isClosable: true,
    });
    setOpenUpdateSubsidizerModal(false);
    setClickedRow(null);
    subsidizerStore.fetchSubsidizers(page, resultsSize, sortField, sortOrder);
  };

  const upSubsidizerUpdateError = (error: ErrorResponse, msg: string) => {
    toast({
      title: msg,
      status: "error",
      isClosable: true,
    });
    setOpenUpdateSubsidizerModal(false);
    setClickedRow(null);
  };

  return (
    <>
      <Box>
        <Flex flexDir="row" alignItems="center">
          <Heading m={4}>
            {i18next.t("subsidizers:subsidizerTable.header")}
          </Heading>
          <Popover  gutter={15} isLazy>
            <PopoverTrigger>
              <InfoOutlineIcon />
            </PopoverTrigger>
            <PopoverContent>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverBody fontSize="md" fontWeight="400">
              {i18next.t("subsidizers:subsidizerTable.description")}
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Flex>
        {subsidizerStore.state !== "error" && (
          <DataTable
            paginatorTemplate="RowsPerPageDropdown PageLinks FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            paginator
            lazy={true}
            rows={resultsSize}
            value={subsidizerStore.subsidizers}
            first={page * resultsSize}
            onPage={(e: any) => {
              setPage(e.page);
            }}
            totalRecords={subsidizerStore.totalSubsidizers}
            sortField={sortField}
            sortOrder={sortOrder === "asc" ? 1 : -1}
            onSort={(e) => {
              setSortField(e.sortField);
              setSortOrder(e.sortOrder === 1 ? "asc" : "desc");
            }}>
            <Column
              field="name"
              header={i18next.t("subsidizers:subsidizerTable.columns.name")}
              sortable={true}
            />
            <Column
              field="description"
              header={i18next.t(
                "subsidizers:subsidizerTable.columns.description"
              )}
              sortable={true}
            />
            <Column
              body={updateButtonTemplate}
              headerStyle={{ width: "8em", textAlign: "center" }}
              bodyStyle={{ textAlign: "center", overflow: "visible" }}
            />
            <Column
              body={deleteButtonTemplate}
              headerStyle={{ width: "8em", textAlign: "center" }}
              bodyStyle={{ textAlign: "center", overflow: "visible" }}
            />
          </DataTable>
        )}

        {/* update rule modal */}
        {clickedRow && (
          <ManagedModal
            openModal={openUpdateSubsidizerModal}
            title={i18next.t("subsidizers:form.update.title")}
            content={
              <UpdateSubsidizerForm
                subsidizer={clickedRow}
                onFormSubmitionError={upSubsidizerUpdateError}
                onFormSubmitted={onSubsidizerUpdateSuccess}
                onFormSubmitionCanceled={() => setOpenUpdateSubsidizerModal(false)}
              />
            }
            closeHandler={() => setOpenUpdateSubsidizerModal(false)}
            showMainAction={false}
            showClose={false}
            closeOnOverlayClick={false}
          />
        )}
      </Box>

      <AlertDialog
        isOpen={openDeleteDialog}
        leastDestructiveRef={cancelRef}
        onClose={onCancelDeleteSubsidizer}
        closeOnEsc={false}
        closeOnOverlayClick={false}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {i18next.t("subsidizers:subsidizerTable.deleteDialog.header")}
            </AlertDialogHeader>

            <AlertDialogBody>
              {i18next.t("subsidizers:subsidizerTable.deleteDialog.body", {
                value: clickedRow?.name,
              })}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={onCancelDeleteSubsidizer}
                disabled={subsidizerStore.state === "pending"}>
                {i18next.t("subsidizers:subsidizerTable.deleteDialog.cancel")}
              </Button>
              <Button
                colorScheme="orange"
                onClick={deleteSubsidizerAction}
                ml={3}
                isLoading={subsidizerStore.state === "pending"}>
                {i18next.t("subsidizers:subsidizerTable.deleteDialog.confirm")}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

export const SubsidizerTable = observer(SubsidizerTableBase);
