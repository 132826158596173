import i18next from "i18next";
import React from "react";
import { GenericSelect } from "./GenericSelect";
import TIMEZONES from "../timezones/timezones.json";
import environment from "configurations";
import { SelectOption } from "../select";

export interface Props {
  i18nextPrefix: string;
  formName: string;
  fieldName: string;
  disabled?: boolean;
  width?: number;
  menuWidth?: number;
  placeholder?: string;
  labelWidth?: string;
  selectedOption?: SelectOption;
}

export const TimezoneSelector: React.FC<Props> = (props: Props) => {
  return (
    <GenericSelect
      fieldType="string"
      options={TIMEZONES.map((timezone) => ({
        value: timezone,
        label: i18next.t(`shared:${timezone}`),
      }))}
      selectedOption={
        props.selectedOption ??
        {
          value: environment.defaults.timezone,
          label: i18next.t(`shared:${environment.defaults.timezone}`)
        }
      }
      {...props}
    />
  );
};
