import { Button } from "@chakra-ui/button";
import Icon from "@chakra-ui/icon";
import * as React from "react";
import { IconType } from "react-icons/lib";

export interface ListActionButtonProps {
  icon: IconType;
  clickHandler: (row: any) => void;
  title?: string;
}

export const ListActionButton: React.FC<ListActionButtonProps> = (props: ListActionButtonProps) => {

  const onClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    props.clickHandler(e);
  }

  return (
    <Button
      title={props.title}
      type="button"
      onClick={onClick}
      variant="danger">
      <Icon as={props.icon} boxSize="20px" />
    </Button>
  );
}