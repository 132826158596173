import { ErrorResponse, PageResponse, SortOrder } from "models";
import { axios } from "network";
import { Rule, RuleListItem } from "../models";
import { CreateOrUpdateRuleCommand } from "../models/commands";

export const getRulePage = async (
  programmeId: string,
  page: number,
  size: number,
  sortField?: string,
  sortOrder?: SortOrder
): Promise<PageResponse<RuleListItem>> => {
  const axiosInstance = await axios();
  const paginationParams = `/programmes/${programmeId}/rules?page[number]=${page}&page[size]=${size}${
    sortField && sortOrder ? `&sort[${sortField}]=${sortOrder}` : ""
  }`;
  const rawResponse = await axiosInstance.get<PageResponse<RuleListItem>>(
    paginationParams
  );
  return { ...rawResponse.data };
};

export const getRule = async (
  programmeId: string,
  id: string
): Promise<Rule | ErrorResponse> => {
  try {
    const axiosInstance = await axios();
    const response = await axiosInstance.get<Rule>(
      `/programmes/${programmeId}/rules/${id}`
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

export const createRule = async (
  programmeId: string,
  cmd: CreateOrUpdateRuleCommand
): Promise<Rule | ErrorResponse> => {
  try {
    const axiosInstance = await axios();
    const response = await axiosInstance.post<Rule>(
      `/programmes/${programmeId}/rules`,
      cmd
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

export const updateRule = async (
  programmeId: string,
  ruleId: string,
  cmd: CreateOrUpdateRuleCommand
): Promise<Rule | ErrorResponse> => {
  try {
    const axiosInstance = await axios();
    const response = await axiosInstance.put<Rule>(
      `/programmes/${programmeId}/rules/${ruleId}`,
      cmd
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};


export const deleteRule = async (
  programmeId: string,
  ruleId: string
): Promise<{ success: true } | ErrorResponse> => {
  try {
    const axiosInstance = await axios();
    await axiosInstance.delete<void>(
      `/programmes/${programmeId}/rules/${ruleId}`
    );
    return { success: true };
  } catch (err) {
    return err.response.data;
  }
};