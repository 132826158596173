import { Button, Stack, useToast } from "@chakra-ui/react";
import { ManagedModal } from "app/shared/modal";
import i18next from "i18next";
import { observer } from "mobx-react";
import { ErrorResponse, SortOrder } from "models";
import React, { useEffect, useState } from "react";
import { CreateRuleForm } from "./forms/create";
import { RuleTable } from "./RuleTable";
import { useRuleStoreContext } from "./stores";
import { useProgrammeStoreContext } from "app/programmes/stores/ProgrammeStore";

interface Props {
  programmeId: string;
}

const RuleListBase: React.FC<Props> = ({ programmeId }) => {
  const [openCreateRuleModal, setOpenCreateRuleModal] = useState(false);
  const [newRuleCreated, setNewRuleCreated] = useState(false);
  const { ruleStore } = useRuleStoreContext();
  const { programmeStore } = useProgrammeStoreContext();
  const [sortField, ] = useState("name"); 
  const [sortOrder, ] = useState<SortOrder>("asc");

  const toast = useToast();

  // After the create rule form is submitted and validated
  const onRuleCreatedSuccess = () => {
    toast({
      title: i18next.t("rules:form.create.success"),
      status: "success",
      isClosable: true,
    });

    setOpenCreateRuleModal(false);
    setNewRuleCreated(true);
    // TODO: review these defaults
    programmeStore.fetchProgramme(programmeId);
    ruleStore.fetchRules(programmeId, 0, 30, sortField, sortOrder);
  };

  const onRuleCreateError = (error: ErrorResponse, msg: string) => {
    toast({ title: msg, status: "error", isClosable: true });
    setOpenCreateRuleModal(false);
  };

  useEffect(() => {
    ruleStore
      .fetchRules(programmeId, 0, 30, sortField, sortOrder)
      .then(() => setNewRuleCreated(false));
  }, [ruleStore, programmeId, sortField, sortOrder, newRuleCreated]);

  return (
    <Stack dir="column" spacing="3">
      <Button onClick={() => setOpenCreateRuleModal(true)}>
        {i18next.t("rules:form.create.openBtn")}
      </Button>
      <RuleTable programmeId={programmeId} />

      {/* create rule modal */}
      <ManagedModal
        openModal={openCreateRuleModal}
        title={i18next.t("rules:form.create.title")}
        content={
          <CreateRuleForm
            programmeId={programmeId}
            onFormSubmitted={onRuleCreatedSuccess}
            onFormSubmitionCanceled={() => {
              setOpenCreateRuleModal(false);
            }}
            onFormSubmitionError={onRuleCreateError}
          />
        }
        closeHandler={() => setOpenCreateRuleModal(false)}
        contentMinHeight={"350px"}
        showMainAction={false}
        showClose={false}
        closeOnOverlayClick={false}
      />
    </Stack>
  );
};

export const RuleList = observer(RuleListBase);
