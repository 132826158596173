import { action, makeObservable, observable, runInAction } from "mobx";
import { RideProvider, RideProviderListItem } from "../models";
import {
  BackendResponseStatus,
  isErrorResponse,
  PageResponse,
  SortOrder,
} from "models";
import { getRideProvider, getRideProviders, createRideProvider, updateRideProvider } from "../services";
import { CreateOrUpdateRideProviderCommand } from "../models/commands";

export default class RideProviderStore {
  rideProviders: RideProviderListItem[] = [];
  state: BackendResponseStatus = "pending";
  totalRideProviders = 0;
  selectedRideProvider: RideProvider | null = null;

  constructor() {
    makeObservable(this, {
      rideProviders: observable,
      totalRideProviders: observable,
      state: observable,
      fetchRideProviders: action,
      fetchRideProvider: action,
      createRideProvider: action,
      updateRideProvider: action
    });
  }

  async fetchRideProviders(
    page: number,
    limit: number,
    sortField?: string,
    sortOrder?: SortOrder
  ) {
    try {
      runInAction(() => {
        this.state = "pending";
      });
      const pagedResults: PageResponse<RideProviderListItem> = await getRideProviders(
        page,
        limit,
        sortField,
        sortOrder
      );
      runInAction(() => {
        this.state = "done";
        this.rideProviders = pagedResults.data;
        this.totalRideProviders = pagedResults.meta.totalElements;
      });
    } catch (error) {
      this.state = "error";
    }
  }

  async fetchRideProvider(id: string) {
    runInAction(() => {
      this.state = "pending";
    });
    const response = await getRideProvider(id);
    runInAction(() => {
      if (isErrorResponse(response)) {
        this.state = "error";
        this.selectedRideProvider = null;
      } else {
        this.state = "done";
        this.selectedRideProvider = response;
      }
    });
  }
  
  async createRideProvider(cmd: CreateOrUpdateRideProviderCommand) {
    runInAction(() => {
      this.state = "pending";
    });
    const response = await createRideProvider(cmd);
    runInAction(() => {
      if (isErrorResponse(response)) {
        this.state = "error";
      } else {
        this.state = "done";
        console.log(response)
      }
    });
    return response;
  }

  async updateRideProvider(rideProviderId: string, cmd: CreateOrUpdateRideProviderCommand) {
    runInAction(() => {
      this.state = "pending";
    });
    const response = await updateRideProvider(rideProviderId, cmd);
    runInAction(() => {
      if (isErrorResponse(response)) {
        this.state = "error";
      } else {
        this.state = "done";
      }
    });
  }
}
