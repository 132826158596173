import { Permission } from "./constants";

export const checkPermissions = (
  userPermissions: string[],
  requiredPermission: Permission
) => {
  if (requiredPermission.length === 0) {
    return true;
  }

  const [requiredResource] = requiredPermission.split(":");

  const isSuperAdmin = userPermissions.some(p => p === "superadmin:*");
  const isAdmin = userPermissions.some(p => p === "admin:*");
  const hasWildcardPermission = userPermissions.some((permission) => {
    const [resource, permType] = permission.split(":");
    return resource === requiredResource && permType.indexOf("*") === 0
  });

  return isSuperAdmin || (
    requiredResource !== 'superadmin' && 
      (isAdmin
        || hasWildcardPermission
        || userPermissions.some((permission) => requiredPermission.includes(permission))));
};
