import React from "react";
import { Form, Formik } from "formik";
import i18next from "i18next";
import {
  CreateOrUpdateRideProviderFormSchema,
  CreateOrUpdateRideProviderFormViewModel,
  viewModel2CreateOrUpdateRideProviderCommand
} from "../common/schema";
import { FormActions } from "app/shared/forms/FormActions";
import { GenericInput } from "app/shared/forms/GenericInput";
import { useRideProviderStoresContext } from "app/rideProviders/stores/context";

interface CreateRideProviderFormProps {
  formSubmittionCanceled: () => void;
  formSubmitted: () => void;
}

export const CreateRideProviderForm: React.FC<CreateRideProviderFormProps> = (props: CreateRideProviderFormProps) => {

  const { rideProviderStore } = useRideProviderStoresContext();

  const defaultEmptyState: CreateOrUpdateRideProviderFormViewModel = {
    name: ""
  };
  
  const submitHandler = async (formValues: CreateOrUpdateRideProviderFormViewModel) => {
    if(rideProviderStore.state === 'pending') return;
    
    // TODO parse response. Now we are extracting data from response on services, not sure if it make sense
    await rideProviderStore.createRideProvider(viewModel2CreateOrUpdateRideProviderCommand(formValues));
    props.formSubmitted()
  };

  return (
    <Formik
      initialValues={defaultEmptyState}
      validationSchema={CreateOrUpdateRideProviderFormSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={submitHandler}>
      {(formikProps) => 
        <Form>
          <GenericInput
            autoFocus={true}
            i18nextPrefix="ride-providers"
            formName="create"
            fieldName="name"
            fieldType="string"
          />
          <FormActions 
            submitText={i18next.t("ride-providers:rideProvidersTable.forms.mainAction")}
            cancelHandler={props.formSubmittionCanceled}
            disableSubmit={rideProviderStore.state === "pending"}
            />
        </Form>
      }
    </Formik>
  );
};
