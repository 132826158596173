import { ApiKeysList } from "app/admin/api-keys/ApiKeysList";
import { ContentArea } from "app/shared";
import React from "react";

const ApiKeysListPage: React.FC = () => {
  return (
    <ContentArea>
      <ApiKeysList />
    </ContentArea>
  );
};

export default ApiKeysListPage;
