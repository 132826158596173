import { action, makeObservable, observable, runInAction } from "mobx";
import { BackendResponseStatus, isErrorResponse, PageResponse, SortOrder } from "models";
import React, { useContext } from "react";
import { Deployment, DeploymentListItem } from "../models";
import {
  activate,
  deactivate,
  getDeployment,
  getDeployments,
  deploy
} from "../services";

class DeploymentStore {
  deployments: DeploymentListItem[] = [];
  selectedDeployment: Deployment | null = null;
  totalDeployments = 0;
  state: BackendResponseStatus = "pending";

  constructor() {
    makeObservable(this, {
      deployments: observable,
      selectedDeployment: observable,
      totalDeployments: observable,
      state: observable,
      fetchDeployments: action,
      fetchDeployment: action,
      createDeployment: action
    });
  }

  async fetchDeployments(
    programmeId: string,
    page: number,
    limit: number,
    sortField?: string,
    sortOrder?: SortOrder
  ) {
    try {
      runInAction(() => {
        this.state = "pending";
      });
      const pagedResults: PageResponse<DeploymentListItem> = await getDeployments(
        programmeId,
        page,
        limit,
        sortField,
        sortOrder
      );
      runInAction(() => {
        this.state = "done";
        this.deployments = pagedResults.data;
        this.totalDeployments = pagedResults.meta.totalElements;
      });
    } catch (error) {
      runInAction(() => {
        this.state = "error";
      });
    }
  }

  async fetchDeployment(programmeId: string, deploymentId: string) {
    try {
      runInAction(() => {
        this.state = "pending";
      });
      const deployment: Deployment = await getDeployment(
        programmeId,
        deploymentId
      );
      runInAction(() => {
        this.state = "done";
        this.selectedDeployment = deployment;
      });
    } catch (error) {
      runInAction(() => {
        this.state = "error";
        this.selectedDeployment = null;
      });
    }
  }

  async setActive(programmeId: string, active: boolean) {
    try {
      runInAction(() => {
        this.state = "pending";
      });
      if (active) {
        await activate(programmeId);
      } else {
        await deactivate(programmeId);
      }
      runInAction(() => (this.state = "done"));
    } catch (error) {
      runInAction(() => {
        this.state = "error";
        this.selectedDeployment = null;
      });
    }
  }
  async createDeployment(programmeId:string): Promise<void> {
    runInAction(() => {
      this.state = "pending";
    });
    const response = await deploy(programmeId);
    runInAction(() => {
      if (isErrorResponse(response)) {
        this.state = "error";
      } else {
        this.state = "done";
      }
    });
    return response;
  }
}

/**
 * Context
 */
interface DeploymentStoreContextProps {
  deploymentStore: DeploymentStore;
}

const DeploymentStoreContext = React.createContext<DeploymentStoreContextProps>(
  {} as DeploymentStoreContextProps
);

export const useDeploymentStoreContext = () =>
  useContext(DeploymentStoreContext);

/**
 * Provider
 */
interface Props {
  children: React.ReactNode;
}

export const DeploymentStoreProvider: React.FC<Props> = ({ children }) => {
  const deploymentStore = new DeploymentStore();
  return (
    <DeploymentStoreContext.Provider value={{ deploymentStore }}>
      {children}
    </DeploymentStoreContext.Provider>
  );
};
