import {
  Button,
  Flex,
  Heading,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Stack,

} from "@chakra-ui/react";
import i18next from "i18next";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";
import { observer } from "mobx-react";
import { SortOrder } from "models";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState } from "react";
import { formatDate } from "utils";
import { CreateRideProviderForm } from "./forms/create";
import { RideProviderListItem } from "./models";
import { useRideProviderStoresContext } from "./stores/context";
import { ManagedModal } from "app/shared/modal";
import { ListActionButton } from "app/shared/list/ListActionButton";
import { BiPencil } from "react-icons/bi";
import { UpdateRideProviderForm } from "./forms/update";
import { InfoOutlineIcon } from "@chakra-ui/icons";

const RideProvidersTableBase: React.FC = () => {
  const { rideProviderStore } = useRideProviderStoresContext();
  const [page, setPage] = useState(0);
  const [sortField, setSortField] = useState("name");
  const [sortOrder, setSortOrder] = useState<SortOrder>("asc");
  const [
    openCreateRideProviderModal,
    setOpenCreateRideProviderModal,
  ] = useState<boolean>(false);
  const [
    selectedRideProviderForUpdate,
    setSelectedRideProviderForUpdate,
  ] = useState<RideProviderListItem | null>(null);
  const resultsSize = 30;

  useEffect(() => {
    rideProviderStore.fetchRideProviders(
      page,
      resultsSize,
      sortField,
      sortOrder
    );
  }, [rideProviderStore, page, resultsSize, sortField, sortOrder]);

  const createdAtTemplate = (rowData: RideProviderListItem) => {
    return formatDate(rowData.createdAt);
  };

  /** CREATE RIDE PROVIDER **/
  const onRideProviderCreated = () => {
    setOpenCreateRideProviderModal(false);
    rideProviderStore.fetchRideProviders(0, 30, "name", "asc");
  };

  /** UPDATE RIDE PROVIDER **/

  const updateButtonTemplate = (row: RideProviderListItem) => {
    return (
      <ListActionButton
        icon={BiPencil}
        clickHandler={() => setSelectedRideProviderForUpdate(row)}
      />
    );
  };

  const onRideProviderUpdated = () => {
    setSelectedRideProviderForUpdate(null);
    rideProviderStore.fetchRideProviders(0, 30, "name", "asc");
  };

  return (
    <Stack dir="column" spacing="3">
      <Button onClick={() => setOpenCreateRideProviderModal(true)}>
        {i18next.t("ride-providers:rideProvidersTable.forms.openModal")}
      </Button>
      <Flex flexDir="row" alignItems="center">
      <Heading m={4}>
        {i18next.t("ride-providers:rideProvidersTable.header")}
      </Heading>
      <Popover gutter={15} isLazy>
        <PopoverTrigger>
          <InfoOutlineIcon />
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverBody fontSize="md" fontWeight="400">
            {i18next.t("ride-providers:rideProvidersTable.description")}
          </PopoverBody>
        </PopoverContent>
      </Popover>
      </Flex>

      {rideProviderStore.state !== "error" && (
        <DataTable
          paginatorTemplate="RowsPerPageDropdown PageLinks FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          paginator
          lazy={true}
          rows={resultsSize}
          value={rideProviderStore.rideProviders}
          first={page * resultsSize}
          onPage={(e: any) => setPage(e.page)}
          totalRecords={rideProviderStore.totalRideProviders}
          sortField={sortField}
          sortOrder={sortOrder === "asc" ? 1 : -1}
          onSort={(e) => {
            setSortField(e.sortField);
            setSortOrder(e.sortOrder === 1 ? "asc" : "desc");
          }}
        >
          <Column
            field="name"
            header={i18next.t(
              "ride-providers:rideProvidersTable.dataTable.columns.name"
            )}
            sortable={true}
          />
          <Column
            field="createdAt"
            header={i18next.t(
              "ride-providers:rideProvidersTable.dataTable.columns.createdAt"
            )}
            sortable={true}
            sortField="created_at"
            body={createdAtTemplate}
          />
          <Column
            body={updateButtonTemplate}
            headerStyle={{ width: "8em", textAlign: "center" }}
            bodyStyle={{ textAlign: "center", overflow: "visible" }}
          />
        </DataTable>
      )}

      {/* create ride provider modal */}
      <ManagedModal
        openModal={openCreateRideProviderModal}
        title={i18next.t("ride-providers:form.create.title")}
        content={
          <CreateRideProviderForm
            formSubmitted={onRideProviderCreated}
            formSubmittionCanceled={() => {
              setOpenCreateRideProviderModal(false)
            }}
          />
        }
        closeHandler={() => setOpenCreateRideProviderModal(false)}
        showMainAction={false}
        showClose={false}
        closeOnOverlayClick={false}
      />

      {/* update ride provider modal */}
      <ManagedModal
        openModal={selectedRideProviderForUpdate != null}
        title={i18next.t("ride-providers:form.update.title")}
        content={
          <UpdateRideProviderForm
            rideProviderId={selectedRideProviderForUpdate?.id!!}
            onFormSubmitted={onRideProviderUpdated}
            onFormSubmitionCanceled={() =>
              setSelectedRideProviderForUpdate(null)
            }
          />
        }
        closeHandler={() => setSelectedRideProviderForUpdate(null)}
        showMainAction={false}
        showClose={false}
        closeOnOverlayClick={false}
      />
    </Stack>
  );
};

export const RideProvidersTable = observer(RideProvidersTableBase);
