import { Button, Stack, useToast } from "@chakra-ui/react";
import { ManagedModal } from "app/shared/modal";
import i18next from "i18next";
import { observer } from "mobx-react";
import { ErrorResponse } from "models";
import React from "react";
import { useState } from "react";
import { CreateProgrammeForm } from "./forms/create";
import { ProgrammesTable } from "./ProgrammesTable";
import { useProgrammeStoreContext } from "./stores/ProgrammeStore";

const ProgrammesListBase: React.FC = () => {
  const [openCreateProgrammeModal, setOpenCreateProgrammeModal] = useState(false);
  const { programmeStore } = useProgrammeStoreContext()
  const toast = useToast();

  const onProgrammeCreatedSuccess = () => {
    toast({
      title: i18next.t("programmes:form.create.success"),
      status: "success",
      isClosable: true,
    });
    setOpenCreateProgrammeModal(false);
    programmeStore.fetchProgrammes(0, 30); // TODO: review these defaults
  };

  const onProgrammeCreateError = (error: ErrorResponse, msg: string) => {
    toast({ title: msg, status: "error", isClosable: true });
    setOpenCreateProgrammeModal(false);
  };

  return (
    <Stack dir="column" spacing="3">
      <Button onClick={() => setOpenCreateProgrammeModal(true)}>
        {i18next.t("programmes:programmesTable.forms.openModal")}
      </Button>
      <ProgrammesTable />

      {/* create programme modal */}
      <ManagedModal
        openModal={openCreateProgrammeModal}
        title={i18next.t("programmes:form.create.title")}
        content={
          <CreateProgrammeForm
            onFormSubmitionError={onProgrammeCreateError}
            onFormSubmitted={onProgrammeCreatedSuccess}
            onFormSubmitionCanceled={() => { setOpenCreateProgrammeModal(false)}}
          />
        }
        closeHandler={() => setOpenCreateProgrammeModal(false)}
        contentMinHeight={"350px"}
        showMainAction={false}
        showClose={false}
        closeOnOverlayClick={false}
      />
    </Stack>
  );
};

export const ProgrammesList = observer(ProgrammesListBase);
