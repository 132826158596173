import { axios } from './../network/index';

export const getEntitiesNames = async (
    request: EntityNameRequest[]
  ): Promise<EntityNameResponse[]> => {
    const axiosInstance = await axios();
    return axiosInstance.post<EntityNameResponse[]>('/utils/entities-names', request)
      .then((response) => {
        return response.data;
      });
};

export enum Entity {
    Programme = 'Programme',
    Criteria = 'Criteria',
    Rule = 'Rule',
    Subsidizer = 'Sponsors',
    Deployment = 'Deployment',
    RideProvider = 'RideProvider'
}

export interface EntityNameRequest {
  entity: Entity;
  id: string;
}

export interface EntityNameResponse {
  entity: Entity;
  id: string;
  name: string;
}