import { EventEmitter } from "events";

export abstract class Events {
    private static eventEmitter = new EventEmitter();

    static emit = (eventType: string) => {
        Events.eventEmitter.emit(eventType)
    }

    static subscribe = (eventType: string, callback: () => void) => {
        Events.eventEmitter.addListener(eventType, callback)
    }

    static unsubscribe = (eventType: string, callback: () => void) => {
        Events.eventEmitter.removeListener(eventType, callback)
    }
}

export class EventType {
    public static PROGRAMME_UPDATED = 'programme-upadted';
}