import { makeObservable, observable, runInAction } from "mobx";
import { BackendResponseStatus, PageResponse, SortOrder } from "models";
import React, { useContext } from "react";
import { Account, AccountListItem } from "../models/commands";
import { getAccounts } from "../services";

export interface ProgrammeStatViewModel {
  confirmCount: number;
  confirmPct: number;
  initiateCount: number;
  initiatePct: number;
  quoteCount: number;
  quotePct: number;
}

class AccountStore {
  selectedAccount: Account | null = null;

  // programmes list
  accounts: AccountListItem[] = [];
  totalAccounts = 0;
  state: BackendResponseStatus = "pending";

  constructor() {
    makeObservable(this, {
      accounts: observable,
      selectedAccount: observable,
      totalAccounts: observable,
      state: observable
    });
  }

  async fetchAccounts(
    page: number,
    limit: number,
    sortField?: string,
    sortOrder?: SortOrder
  ) {
    try {
      runInAction(() => {
        this.state = "pending";
      });
      const pagedResults: PageResponse<AccountListItem> = await getAccounts(
        page,
        limit,
        sortField,
        sortOrder
      );
      runInAction(() => {
        this.state = "done";
        this.accounts = pagedResults.data;
        this.totalAccounts = pagedResults.meta.totalElements;
      });
    } catch (error) {
      runInAction(() => {
        this.state = "error";
      });
    }
  }
}

/**
 * Context
 */
interface AccountStoreContextProps {
  accountStore: AccountStore;
}

const AccountStoreContext = React.createContext<AccountStoreContextProps>(
  {} as AccountStoreContextProps
);

export const useAccountStoreContext = () => useContext(AccountStoreContext);

/**
 * Provider
 */
interface Props {
  children: React.ReactNode;
}

export const AccountStoreProvider: React.FC<Props> = ({ children }) => {
  const accountStore = new AccountStore();
  return (
    <AccountStoreContext.Provider value={{ accountStore }}>
      {children}
    </AccountStoreContext.Provider>
  );
};
