import * as Yup from "yup";
import {
  CreateCriteriaCommand,
  UpdateCriteriaCommand,
} from "../../models/commands";
import { Polygon } from "geojson";
import i18next from "i18next";
import { CriteriaType } from "app/criteria/models";

const formI18nPrefix = "programmes:criteria.geographic.errors.validation";

export const CreateOrUpdateGeoCriteriaFormSchema = Yup.object({
  name: Yup.string().required(i18next.t(`${formI18nPrefix}.name.notblank`)),
  place: Yup.object()
    .shape({
      type: Yup.string(),
      coordinates: Yup.array().of(
        Yup.array().of(
          Yup.array().of(Yup.number()).min(2).max(2)
        ).min(1)
      ).min(1, i18next.t(`${formI18nPrefix}.place.min`))
    })
    .required(i18next.t(`${formI18nPrefix}.name.notblank`)), //TODO: this is not optimal yet
}).defined();

export type GeoCriteriaViewModel = Yup.InferType<typeof CreateOrUpdateGeoCriteriaFormSchema>;

export const viewModel2CreateGeoCriteriaCommand = (
  formValues: GeoCriteriaViewModel
): CreateCriteriaCommand => {
  return {
    type: CriteriaType.Geo,
    name: formValues.name,
    place: {
      geometry: formValues.place as Polygon
    },
  };
};

export const viewModel2UpdateGeoCriteriaCommand = (
  id: string,
  formValues: GeoCriteriaViewModel
): UpdateCriteriaCommand => {
  return {
    type: CriteriaType.Geo,
    id: id,
    name: formValues.name,
    place: {
      geometry: formValues.place as Polygon
    },
  };
};
