import { RuleItem, RuleItemType } from "app/rules/models";
import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  Text,
} from "@chakra-ui/react";
import i18next from "i18next";
import { CriteriaPageItem } from "app/criteria/models";
import { Field } from "formik";
import { FieldInternalProps } from "app/shared/forms/GenericInput";
import { Select } from "app/shared";

export const RULE_LABELS_WIDTH = 350;

interface RuleItemSetupProps {
  ruleItem: RuleItem;
  onRuleItemUpdate: (ruleItem: RuleItem) => void;
  onRuleItemDelete: (ruleItem: RuleItem) => void;
}

const RuleItemSetup: React.FC<RuleItemSetupProps> = (
  props: RuleItemSetupProps
) => {
  const { ruleItem, onRuleItemUpdate, onRuleItemDelete } = props;

  const propagateUpdate = useCallback(
    ({ updatedStart, updatedEnd }) => {
      const updatedRuleItem = {
        ...ruleItem,
      };
      if (updatedStart !== undefined) {
        updatedRuleItem.isStart = updatedStart;
      } else if (updatedEnd !== undefined) {
        updatedRuleItem.isEnd = updatedEnd;
      }
      onRuleItemUpdate(updatedRuleItem);
    },
    [onRuleItemUpdate, ruleItem]
  );

  return (
    <Box p={2} m={2} border="1px solid black" position="relative">
      <Text>
        {i18next.t("rules:form.common.fields.items.criteriaName")}:{" "}
        {ruleItem.criteria.name}
      </Text>
      <Text>
        {i18next.t("rules:form.common.fields.items.criteriaType")}:
        {ruleItem.criteria.type}
      </Text>
      {ruleItem.type === RuleItemType.Geographic && (
        <Box>
          <Checkbox
            defaultIsChecked={ruleItem.isStart}
            onChange={(e) => {
              propagateUpdate({
                updatedStart: e.target.checked,
              });
            }}>
            {i18next.t("rules:form.common.fields.items.isStart")}
          </Checkbox>
          &nbsp;&nbsp;
          <Checkbox
            defaultIsChecked={ruleItem.isEnd}
            onChange={(e) => {
              propagateUpdate({
                updatedEnd: e.target.checked,
              });
            }}>
            {i18next.t("rules:form.common.fields.items.isEnd")}
          </Checkbox>
        </Box>
      )}
      <Box
        onClick={() => onRuleItemDelete(ruleItem)}
        position="absolute"
        top="0.5rem"
        right="1.0rem"
        _hover={{
          cursor: "pointer",
        }}>
        <span>X</span>
      </Box>
    </Box>
  );
};

interface CriteriaSelectionFieldProps {
  fieldName: string;
  criteria: CriteriaPageItem[];
  initialRuleItems: RuleItem[];
  onRuleItemListUpdated: (items: RuleItem[]) => void;
}

export const CriteriaSelectionField: React.FC<CriteriaSelectionFieldProps> = (
  props: CriteriaSelectionFieldProps
) => {
  const {
    fieldName,
    criteria,
    initialRuleItems,
    onRuleItemListUpdated,
  } = props;
  const [
    selectedCriteria,
    setSelectedCriteria,
  ] = useState<CriteriaPageItem | null>(null);
  const [ruleItems, setRuleItems] = useState<RuleItem[]>([]);

  useEffect(() => {
    // The condition is important otherwise the effect sets the ruleItems to the initial rule items on every change
    if (ruleItems.length === 0) setRuleItems(initialRuleItems);
  }, [ruleItems, initialRuleItems]);

  const currentCriteria = useCallback(() => {
    return criteria.map((c) => ({
      value: c.id,
      label: `${c.name} (${i18next.t(
        `programmes:enums.criteriaType.${c.type}`
      )})`,
    }));
  }, [criteria]);

  const addRuleItem = useCallback(() => {
    if (selectedCriteria) {
      const newItem =
        ruleItems.findIndex(
          (ruleItem) => ruleItem.criteria.id === selectedCriteria.id
        ) === -1;
      if (newItem) {
        const ruleType =
          selectedCriteria.type === "Geographic"
            ? RuleItemType.Geographic
            : RuleItemType.Generic;
        const ruleItem: RuleItem = {
          isStart: ruleType === RuleItemType.Geographic ? false : undefined,
          isEnd: ruleType === RuleItemType.Geographic ? false : undefined,
          type: ruleType,
          criteria: {
            id: selectedCriteria.id,
            type: selectedCriteria.type,
            name: selectedCriteria.name,
          },
        };
        const updatedRuleItems = ruleItems.concat(ruleItem);
        setRuleItems(updatedRuleItems);
        onRuleItemListUpdated(updatedRuleItems);
      }
    }
  }, [onRuleItemListUpdated, ruleItems, selectedCriteria]);

  const onRuleItemUpdate = useCallback(
    (ruleItem: RuleItem) => {
      const idx = ruleItems.findIndex(
        (storedItem) => storedItem.criteria.id === ruleItem.criteria.id
      );
      if (idx !== -1) {
        // TODO: Evil manual state mutation, probably better to re-create the arrays etc
        ruleItems[idx] = ruleItem;
        onRuleItemListUpdated(ruleItems);
      }
    },
    [onRuleItemListUpdated, ruleItems]
  );

  const onRuleItemDelete = useCallback(
    (ruleItem: RuleItem) => {
      const idx = ruleItems.findIndex(
        (storedItem) => storedItem.criteria.id === ruleItem.criteria.id
      );
      if (idx !== -1) {
        // TODO: Evil manual state mutation, probably better to re-create the arrays etc
        ruleItems.splice(idx, 1);
        onRuleItemListUpdated(ruleItems);
      }
    },
    [onRuleItemListUpdated, ruleItems]
  );

  return (
    <Field className="block" name={fieldName}>
      {(renderProps: FieldInternalProps) => (
        <FormControl
          isInvalid={
            renderProps.form.errors[fieldName] &&
            renderProps.form.touched[fieldName]
          }>
          <Box mb={4}>
            <Flex>
              <Box flex="1" mr={3} pt={2}>
                <Select
                  onChange={async (option) => {
                    const selectedCriteria = criteria.find(
                      (criteria) => criteria.id === option.value
                    );
                    if (selectedCriteria) {
                      setSelectedCriteria(selectedCriteria);
                    }
                  }}
                  options={currentCriteria()}
                  placeholder={i18next.t(
                    "rules:form.common.fields.items.criteriaSelect.placeholder"
                  )}
                />
              </Box>
              <Button onClick={addRuleItem} mt="2">
                {i18next.t("rules:form.common.fields.items.addBtn")}
              </Button>
            </Flex>
            <Box overflowY="auto" maxH="400px">
              {ruleItems.map((ruleItem) => (
                <RuleItemSetup
                  key={ruleItem.criteria.id}
                  ruleItem={ruleItem}
                  onRuleItemUpdate={onRuleItemUpdate}
                  onRuleItemDelete={onRuleItemDelete}
                />
              ))}
            </Box>
          </Box>
          <Text variant="alert-text">{renderProps.form.errors[fieldName]}</Text>
        </FormControl>
      )}
    </Field>
  );
};
