import React, { useEffect } from "react";
import { Form, Formik } from "formik";
import i18next from "i18next";
import { formatDate } from "utils";
import {
  CreateOrUpdateRideProviderFormSchema,
  CreateOrUpdateRideProviderFormViewModel,
  viewModel2CreateOrUpdateRideProviderCommand
} from "../common/schema";
import { FormActions } from "app/shared/forms/FormActions";
import { GenericInput } from "app/shared/forms/GenericInput";
import { Table, Tbody, Td, Tr } from "@chakra-ui/table";
import { useRideProviderStoresContext } from "app/rideProviders/stores/context";
import { Stack } from "@chakra-ui/layout";

interface UpdateRideProviderFormProps {
  rideProviderId: string;
  onFormSubmitted: () => void;
  onFormSubmitionCanceled: () => void;
}

export const UpdateRideProviderForm: React.FC<UpdateRideProviderFormProps> = ({rideProviderId, onFormSubmitionCanceled, onFormSubmitted}) => {
  const { rideProviderStore } = useRideProviderStoresContext();

  useEffect(() => {
    rideProviderStore
      .fetchRideProvider(rideProviderId)
  }, [rideProviderStore, rideProviderId])

  const submitHandler = async (formValues: CreateOrUpdateRideProviderFormViewModel) => {
    if(rideProviderStore.state === 'pending') return;

    await rideProviderStore.updateRideProvider(
      rideProviderId,
      viewModel2CreateOrUpdateRideProviderCommand(formValues)
    );
    onFormSubmitted()
  };

  return (
    <>
      {rideProviderStore.state === "done" && 
        <Formik
          initialValues={ {
            name: rideProviderStore.selectedRideProvider?.name!!
          }}
          validationSchema={CreateOrUpdateRideProviderFormSchema}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={submitHandler}>
          {(formikProps) => 
            <Form>
              <Stack dir="column" spacing="3">
                <Table variant="simple">
                  <Tbody>
                    <Tr>
                      <Td>{i18next.t("ride-providers:rideProviderDetails.detailsTable.name")}</Td>
                      <Td>
                        <GenericInput
                          autoFocus={true}
                          i18nextPrefix="ride-providers"
                          formName="update"
                          fieldName="name"
                          fieldType="string"
                          hideLabel={true} />
                      </Td>
                    </Tr>
                    <Tr>
                      <Td>{i18next.t("ride-providers:rideProviderDetails.detailsTable.createdAt")}</Td>
                      <Td>
                        {rideProviderStore.selectedRideProvider?.createdAt &&
                          formatDate(rideProviderStore.selectedRideProvider.createdAt)}
                      </Td>
                    </Tr>
                  </Tbody>
                </Table>
                <FormActions 
                  cancelHandler={onFormSubmitionCanceled}
                  disableSubmit={rideProviderStore.state === 'pending'}/>
              </Stack>
            </Form>
          }
        </Formik>
      }
    </>
  );
};
