import { Auth } from "aws-amplify";
import { AxiosInstance, default as Axios } from "axios";
import environment from "configurations";

// Create axios instances
const configuration = {
  baseURL: `${environment.api}`,
  headers: {
    "Content-Type": "application/json; charset=utf-8",
  },
};

let axiosInstance: AxiosInstance | undefined = undefined;

const createAxiosInstance = async () => {
  const axios = Axios.create(configuration);

  axios.interceptors.request.use(async (config) => {
    const credentials = await Auth.currentSession();
    const token = credentials.getIdToken().getJwtToken();
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  });

    return axios;
  };
  

export const axios = async () => {
  if (!axiosInstance) {
    axiosInstance = await createAxiosInstance();
  }

  return axiosInstance;
};

