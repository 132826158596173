import { Box, Flex, FormControl, FormLabel, Text } from "@chakra-ui/react";
import { Field } from "formik";
import i18next from "i18next";
import React, { useState, useEffect } from "react";
import { Select, SelectOption } from "../select";
import { DEFAULT_LABEL_WIDTH, FieldInternalProps } from "./GenericInput";

export interface GenericSelectProps {
  fieldName: string;
  formName: string;
  fieldType: string;
  options: SelectOption[];
  selectedOption?: SelectOption;
  value?: SelectOption;
  i18nextPrefix: string;
  hideLabel?: boolean;
  disabled?: boolean;
  labelWidth?: string;
  width?: number;
  menuWidth?: number;
  flex?: number;
  onChange?: (option: any) => void;
}

export const GenericSelect: React.FC<GenericSelectProps> = ({
  fieldName,
  formName,
  fieldType,
  options,
  selectedOption,
  value,
  i18nextPrefix,
  disabled = false,
  hideLabel = false,
  labelWidth = DEFAULT_LABEL_WIDTH,
  width,
  menuWidth,
  flex,
  onChange,
  ...rest
}) => {

  const [selectedValue, setSelectedValue] = useState<SelectOption | undefined>(selectedOption);

  useEffect(() => {
    if(value) {
      setSelectedValue(value);
    }
  }, [value])

  return (
    <Flex mb="10px" flex={flex} w="100%">
      {!hideLabel &&
        <Box width={labelWidth}>
          <FormLabel>
            {i18next.t(`${i18nextPrefix}:form.${formName}.fields.${fieldName}.label`)}
          </FormLabel>
        </Box>
      }
      <Field name={fieldName} type={fieldType}>
        {(renderProps: FieldInternalProps) => (
          <FormControl
            isInvalid={
              renderProps.form.errors[fieldName] &&
              renderProps.form.touched[fieldName]
            }>
            <Select
              disabled={disabled}
              onChange={(option) => {
                renderProps.form.setFieldValue(fieldName, option.value);
                renderProps.form.setFieldTouched(fieldName, true);
                if(onChange){
                  onChange(option)
                }
                setSelectedValue(option);
              }}
              options={options}
              defaultValue={selectedOption}
              value={ selectedValue ? [selectedValue] : [] }
              menuWidth={menuWidth ?? width}
              width={width ? `${width}` : undefined}
              {...(rest as unknown)}
              placeholder={i18next.t(
                `${i18nextPrefix}:form.${formName}.fields.${fieldName}.placeholder`
              )}
            />
            <Text variant="alert-text">{renderProps.form.errors[fieldName]}</Text>
          </FormControl>
        )}
      </Field>
    </Flex>
)};
