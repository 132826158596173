import { Button, Stack, useToast } from "@chakra-ui/react";
import { ManagedModal } from "app/shared/modal";
import { observer } from "mobx-react";
import React, { useState } from "react";
import i18next from "i18next";
import { CreateDeploymentForm } from "./forms/CreateDeployment";
import { DeploymentsTable } from "./DeploymentsTable";
import { useDeploymentStoreContext } from "./stores/DeploymentStore";
import { useProgrammeStoreContext } from "app/programmes/stores/ProgrammeStore";
import { useRuleStoreContext } from "app/rules/stores";
import { handleAxiosError } from "utils/ErrorEventHandler";

interface Props {
  programmeId: string;
}

const DeploymentListBase: React.FC<Props> = ({ programmeId }) => {
  const { deploymentStore } = useDeploymentStoreContext();
  const { programmeStore } = useProgrammeStoreContext();
  const [openCreateDeployModal, setOpenCreateDeployModal] = useState(false);
  const { ruleStore } = useRuleStoreContext();
  const [deploying, setDeploying] = useState(false);
  const toast = useToast();

  const onDeployClicked = (): void => {
    if (ruleStore.rules.length > 0) {
      if (deploymentStore.state === "pending") return;
      setDeploying(true);
      deploymentStore
        .createDeployment(programmeId)
        .then((result) => {
          setOpenCreateDeployModal(false);
          toast({
            title: i18next.t("programmes:form.createDeployment.success"),
            status: "success",
            isClosable: true,
          });
          deploymentStore.fetchDeployments(programmeId, 0, 30); // TODO review these limits
          programmeStore.fetchProgramme(programmeId);
        })
        .catch((error) => {
        handleAxiosError(error);
        })
        .finally(() => setDeploying(false));
    } else {
      toast({
        title: i18next.t("programmes:form.createDeployment.missingRule"),
        status: "error",
        isClosable: true,
      });
    }
  };
  return (
    <Stack dir="column" spacing="3">
      <Button onClick={() => setOpenCreateDeployModal(true)}>
        {i18next.t("programmes:form.createDeployment.openBtn")}
      </Button>
      <DeploymentsTable programmeId={programmeId} />

      {/* create deployment modal */}
      <ManagedModal
        openModal={openCreateDeployModal}
        title=""
        content={
          <>
            <CreateDeploymentForm
              programmeId={programmeId}
              cancelHandler={() => {
                setOpenCreateDeployModal(false);
              }}
              submitHandler={onDeployClicked}
              busy={deploying}
            />
          </>
        }
        closeHandler={() => setOpenCreateDeployModal(false)}
        showMainAction={false}
        showClose={false}
        mainActionText={i18next.t("programmes:form.createDeployment.deploy")}
        mainActionDisabled={deploying}
        closeDisabled={deploying}
        closeOnOverlayClick={false}
      />
    </Stack>
  );
};

export const DeploymentList = observer(DeploymentListBase);
