import moment from "moment";

export const formatDate = (iso8601: string) => {
  return moment(iso8601).format("YYYY-MM-DD"); 
};

export const formatToLocalDate = (iso8601: string) => {
  return moment(iso8601).format("YYYY-MM-DD");
};

export const format = (date: string, format: string) => {
  return moment(date).format(format);
};

export const toUtc = (
  dateTime: Date,
  dateTimeFormat: string,
  timezone: string
): string => {
  const dateTimeFormatted = moment(dateTime).format(dateTimeFormat);
  const dateTimeAtTimezone = moment.tz(
    dateTimeFormatted,
    dateTimeFormat,
    timezone
  );
  return dateTimeAtTimezone.utc().format();
};