import { ChevronRightIcon } from "@chakra-ui/icons";
import {
  Breadcrumb as ChakraBreadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
} from "@chakra-ui/react";
import i18next, { TOptions } from "i18next";
import React from "react";
import { Link } from "react-router-dom";

export interface BreadcrumbObject {
  path: string;
  i18nLabel: string;
  i18nOptions?: TOptions;
  selected: boolean;
}

interface Props {
  breadcrumbs: BreadcrumbObject[];
}

export const Breadcrumb: React.FC<Props> = ({ breadcrumbs }) => {
  return (
    <ChakraBreadcrumb separator={<ChevronRightIcon color="gray.500" />} py={4}>
      {breadcrumbs.map((breadcrumb, index) => (
        <BreadcrumbItem
          key={`breadcrumb_${index}`}
          isCurrentPage={breadcrumb.selected}>
          <BreadcrumbLink
            as={Link}
            to={breadcrumb.path}
            color={breadcrumb.selected ? "gray.400" : undefined}>
            {i18next.t(breadcrumb.i18nLabel, breadcrumb.i18nOptions)}
          </BreadcrumbLink>
        </BreadcrumbItem>
      ))}
    </ChakraBreadcrumb>
  );
};
