import { ProgrammeLimitPeriod } from './../../models/index';
import { formatToLocalDate } from "utils";
import * as Yup from "yup";
import { CreateOrUpdateProgrammeCommand } from "../../models/commands";
import i18next from "i18next";

export const CreateOrUpdateProgrammeFormSchema = Yup.object({
  name: Yup.string()
    .min(3, "Too Short!")
    .max(255, "Too Long!")
    .required("Required"),
  currency: Yup.string().required("Required"),
  timezone: Yup.string().required("Required"),
  startDate: Yup.string().required("Required"),
  endDate: Yup.string().required("Required"),
  rideProviders: Yup.array().of(Yup.string()).min(1).required("Required"),
  subsidizer: Yup.string().required("Required"),
  budget: Yup.number()
    .integer()
    .typeError(i18next.t('programmes:form.create.fields.budget.error.type'))
    .required("Required"),
  tripLimit: Yup.string()
    .matches(/[0-9]+/, i18next.t('programmes:form.create.fields.tripLimit.error.type'))
    .nullable(true),
  tripLimitPeriod: Yup.mixed()
    .oneOf(Object.values(ProgrammeLimitPeriod))
    .nullable(true),
  subsidyLimit: Yup.string()
    .matches(/^[0-9]+([\\.,][0-9]{0,2})?$/, i18next.t('programmes:form.create.fields.subsidyLimit.error.type'))
    .nullable(true),
  subsidyLimitPeriod: Yup.mixed()
    .oneOf(Object.values(ProgrammeLimitPeriod))
    .nullable(true)
}).defined();

export type CreateOrUpdateProgrammeFormViewModel = Yup.InferType<
  typeof CreateOrUpdateProgrammeFormSchema
>;

export const viewModel2CreateOrUpdateProgrammeCommand = (
  formValues: CreateOrUpdateProgrammeFormViewModel
): CreateOrUpdateProgrammeCommand => {
  return {
    name: formValues.name,
    currency: formValues.currency,
    timezone: formValues.timezone,
    startDate: formatToLocalDate(formValues.startDate),
    endDate: formatToLocalDate(formValues.endDate),
    rideProviders: formValues.rideProviders,
    subsidizer: formValues.subsidizer,
    budget: formValues.budget,
    tripLimit: !formValues.tripLimit || formValues.tripLimit.trim() === '' ? undefined : parseInt(formValues.tripLimit),
    tripLimitPeriod: !formValues.tripLimitPeriod || formValues.tripLimitPeriod.trim() === '' ? undefined : formValues.tripLimitPeriod!!,
    subsidyLimit: !formValues.subsidyLimit || formValues.subsidyLimit.trim() === '' ? undefined : Number(formValues.subsidyLimit),
    subsidyLimitPeriod: !formValues.subsidyLimitPeriod || formValues.subsidyLimitPeriod.trim() === '' ? undefined : formValues.subsidyLimitPeriod
  };
};
