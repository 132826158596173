export type Permission =
  | "programmes:*"
  | "programmes:read"
  | "programmes:create"
  | "programmes:update"
  | "subsidizers:*"
  | "subsidizers:read"
  | "ride-providers:read"
  | "user:read"
  | "admin:*"
  | "superadmin:*";

export const SuperAdmin: string = 'superadmin:*';
export const Admin: string = 'admin:*';
export const Programmes: string = 'programmes:*';
export const Subsidizers: string = 'subsidizers:*';
export const RideProviders: string = 'ride-providers:*';
export const Maas: string = 'maas:*';