import { axios } from "network";
import {
  GenerateDataCommand,
  GenerateDataResponse,
} from "app/data-generator/models";
import { handleAxiosError } from "utils/ErrorEventHandler";

export const generateData = async (
  cmd: GenerateDataCommand
): Promise<GenerateDataResponse> => {
  const axiosInstance = await axios();
  return axiosInstance
    .post<GenerateDataResponse>("/simulator/generate-data", cmd)
    .then((response) => response.data)
    .catch((error) => handleAxiosError(error));
};
