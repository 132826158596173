import { RuleList } from "app/rules/RuleList";
import { Tabs } from "app/shared";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { CriteriaList } from "../criteria/CriteriaList";
import { DeploymentList } from "app/deployments/DeploymentsList";
import { Stack, Text } from "@chakra-ui/layout";
import i18next from "i18next";
import { useProgrammeStoreContext } from "./stores/ProgrammeStore";
import { ProgrammeDetailsEdit}  from "../ProgrammeDetailsEdit/index"




interface Props {
  programmeId: string;
}

const ProgrammeDetailsBase: React.FC<Props> = ({ programmeId }) => {
  const { programmeStore } = useProgrammeStoreContext();

  useEffect(() => {
    if (programmeStore.selectedProgramme?.id !== programmeId) {
      programmeStore.fetchProgramme(programmeId);
    }
  }, [programmeStore, programmeId]);

  return (
    <Stack>
      <Text fontWeight="bold" fontSize="sm" align="right" mr="5">
        {i18next.t(
          programmeStore.selectedProgramme?.deployed
            ? "programmes:programmeDetails.deployed.yes"
            : "programmes:programmeDetails.deployed.no"
        )}
      </Text>
      <Tabs
        tabs={[
           {
             i18nTitle: "Program Details",
             content: <ProgrammeDetailsEdit programmeId={programmeId} />, // content -- generic tab
           },
          {
            i18nTitle: "programmes:programmeDetails.tabs.criteria",
            content: <CriteriaList programmeId={programmeId} />,
          },
          {
            i18nTitle: "programmes:programmeDetails.tabs.rules",
            content: <RuleList programmeId={programmeId} />,
          },
          {
            i18nTitle: "programmes:programmeDetails.tabs.deploys",
            content: <DeploymentList programmeId={programmeId} />,
          },
        ]}
      />
    </Stack>
  );
};

export const ProgrammeDetails = observer(ProgrammeDetailsBase);
