import { ProgrammesList } from "app/programmes/ProgrammesList";
import { ContentArea } from "app/shared";
import React from "react";

const ProgrammesListPage: React.FC = () => {
  return (
    <ContentArea>
      <ProgrammesList />
    </ContentArea>
  );
};

export default ProgrammesListPage;
