import React, { useCallback, useEffect, useState } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import i18next from "i18next";
import {
  CreateOrUpdateApiKeyFormSchema,
  CreateOrUpdateApiKeyFormViewModel,
} from "./schema";
import { Box, Flex, Text } from "@chakra-ui/layout";
import { Checkbox } from "@chakra-ui/checkbox";
import { GenericSelect } from "app/shared/forms/GenericSelect";
import {
  SuperAdmin,
  Admin,
  Subsidizers,
  RideProviders,
  Programmes,
  Maas,
} from "app/shared/accessControl/constants";
import { SelectOption } from "app/shared/select";
import { FormActions } from "app/shared/forms/FormActions";
import {
  DEFAULT_LABEL_WIDTH,
  GenericInput,
} from "app/shared/forms/GenericInput";
import { useAccountStoreContext } from "app/admin/accounts/stores/AccountStore";
import { MaasApiProgramme } from "../models/commands";
import { AccountListItem } from "app/admin/accounts/models/commands";
import { Select } from "app/shared";
import { Button } from "@chakra-ui/button";
import { getAvailableProgrammes } from "../services";

interface FormProps {
  initialState: CreateOrUpdateApiKeyFormViewModel;
  updating: boolean;
  syncedWithCognito?: boolean;
  submitHandler: (
    formValues: CreateOrUpdateApiKeyFormViewModel,
    helpers: FormikHelpers<any>
  ) => void;
  cancelHandler: () => void;
}

export const ApiKeyForm: React.FC<FormProps> = (props: FormProps) => {
  const { initialState, submitHandler } = props;

  const { accountStore } = useAccountStoreContext();
  const [availableAccounts, setAvailableAccounts] = useState<SelectOption[]>(
    []
  );
  const [availableMaasPogrammes, setAvailableMaasPogrammes] = useState<
    MaasApiProgramme[]
  >([]);
  const [
    selectedMaasProgramme,
    setSelectedMaasProgramme,
  ] = useState<MaasApiProgramme | null>(null);
  const [selectedMaasProgrammes, setSelectedMaasProgrammes] = useState<
    MaasApiProgramme[]
  >([]);

  useEffect(() => {
    // set permission list checkbox according initial state
    const apiKeyPermissions = props.initialState.permissions.split(",");
    PERMISSIONS_LIST.forEach((p) => {
      p.checked = apiKeyPermissions.indexOf(p.permission) >= 0;
    });

    // load available programmes and select the ones according initial state
    getAvailableProgrammes().then((availableProgrammes) => {
      setAvailableMaasPogrammes(availableProgrammes);

      const selectedProgrammes = availableProgrammes.filter(
        (p) => props.initialState.maasProgrammes.indexOf(p.id) >= 0
      );
      setSelectedMaasProgrammes(selectedProgrammes);
    });

    // set available accounts
    const accountsOptions = accountStore.accounts.map(
      (account: AccountListItem) => ({
        label: account.name,
        value: account.id,
      })
    );
    setAvailableAccounts(accountsOptions);
  }, [accountStore, props.initialState]);

  const onFormSubmitted = (
    formValues: CreateOrUpdateApiKeyFormViewModel,
    helpers: FormikHelpers<any>
  ) => {
    submitHandler(formValues, helpers);
  };

  const maasApiProgrammes = useCallback(() => {
    return availableMaasPogrammes.map((p) => ({
      value: p.id,
      label: p.name,
    }));
  }, [availableMaasPogrammes]);

  return (
    <Formik
      initialValues={initialState}
      validationSchema={CreateOrUpdateApiKeyFormSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onFormSubmitted}>
      {(formikProps) => {
        const onAcountChanged = (selectedAccount: SelectOption) => {
          formikProps.setFieldValue("account", {
            id: selectedAccount.value,
            name: selectedAccount.label,
          });
        };

        const permissionsChanged = (
          permissionItem: PermissionListItem,
          checked: boolean
        ) => {
          const isAdminOrSuperAdmin =
            permissionItem.permission === SuperAdmin ||
            permissionItem.permission === Admin;
          let permissionsArray = isAdminOrSuperAdmin
            ? []
            : formikProps.values.permissions
                .split(",")
                .filter((el) => el !== "");

          if (checked) {
            if (isAdminOrSuperAdmin) {
              PERMISSIONS_LIST.filter(
                (p) => p.permission !== permissionItem.permission
              ).forEach((element) => (element.checked = false));
              permissionsArray = [];
            } else {
              PERMISSIONS_LIST.filter(
                (p) => p.permission === Admin || p.permission === SuperAdmin
              ).forEach((element) => (element.checked = false));
              permissionsArray = permissionsArray.filter(
                (p) => p !== Admin && p !== SuperAdmin
              );
            }
            permissionsArray.push(permissionItem.permission);
          } else {
            const permissionsIndex = permissionsArray.indexOf(
              permissionItem.permission
            );
            if (permissionsIndex >= 0) {
              permissionsArray.splice(permissionsIndex, 1);
            }

            if (permissionItem.permission === Maas) {
              setSelectedMaasProgrammes([]);
            }
          }

          formikProps.setFieldValue("permissions", permissionsArray.join(","));
          permissionItem.checked = checked;

          // if no superadmin, admin or maas permission is set, empry selectd programmes
          if (
            permissionsArray.filter(
              (p) => p === Admin || p === SuperAdmin || p === Maas
            ).length === 0
          ) {
            formikProps.setFieldValue("maasProgrammes", []);
          }
        };

        const addProgramme = () => {
          if (
            selectedMaasProgramme &&
            !selectedMaasProgrammes.find(
              (p) => p.id === selectedMaasProgramme.id
            )
          ) {
            const currentSelection = selectedMaasProgrammes.concat([]);
            currentSelection.push(selectedMaasProgramme);
            setSelectedMaasProgrammes(currentSelection);

            formikProps.setFieldValue(
              "maasProgrammes",
              currentSelection.map((p) => p.id)
            );
          }
        };

        const removeProgramme = (programme: MaasApiProgramme) => {
          const programmeToRemove = selectedMaasProgrammes.find(
            (p) => p.id === programme.id
          );
          if (programmeToRemove) {
            const currentSelection = selectedMaasProgrammes.concat([]);
            const indexToRemove = currentSelection.indexOf(programmeToRemove);
            currentSelection.splice(indexToRemove, 1);
            setSelectedMaasProgrammes(currentSelection);

            formikProps.setFieldValue(
              "maasProgrammes",
              currentSelection.map((p) => p.id)
            );
          }
        };

        return (
          <Form>
            <GenericSelect
              disabled={props.updating}
              i18nextPrefix="api-keys"
              formName="create"
              fieldName="account"
              fieldType="string"
              options={availableAccounts}
              selectedOption={
                props.updating
                  ? {
                      value: props.initialState.account.id,
                      label: props.initialState.account.name,
                    }
                  : undefined
              }
              onChange={onAcountChanged}
            />
            <GenericInput
              className={props.syncedWithCognito === true ? "" : "hide"}
              disabled={props.updating}
              autoFocus={true}
              i18nextPrefix="api-keys"
              formName="create"
              fieldName="email"
              fieldType="string"
            />
            <GenericInput
              i18nextPrefix="api-keys"
              formName="create"
              fieldName="description"
              fieldType="string"
            />
            <Flex flexDir="row" width="100%">
              <Box width={DEFAULT_LABEL_WIDTH}>
                <Text>
                  {i18next.t("api-keys:form.create.fields.permissions.label")}
                </Text>
              </Box>
              <Flex width="100%">
                <Flex flexDir="column">
                  {PERMISSIONS_LIST.map(
                    (element: PermissionListItem, index: number) => {
                      return (
                        <Checkbox
                          color={element.color}
                          key={index}
                          onChange={(e) =>
                            permissionsChanged(element, e.target.checked)
                          }
                          isChecked={element.checked}>
                          {i18next.t(element.labelKey)}
                        </Checkbox>
                      );
                    }
                  )}
                </Flex>
              </Flex>
            </Flex>
            {formikProps.values.permissions
              .split(",")
              .filter((p) => [Admin, SuperAdmin, Maas].indexOf(p) >= 0).length >
              0 && (
              <Flex flexDir="column" width="100%">
                <Flex>
                  <Box flex="1" mr={3} pt={2}>
                    <Select
                      onChange={async (option) => {
                        const selectedMaasProg = availableMaasPogrammes.find(
                          (maasApiProg) => maasApiProg.id === option.value
                        );
                        if (selectedMaasProg) {
                          setSelectedMaasProgramme(selectedMaasProg);
                        }
                      }}
                      options={maasApiProgrammes()}
                      placeholder={i18next.t(
                        "api-keys:form.create.fields.permissions.selectMaasApiPrograames"
                      )}
                    />
                  </Box>
                  <Button onClick={addProgramme} mt="2">
                    {i18next.t(
                      "api-keys:form.create.fields.permissions.addProgrammeToMaasApi"
                    )}
                  </Button>
                </Flex>
                <Box overflowY="auto" maxH="400px" mt={1}>
                  {selectedMaasProgrammes.map((programme, index) => (
                    <Flex
                      flexDir="row"
                      width="100%"
                      key={index}
                      border="1px solid black"
                      p={2}
                      mb={1}>
                      <Box width="100%">
                        <Text>{programme.name}</Text>
                        <Text fontSize="sm">{programme.accountName}</Text>
                        <Text fontSize="sm">
                          {programme.startDate + " - " + programme.endDate}
                        </Text>
                      </Box>
                      <Text
                        onClick={() => removeProgramme(programme)}
                        _hover={{
                          cursor: "pointer",
                        }}>
                        X
                      </Text>
                    </Flex>
                  ))}
                </Box>
              </Flex>
            )}
            <FormActions
              submitText={i18next.t(
                props.updating
                  ? "api-keys:form.update.mainAction"
                  : "api-keys:form.create.mainAction"
              )}
              cancelHandler={props.cancelHandler}
            />
          </Form>
        );
      }}
    </Formik>
  );
};

const PERMISSIONS_LIST: PermissionListItem[] = [
  {
    permission: SuperAdmin,
    labelKey: "api-keys:form.create.fields.permissions.superAdmin.label",
    checked: false,
    color: "#f2a230",
  },
  {
    permission: Admin,
    labelKey: "api-keys:form.create.fields.permissions.admin.label",
    checked: false,
    color: "#f2a230",
  },
  {
    permission: Subsidizers,
    labelKey: "api-keys:form.create.fields.permissions.subsidizers.label",
    checked: false,
  },
  {
    permission: RideProviders,
    labelKey: "api-keys:form.create.fields.permissions.rideProviders.label",
    checked: false,
  },
  {
    permission: Programmes,
    labelKey: "api-keys:form.create.fields.permissions.programmes.label",
    checked: false,
  },
  {
    permission: Maas,
    labelKey: "api-keys:form.create.fields.permissions.maasApi.label",
    checked: false,
  },
];

interface PermissionListItem {
  permission: string;
  labelKey: string;
  checked: boolean;
  color?: string;
}
