import { Box } from "@chakra-ui/react";
import { useCriteriaStoresContext } from "app/criteria/stores/CriteriaStore";
import { FormActions } from "app/shared/forms/FormActions";
import { GenericInput } from "app/shared/forms/GenericInput";
import { GenericSelect } from "app/shared/forms/GenericSelect";
import { handleFormError } from "app/shared/forms/utils";
import { Form, Formik, FormikHelpers } from "formik";
import i18next from "i18next";
import { DataType, ErrorResponse, isErrorResponse, Operator } from "models";
import React from "react";
import { TripAttributeCriteria } from "../../models";
import {
  selectDataTypeValues,
  selectOperatorValues,
  TripAttributeCriteriaFormSchema,
  TripAttributeCriteriaViewModel,
  viewModel2CreateTripAttributeCriteriaCommand,
  viewModel2UpdateTripAttributeCriteriaCommand,
} from "./schema";

const formI18nPrefix = "programmes:form.createTripAttributeCriteria";

interface FormProps {
  initialState: TripAttributeCriteriaViewModel;
  submitHandler: (
    formValues: TripAttributeCriteriaViewModel,
    helpers: FormikHelpers<any>
  ) => void;
  cancelHandler: () => void;
}

const TripAttributeCriteriaForm: React.FC<FormProps> = (props: FormProps) => {
  const { criteriaStore } = useCriteriaStoresContext();
  const { initialState, submitHandler } = props;
  return (
    <Formik
      initialValues={initialState}
      validationSchema={TripAttributeCriteriaFormSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={submitHandler}>
      {(formikProps) => (
        <Form>
          <Box py={2}>
            <GenericInput
              i18nextPrefix="programmes"
              formName="createTripAttributeCriteria"
              fieldName="name"
              fieldType="string"
            />
            <GenericInput
              i18nextPrefix="programmes"
              formName="createTripAttributeCriteria"
              fieldName="field"
              fieldType="string"
            />
            <GenericInput
              i18nextPrefix="programmes"
              formName="createTripAttributeCriteria"
              fieldName="value"
              fieldType="string"
            />
            <GenericSelect
              formName="createTripAttributeCriteria"
              fieldName="dataType"
              fieldType="string"
              options={selectDataTypeValues}
              i18nextPrefix="programmes"
              selectedOption={ selectDataTypeValues.find(sdt => sdt.value === initialState.dataType)}
            />
            <GenericSelect
              formName="createTripAttributeCriteria"
              fieldName="operator"
              fieldType="string"
              options={selectOperatorValues}
              i18nextPrefix="programmes"
              selectedOption={ selectOperatorValues.find(so => so.value === initialState.operator)}
            />
            <FormActions
              cancelHandler={props.cancelHandler}
              submitText={i18next.t("programmes:form.createScheduleCriteria.submit")}
              disableSubmit={criteriaStore.state === 'pending'}/>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

interface CreateTripAttributeFormProps {
  programmeId: string;
  onFormSubmitted: () => void;
  onFormSubmitionCanceled: () => void;
  onFormSubmitionError: (error: ErrorResponse, message: string) => void;
}

export const CreateTripAttributeCriteriaForm: React.FC<CreateTripAttributeFormProps> = (
  props: CreateTripAttributeFormProps
) => {
  const { programmeId } = props;
  const { criteriaStore } = useCriteriaStoresContext();

  const submitHandler = async (
    formValues: TripAttributeCriteriaViewModel,
    helpers: FormikHelpers<any>
  ) => {
    if(criteriaStore.state === 'pending') return;

    helpers.setSubmitting(true);
    const response = await criteriaStore.createCriteria(programmeId, viewModel2CreateTripAttributeCriteriaCommand(formValues));
    helpers.setSubmitting(false);
    if (isErrorResponse(response)) {
      handleFormError(
        response,
        helpers,
        props.onFormSubmitionError,
        formI18nPrefix
      );
    } else {
      props.onFormSubmitted();
    }
  };

  const emptyState: TripAttributeCriteriaViewModel = {
    name: "",
    operator: Operator.Equal,
    dataType: DataType.String,
    field: "",
    value: "",
  };

  return (
    <TripAttributeCriteriaForm
      submitHandler={submitHandler}
      cancelHandler={props.onFormSubmitionCanceled}
      initialState={emptyState}
    />
  );
};

interface UpdateTripAttributeFormProps {
  programmeId: string;
  tripAttributeCriteria: TripAttributeCriteria;
  onFormSubmitted: () => void;
  onFormSubmitionCanceled: () => void;
  onFormSubmitionError: (error: ErrorResponse, message: string) => void;
}

export const UpdateTripAttributeCriteriaForm: React.FC<UpdateTripAttributeFormProps> = (
  props: UpdateTripAttributeFormProps
) => {
  const { programmeId, tripAttributeCriteria } = props;
  const { criteriaStore } = useCriteriaStoresContext();

  const submitHandler = async (
    formValues: TripAttributeCriteriaViewModel,
    helpers: FormikHelpers<any>
  ) => {
    if(criteriaStore.state === 'pending') return;

    helpers.setSubmitting(true);
    const response = await criteriaStore.updateCriteria(
      programmeId,
      tripAttributeCriteria.id,
      viewModel2UpdateTripAttributeCriteriaCommand(
        tripAttributeCriteria.id,
        formValues
      )
    );
    helpers.setSubmitting(false);
    if (isErrorResponse(response)) {
      handleFormError(
        response,
        helpers,
        props.onFormSubmitionError,
        formI18nPrefix
      );
    } else {
      props.onFormSubmitted();
    }
  };

  const currentTripAttributeCriteria: TripAttributeCriteriaViewModel = tripAttributeCriteria;

  return (
    <TripAttributeCriteriaForm
      submitHandler={submitHandler}
      cancelHandler={props.onFormSubmitionCanceled}
      initialState={currentTripAttributeCriteria}
    />
  );
};
