import { Button } from "@chakra-ui/button";
import {
  Box,
  Center,
  Stack,
  Text,
  UnorderedList,
  ListItem,
} from "@chakra-ui/layout";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { Libraries } from "@react-google-maps/api/dist/utils/make-load-script-url";
import environment from "configurations";
import { Position } from "geojson";
import i18next from "i18next";
import { observer } from "mobx-react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { handleAxiosError } from "utils/ErrorEventHandler";
import {
  SimulationRequest,
  SimulationResponse,
  TripRequestType,
  SimulationResponseItemMonetaryValue,
  SimulationResponseItemPercentage,
  SimulationResponseItemType,
} from "../models";
import { confirm, initiate } from "../service";

interface SimulationResultProperties {
  request: SimulationRequest;
  result?: SimulationResponse;
}

const SimulationResultBase: React.FC<SimulationResultProperties> = ({
  request,
  result,
}) => {
  const [libraries] = useState<Libraries>(["drawing", "places"]);
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: environment.googleMapsApiKey || "",
    libraries,
  });
  const [markers, setMarkers] = useState<google.maps.Marker[]>([]);
  const [currentResponse, setCurrentResponse] = useState<
    SimulationResponse | undefined
  >();
  const map = useRef<google.maps.Map>();

  useEffect(() => {
    // set initial state (was not working as default value in useState)
    setCurrentResponse(result);
  }, [result]);

  const renderMarkers = useCallback(
    (map: google.maps.Map) => {
      const buildMarker = (
        lat: number,
        lng: number,
        label: string
      ): google.maps.Marker => {
        return new google.maps.Marker({
          position: new google.maps.LatLng(lat, lng),
          map: map as google.maps.Map,
          label: label,
          draggable: true,
        });
      };

      const onMarkerDragEnded = (event: any) => {
        const newLat = event.latLng.lat();
        const newLng = event.latLng.lng();
        const response = Object.assign({}, currentResponse);
        switch (currentResponse?.status) {
          case TripRequestType.Quote:
            response.startPosition = [newLat, newLng];
            break;
          case TripRequestType.Initiate:
            response.endPosition = [newLat, newLng];
            break;
        }
        setCurrentResponse(response);
      };

      // remove previously render markers
      if (markers.length > 0) {
        markers.forEach((marker) => marker.setMap(null));
      }

      const currMarkers: google.maps.Marker[] = [];
      switch (currentResponse?.status) {
        case TripRequestType.Quote:
          currMarkers.push(
            buildMarker(
              currentResponse.startPosition[0],
              currentResponse.startPosition[1],
              "A"
            )
          );
          break;
        case TripRequestType.Initiate:
          currMarkers.push(
            buildMarker(
              currentResponse.endPosition[0],
              currentResponse.endPosition[1],
              "B"
            )
          );
          break;
        case TripRequestType.Confirm:
          currMarkers.push(
            buildMarker(
              currentResponse.startPosition[0],
              currentResponse.startPosition[1],
              "A"
            )
          );
          currMarkers.push(
            buildMarker(
              currentResponse.endPosition[0],
              currentResponse.endPosition[1],
              "B"
            )
          );
          break;
      }

      const adjustMapBounds = (
        visibleMarkers: google.maps.Marker[],
        map: google.maps.Map
      ) => {
        const bounds = new google.maps.LatLngBounds();
        visibleMarkers.forEach((marker) => {
          const position = marker.getPosition();
          if (position) {
            bounds.extend(position);
          }
        });
        map.fitBounds(bounds);
      };

      if (currMarkers.length > 0) {
        currMarkers.forEach((marker) => {
          if (marker.getDraggable()) {
            google.maps.event.addListener(marker, "dragend", (event) =>
              onMarkerDragEnded(event)
            );
          }
        });
        adjustMapBounds(currMarkers, map);
      }
      setMarkers(currMarkers);
    },
    [currentResponse, markers]
  );

  useEffect(() => {
    if (map.current && currentResponse) {
      renderMarkers(map.current);
    }
    // If we set the render markers as a dependency then we will have a stack overflow
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentResponse]);

  const onMapReady = (gmap: google.maps.Map) => {
    map.current = gmap;
    renderMarkers(map.current);
  };

  const renderMap = () => (
    <GoogleMap
      id="rideal-drawing-manager"
      mapContainerStyle={{ height: "250px", width: "100%" }}
      zoom={8}
      onLoad={onMapReady}
      options={{
        disableDefaultUI: true,
        fullscreenControl: true,
        zoomControl: true,
      }}
    ></GoogleMap>
  );

  const renderTriggeredRules = () => {
    return (
      <Box pl={5}>
        {currentResponse &&
          currentResponse.items.map((item, index) => {
            const itemPercentage = item as SimulationResponseItemPercentage;
            const itemMonetaryValue = item as SimulationResponseItemMonetaryValue;
            return (
              <Box mb={4} key={index}>
                <UnorderedList>
                  <ListItem>
                    {i18next.t("simulator:result.triggeredRules.programme", {
                      programme: item.programme,
                    })}
                  </ListItem>
                  <ListItem>
                    {i18next.t("simulator:result.triggeredRules.rule", {
                      rule: item.rule,
                    })}
                  </ListItem>

                  {item.type === SimulationResponseItemType.percentage && (
                    <div>
                      <ListItem>
                        {i18next.t(
                          "simulator:result.triggeredRules.subsidyPercentage",
                          { subsidyPercentage: itemPercentage.percentage }
                        )}
                      </ListItem>
                      <ListItem>
                        {i18next.t(
                          "simulator:result.triggeredRules.subsidyValue",
                          {
                            subsidyValue: itemPercentage.subsidyValue,
                            currencyCode: itemMonetaryValue.currencyCode,
                          }
                        )}
                      </ListItem>
                    </div>
                  )}

                  {item.type === SimulationResponseItemType.monetaryValue && (
                    <div>
                      <ListItem>
                        {i18next.t(
                          "simulator:result.triggeredRules.baseSubsidy",
                          {
                            baseSubsidy: itemMonetaryValue.baseSubsidy
                              ? itemMonetaryValue.baseSubsidy.toFixed(2)
                              : "0",
                            currencyCode: itemMonetaryValue.currencyCode,
                          }
                        )}
                      </ListItem>
                      <ListItem>
                        {i18next.t(
                          "simulator:result.triggeredRules.distanceSubsidy",
                          {
                            distanceSubsidy: itemMonetaryValue.distanceSubsidy
                              ? itemMonetaryValue.distanceSubsidy.toFixed(2)
                              : "0",
                            currencyCode: itemMonetaryValue.currencyCode,
                          }
                        )}
                      </ListItem>
                    </div>
                  )}
                </UnorderedList>
              </Box>
            );
          })}
      </Box>
    );
  };

  const onActionClicked = () => {
    const currResult = currentResponse;
    const markerPosition = markers[0].getPosition();
    if (currResult && markerPosition) {
      const markerCoords = [markerPosition.lat(), markerPosition.lng()];
      const initialPosition: Position =
        currResult.status === TripRequestType.Quote
          ? markerCoords
          : (currResult.startPosition as Position);
      const finalPosition: Position =
        currResult.status === TripRequestType.Initiate
          ? markerCoords
          : (currResult.endPosition as Position);

      const newRequest: SimulationRequest = {
        startDateTime: currResult.startDateTime,
        endDateTime: currResult.endDateTime,
        rideProvider: currResult.rideProvider,
        customAttributes: currResult.customAttributes,
        quoteId: currResult.id,
        user: currResult.user,
        startPosition: initialPosition,
        endPosition: finalPosition,
        totalTripCost: request.totalTripCost,
      };

      switch (currResult.status) {
        case TripRequestType.Quote:
          initiate(newRequest)
            .then((res) => setCurrentResponse(res))
            .catch((error) => {
              handleAxiosError(error);
              console.warn(error);
            }); // TODO show to the user
          break;
        case TripRequestType.Initiate:
          confirm(newRequest)
            .then((res) => setCurrentResponse(res))
            .catch((error) => {
              handleAxiosError(error);
              console.warn(error);
            });
          // TODO show to the user
          break;
        default:
          return;
      }
    } else {
      throw new Error("Called onActionClicked with invalid state");
    }
  };

  return (
    <Stack h="100%">
      {!currentResponse && (
        <Center h="100%">Results will be presented here</Center>
      )}
      {currentResponse && (
        <Stack align="stretch">
          <Box mt={10}></Box>

          {/* SUBSIDIZED / NON SUBSIDIZED */}
          <Text fontSize="xl" fontWeight="600">
            {i18next.t("simulator:result.title")}
          </Text>
          {currentResponse.totalSubsidizedValue > 0 ? (
            <Text fontSize="lg" fontWeight="600" color="yellowgreen">
              {i18next.t("simulator:result.subsidized")}
            </Text>
          ) : (
            <Text fontSize="lg" fontWeight="600" color="tomato">
              {i18next.t("simulator:result:noSubsidyFound")}
            </Text>
          )}
          {currentResponse.totalSubsidizedValue > 0 && (
            <Stack>
              <Text fontSize="lg">
                {i18next.t("simulator:result.totalSubsidizedValue", {
                  value: currentResponse.totalSubsidizedValue.toFixed(2),
                })}
              </Text>

              {/* TRIGGERED RULES */}
              <Text fontSize="lg" fontWeight="600">
                {i18next.t("simulator:result.triggeredRules.title")}
              </Text>
              {renderTriggeredRules()}

              {/* START/END POSITIONS */}
              <Text fontSize="md">
                {i18next.t("simulator:result.startPosition", {
                  lat: currentResponse.startPosition[1].toFixed(6),
                  lng: currentResponse.startPosition[0].toFixed(6),
                })}
              </Text>
              <Text fontSize="md">
                {i18next.t("simulator:result.endPosition", {
                  lat: currentResponse.endPosition[1].toFixed(6),
                  lng: currentResponse.endPosition[0].toFixed(6),
                })}
              </Text>

              {/** MAP **/}
              {isLoaded ? (
                renderMap()
              ) : (
                <Text>{i18next.t("simulator:map.loading")}</Text>
              )}

              {/* STATUS */}
              <Text fontSize="lg" fontWeight="600">
                {i18next.t("simulator:result.status", {
                  status: currentResponse.status,
                })}
              </Text>

              {/* INITIATE/CONFIRM ACTION */}
              {currentResponse.status !== TripRequestType.Confirm && (
                <Stack direction="row-reverse" align="stretch">
                  <Button colorScheme="blue" onClick={onActionClicked}>
                    {i18next.t(
                      currentResponse.status === TripRequestType.Quote
                        ? "simulator:result.actions.initiate"
                        : "simulator:result.actions.confirm"
                    )}
                  </Button>
                </Stack>
              )}
            </Stack>
          )}
        </Stack>
      )}
    </Stack>
  );
};

export const SimulationResult = observer(SimulationResultBase);
