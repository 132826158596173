import { Criteria, CriteriaPageItem } from "app/criteria/models";
import { getCriteria, getCriteriaPage, createCriteria, updateCriteria, deleteCriteria } from "app/criteria/services";
import { action, makeObservable, observable, runInAction } from "mobx";
import {
  BackendResponseStatus,
  ErrorResponse,
  isErrorResponse,
  PageResponse,
  SortOrder,
} from "models";
import React, { useContext } from "react";
import { CreateCriteriaCommand, UpdateCriteriaCommand } from "../models/commands";

class CriteriaStore {
  criteria: CriteriaPageItem[] = [];
  selectedCriteria: Criteria | null = null;
  totalCriteria = 0;
  state: BackendResponseStatus = "pending";

  constructor() {
    makeObservable(this, {
      criteria: observable,
      selectedCriteria: observable,
      totalCriteria: observable,
      state: observable,
      fetchCriteriaPage: action,
      fetchCriteria: action,
      createCriteria: action,
      updateCriteria: action,
      deleteCriteria: action
    });
  }

  async fetchCriteriaPage(
    programmeId: string,
    page: number,
    limit: number,
    sortField?: string,
    sortOrder?: SortOrder
  ) {
    try {
      runInAction(() => {
        this.state = "pending";
      });
      const pagedResults: PageResponse<CriteriaPageItem> = await getCriteriaPage(
        programmeId,
        page,
        limit,
        sortField,
        sortOrder
      );
      runInAction(() => {
        this.state = "done";
        this.criteria = pagedResults.data;
        this.totalCriteria = pagedResults.meta.totalElements;
      });
    } catch (error) {
      runInAction(() => {
        this.state = "error";
      });
    }
  }

  async fetchCriteria(programmeId: string, id: string) {
    runInAction(() => {
      this.state = "pending";
    });

    const response = await getCriteria(programmeId, id);
    runInAction(() => {
      if (isErrorResponse(response)) {
        this.state = "error";
        this.selectedCriteria = null;
      } else {
        this.state = "done";
        this.selectedCriteria = response;
      }
    });
  }

  async createCriteria(programmeId: string, cmd: CreateCriteriaCommand): Promise<Criteria | ErrorResponse> {
    runInAction(() => {
      this.state = "pending";
    });
    const response = await createCriteria(programmeId, cmd);
    runInAction(() => {
      if (isErrorResponse(response)) {
        this.state = "error";
      } else {
        this.state = "done";
      }
    });
    return response;
  }

  async updateCriteria(programmeId: string, criteriaId: string, cmd: UpdateCriteriaCommand): Promise<Criteria | ErrorResponse> {
    runInAction(() => {
      this.state = "pending";
    });
    const response = await updateCriteria(programmeId, criteriaId, cmd);
    runInAction(() => {
      if (isErrorResponse(response)) {
        this.state = "error";
      } else {
        this.state = "done";
      }
    });
    return response;
  }
  async deleteCriteria(programmeId: string, criteriaId:string): Promise<Criteria | ErrorResponse | void> {
    runInAction(() => {
      this.state = "pending";
    });
    const response = await deleteCriteria(programmeId, criteriaId);
    runInAction(() => {
      if (isErrorResponse(response)) {
        this.state = "error";
      } else {
        this.state = "done";
      }
    });
    return response;
  }

}


interface CriteriaStoreContextProps {
  criteriaStore: CriteriaStore;
}

export const CriteriaStoresContext = React.createContext<CriteriaStoreContextProps>(
  {} as CriteriaStoreContextProps
);

export const useCriteriaStoresContext = () => useContext(CriteriaStoresContext);

interface Props {
  children: React.ReactNode;
}

export const CriteriaStoreProvider: React.FC<Props> = ({ children }) => {
  const criteriaStore = new CriteriaStore();

  return (
    <CriteriaStoresContext.Provider value={{ criteriaStore }}>
      {children}
    </CriteriaStoresContext.Provider>
  );
};
