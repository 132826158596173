import React from "react";
import {
  CreateOrUpdateProgrammeFormViewModel,
  viewModel2CreateOrUpdateProgrammeCommand,
} from "../common/schema";
import { ProgrammeForm } from "../common/form";
import { ErrorResponse, isErrorResponse } from "models";
import { Programme, ProgrammeLimitPeriod } from "app/programmes/models";
import { useProgrammeStoreContext } from "app/programmes/stores/ProgrammeStore";

interface Props {
  programme: Programme;
  onFormSubmitted: () => void;
  onFormSubmitionCanceled: () => void;
  onFormSubmitionError: (errorResponse: ErrorResponse, msg: string) => void;
}

export const UpdateProgrammeForm: React.FC<Props> = (props: Props) => {
  const { programmeStore } = useProgrammeStoreContext();

  const initialValues: CreateOrUpdateProgrammeFormViewModel = {
    name: props.programme.name,
    currency: props.programme.currency,
    timezone: props.programme.timezone,
    startDate: props.programme.startDate,
    endDate: props.programme.endDate,
    rideProviders: props.programme.rideProviders.map((it) => it.id),
    subsidizer: props.programme.subsidizer.id,
    budget: props.programme.budget,
    tripLimit: props.programme.tripLimit?.toString() ?? "",
    tripLimitPeriod:
      props.programme.tripLimitPeriod ?? ProgrammeLimitPeriod.Day,
    subsidyLimit: props.programme.subsidyLimit?.toString() ?? '',
    subsidyLimitPeriod:
      props.programme.subsidyLimitPeriod ?? ProgrammeLimitPeriod.Day,
  };

  const submitHandler = async (
    formValues: CreateOrUpdateProgrammeFormViewModel
  ) => {
    if (programmeStore.state === 'pending') return;

    const res = await programmeStore.updateProgramme(
      props.programme.id,
      viewModel2CreateOrUpdateProgrammeCommand(formValues)
    );
    if (isErrorResponse(res)) {
      props.onFormSubmitionError(res, "");
    } else {
      props.onFormSubmitted();
    }
  };

  return (
    <ProgrammeForm
      formName="update"
      initialValues={initialValues}
      submitHandler={submitHandler}
      cancelHandler={props.onFormSubmitionCanceled}
    />
  );
};
