import { Box, Text } from "@chakra-ui/react";
import { observer } from "mobx-react";
import React from "react";
import { ContentArea } from "../app/shared";
import { Image } from "@chakra-ui/react";
import i18next from "i18next";
import logo from "../app/shared/assets/logo.png";

const HomePage: React.FC = () => {
  return (
    <ContentArea className="homeContainer">
      <Text textAlign="center" fontWeight="600" fontSize="4xl">
        {i18next.t("shared:home.intro")}
      </Text>
      <Image className="homeLogo" width="242px" src={logo} alt="site-logo" />
      <Box>
        <Text textAlign="center">
          {i18next.t("shared:home.intro")}
          <span style={{ color: "#E51873", fontWeight: "bold" }}>
            Rideal´s
          </span>
          {i18next.t("shared:home.description")}
        </Text>
      </Box>
    </ContentArea>
  );
};

export default observer(HomePage);
