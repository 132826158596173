import { AccountStoreProvider } from "app/admin/accounts/stores/AccountStore";
import { ApiKeyStoreProvider } from "app/admin/api-keys/stores/ApiKeyStore";
import { CriteriaStoreProvider } from "app/criteria/stores/CriteriaStore";
import { DeploymentStoreProvider } from "app/deployments/stores/DeploymentStore";
import { ProgrammeStoreProvider } from "app/programmes/stores/ProgrammeStore";
import { RideProviderStoreProvider } from "app/rideProviders/stores";
import { RuleStoreProvider } from "app/rules/stores";
import { SubsidizerStoreProvider } from "app/subsidizers/stores";
import { AuthProvider } from "authentication";
import { ChakraProvider, CSSReset } from "@chakra-ui/react";
import { theme } from "styles";
import Amplify from "aws-amplify";
import React from "react";
import ReactDOM from "react-dom";
import awsExports from "./aws-exports";
import "./i18n/i18n";
import App from "./pages/App";
import * as serviceWorker from "./serviceWorker";

Amplify.configure(awsExports);

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <RideProviderStoreProvider>
        <SubsidizerStoreProvider>
          <ProgrammeStoreProvider>
            <CriteriaStoreProvider>
              <DeploymentStoreProvider>
                <RuleStoreProvider>
                  <AccountStoreProvider>
                    <ApiKeyStoreProvider>
                    <ChakraProvider theme={theme} resetCSS={true}>
                      <CSSReset />
                      <App />
                    </ChakraProvider>
                    </ApiKeyStoreProvider>
                  </AccountStoreProvider>
                </RuleStoreProvider>
              </DeploymentStoreProvider>
            </CriteriaStoreProvider>
          </ProgrammeStoreProvider>
        </SubsidizerStoreProvider>
      </RideProviderStoreProvider>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
