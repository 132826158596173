import { Icon } from "@chakra-ui/react";
import i18next from "i18next";
import React from "react";
import { FaCar, FaShieldAlt, FaTachometerAlt } from "react-icons/fa";
import { GoTools } from "react-icons/go";
import { HiHome } from "react-icons/hi";
import { GrMoney } from "react-icons/gr";
import { Permission } from "../accessControl/constants";
import environment from "configurations";

export type NavItemProps = {
  title: string;
  itemId: string;
  disable?: boolean;
  elemBefore?: React.FC<unknown>;
  subNav?: NavItemProps[];
  requiredPermission?: Permission;
};

export type SideNavProps = {
  items: NavItemProps[];
  activeItemId: string;
  onSelect?: ({ itemId }: { itemId: string }) => void;
};

const simulationItem = environment.production ? 
  {
    title: i18next.t("shared:sidemenu.simulation.mainSection"),
    itemId: "/simulator",
    elemBefore: () => <Icon as={FaTachometerAlt} color="gray.600" />
  } : 
  {
    title: i18next.t("shared:sidemenu.simulation.mainSection"),
    itemId: "simulation",
    elemBefore: () => <Icon as={FaTachometerAlt} color="gray.600" />,
    subNav: [
      { 
        title: i18next.t("shared:sidemenu.simulation.simulator"), 
        itemId: "/simulation/simulator"
      },
      { 
        title: i18next.t("shared:sidemenu.simulation.dataGenerator"),
        itemId: "/simulation/data-generator" }
    ]
  }

export const SIDEMENU_NAVIGATION: NavItemProps[] = [
  {
    title: i18next.t("shared:sidemenu.home"),
    itemId: "/",
    elemBefore: () => <Icon as={HiHome} color="gray.600" />,
  },
  {
    title: i18next.t("shared:sidemenu.subsidizers.mainSection"),
    itemId: "/sponsors",
    elemBefore: () => <Icon as={GrMoney} color="gray.600" />,
    requiredPermission: "subsidizers:read"
  },
  {
    title: i18next.t("shared:sidemenu.rideproviders.mainSection"),
    itemId: "/ride-providers",
    elemBefore: () => <Icon as={FaCar} color="gray.600" />,
    requiredPermission: "ride-providers:read"
  },
  {
    title: i18next.t("shared:sidemenu.programmes.mainSection"),
    itemId: "/program",
    elemBefore: () => <Icon as={GoTools} color="gray.600" />,
    requiredPermission: "admin:*"
  }
  ,
  {
    title: i18next.t("shared:sidemenu.admin.mainSection"),
    itemId: "admin",
    elemBefore: () => <Icon as={FaShieldAlt} color="gray.600" />,
    requiredPermission: "superadmin:*",
    subNav: [
      { 
        title: i18next.t("shared:sidemenu.admin.accounts"), 
        itemId: "/admin/accounts"
      },
      { 
        title: i18next.t("shared:sidemenu.admin.apiKeys"),
        itemId: "/admin/api-keys"
      }
    ]
  }
  ,
  simulationItem
];
