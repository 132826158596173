import React from "react";
import { Form, Formik } from "formik";
import i18next from "i18next";

import { FormActions } from "app/shared/forms/FormActions";
import { GenericInput } from "app/shared/forms/GenericInput";
import { CreateOrUpdateAccountFormSchema, CreateOrUpdateAccountFormViewModel, viewModel2CreateOrUpdateAccountCommand } from "./schema";
import { createAccount } from "../services";

interface CreateAccountFormProps {
  formSubmittionCanceled: () => void;
  formSubmitted: () => void;
}

export const CreateAccountForm: React.FC<CreateAccountFormProps> = (props: CreateAccountFormProps) => {

  const defaultEmptyState: CreateOrUpdateAccountFormViewModel = {
    name: "",
    adminEmail: "",
    description: ""
  };
  
  const submitHandler = async (formValues: CreateOrUpdateAccountFormViewModel) => {
    // TODO parse response. Now we are extracting data from response on services, not sure if it make sense
    await createAccount(
      viewModel2CreateOrUpdateAccountCommand(formValues)
    );
    props.formSubmitted()
  };

  return (
    <Formik
      initialValues={defaultEmptyState}
      validationSchema={CreateOrUpdateAccountFormSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={submitHandler}>
      {(formikProps) => 
        <Form>
          <GenericInput
            autoFocus={true}
            i18nextPrefix="accounts"
            formName="create"
            fieldName="name"
            fieldType="string"
          />
          <GenericInput
            autoFocus={true}
            i18nextPrefix="accounts"
            formName="create"
            fieldName="adminEmail"
            fieldType="string"
          />
          <GenericInput
            autoFocus={true}
            i18nextPrefix="accounts"
            formName="create"
            fieldName="description"
            fieldType="string"
          />
          <FormActions 
            submitText={i18next.t("accounts:form.create.mainAction")}
            cancelHandler={props.formSubmittionCanceled}/>
        </Form>
      }
    </Formik>
  );
};
