import React from "react";
import i18next from "i18next";
import {
  CreateOrUpdateApiKeyFormViewModel,
  viewModel2CreateAccountCommand,
  validateEmail,
} from "./schema";
import { createApiKey } from "../services";
import { isErrorResponse } from "models";
import { useToast } from "@chakra-ui/toast";
import { ApiKeyForm } from "./common";
import { FormikHelpers } from "formik";

interface CreateApiKeyFormProps {
  formSubmittionCanceled: () => void;
  formSubmitted: () => void;
}

export const CreateApiKeyForm: React.FC<CreateApiKeyFormProps> = (
  props: CreateApiKeyFormProps
) => {
  const defaultEmptyState: CreateOrUpdateApiKeyFormViewModel = {
    email: "",
    description: "",
    permissions: "",
    account: {
      id: "",
      name: "",
    },
    maasProgrammes: [],
  };
  const toast = useToast();

  const submitHandler = async (
    formValues: CreateOrUpdateApiKeyFormViewModel,
    helpers: FormikHelpers<any>
  ) => {
    if (!validateEmail(formValues, helpers, true)) return;

    const response = await createApiKey(
      viewModel2CreateAccountCommand(formValues)
    );
    if (isErrorResponse(response)) {
      toast({
        title: i18next.t("api-keys:form.create.error"),
        status: "error",
        isClosable: true,
      });
    } else {
      props.formSubmitted();
    }
  };

  return (
    <ApiKeyForm
      initialState={defaultEmptyState}
      syncedWithCognito={true}
      updating={false}
      submitHandler={submitHandler}
      cancelHandler={props.formSubmittionCanceled}></ApiKeyForm>
  );
};
