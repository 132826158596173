import { CreateOrUpdateRideProviderCommand } from '../../models/commands';

import * as Yup from "yup";

export const CreateOrUpdateRideProviderFormSchema = Yup.object({
  name: Yup.string()
    .min(3, "Too Short!")
    .max(255, "Too Long!")
    .required("Required")
}).defined();

export type CreateOrUpdateRideProviderFormViewModel = Yup.InferType<
  typeof CreateOrUpdateRideProviderFormSchema
>;

export const viewModel2CreateOrUpdateRideProviderCommand = (
    formValues: CreateOrUpdateRideProviderFormViewModel
  ): CreateOrUpdateRideProviderCommand => {
    return {
      name: formValues.name
    };
  };
  