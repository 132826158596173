import { Button, Stack, useToast, AlertDialog, AlertDialogOverlay, AlertDialogContent, AlertDialogBody, AlertDialogHeader, AlertDialogFooter } from "@chakra-ui/react";
import { observer } from "mobx-react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import React from "react";
import i18next from "i18next";
import { useState, useEffect } from "react";
import { ManagedModal } from "app/shared/modal";
import { CreateAccountForm } from "./forms/create-account";
import { useAccountStoreContext } from "./stores/AccountStore";
import { isErrorResponse, SortOrder } from "models";
import { AccountListItem } from "./models/commands";
import { deleteAccount } from "./services";

const AccountsListBase: React.FC = () => {
  const cancelRef = undefined;
  const resultsSize = 30;
  const [busy, setBusy] = useState(false);
  const [page, setPage] = useState(0);
  const [sortField, setSortField] = useState("name");
  const [sortOrder, setSortOrder] = useState<SortOrder>("asc");
  const [selectedAccountForDeletion, setSelectedAccountForDeletion] = useState<AccountListItem | null>(null);
  const [openCreateAccountModal, setOpenCreateAccountModal] = useState(false);
  const { accountStore } = useAccountStoreContext();

  const toast = useToast();

  useEffect(() => {
    accountStore.fetchAccounts(
      page,
      resultsSize,
      sortField,
      sortOrder
    );
  }, [accountStore, page, resultsSize, sortField, sortOrder]);

  const onAccountCreatedSuccess = () => {
    toast({
      title: i18next.t("accounts:form.create.success"),
      status: "success",
      isClosable: true,
    });
    setOpenCreateAccountModal(false);
    accountStore.fetchAccounts(0, 30, sortField, sortOrder); // TODO: review these defaults
  };

  const deleteBtnTemplate = (rowData: AccountListItem) => {
    return <Button
      colorScheme="red"
      size="xs"
      onClick={() => setSelectedAccountForDeletion(rowData)}>
        {i18next.t('accounts:table.delete')}
      </Button>
  }

  const deleteAccountAction = async (account: AccountListItem) => {
    setBusy(true);
    const response = await deleteAccount(account.id);
    if (isErrorResponse(response)) {
      toast({
        title: i18next.t(response?.httpStatusCode === 409 ? "accounts:form.delete.error.conflict" : "accounts:form.delete.error.generic"),
        status: "error",
        isClosable: true,
      });
    } else {
      toast({
        title: i18next.t("accounts:form.delete.success"),
        status: "success",
        isClosable: true,
      });
      accountStore.fetchAccounts(
        page,
        resultsSize,
        sortField,
        sortOrder
      );
    }
    setBusy(false);
    setSelectedAccountForDeletion(null);
  };

  return (
    <Stack dir="column" spacing="3">
      <Button onClick={() => setOpenCreateAccountModal(true)}>
        {i18next.t("accounts:table.modal.create.openModal")}
      </Button>

      {accountStore.state !== "error" && (
        <DataTable
          paginatorTemplate="RowsPerPageDropdown PageLinks FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          paginator
          lazy={true}
          rows={resultsSize}
          value={accountStore.accounts}
          first={page * resultsSize}
          onPage={(e: any) => setPage(e.page) }
          totalRecords={accountStore.totalAccounts}
          sortField={sortField}
          sortOrder={sortOrder === "asc" ? 1 : -1}
          onSort={(e) => {
            setSortField(e.sortField);
            setSortOrder(e.sortOrder === 1 ? "asc" : "desc");
          }}>
          <Column
            field="name"
            header={i18next.t("accounts:table.columns.name")}
            sortable={true}
          />
          <Column
            field="email"
            header={i18next.t("accounts:table.columns.adminEmail")} />
          <Column
            header={i18next.t("api-keys:table.columns.action")}
            body={deleteBtnTemplate} />
        </DataTable>
      )}

      {/* create account modal */}
      <ManagedModal
        openModal={openCreateAccountModal}
        title={i18next.t("accounts:form.create.title")}
        content={
          <CreateAccountForm
            formSubmitted={onAccountCreatedSuccess}
            formSubmittionCanceled={() => { setOpenCreateAccountModal(false)}}
          />
        }
        closeHandler={() => setOpenCreateAccountModal(false)}
        contentMinHeight={"350px"}
        showMainAction={false}
        showClose={false}
        closeOnOverlayClick={false}
      />

      {/* delete account modal */}
      <AlertDialog
        isOpen={selectedAccountForDeletion != null}
        leastDestructiveRef={cancelRef}
        onClose={() => setSelectedAccountForDeletion(null)}
        closeOnEsc={false}
        closeOnOverlayClick={false}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {i18next.t("accounts:form.delete.header")}
            </AlertDialogHeader>

            <AlertDialogBody>
              {i18next.t("accounts:form.delete.confirm", { accountName: selectedAccountForDeletion?.name })}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={() => setSelectedAccountForDeletion(null)}
                disabled={busy}>
                {i18next.t("shared:cancel")}
              </Button>
              <Button
                colorScheme="orange"
                onClick={() => deleteAccountAction(selectedAccountForDeletion!!)}
                ml={3}
                isLoading={busy}>
                {i18next.t("accounts:form.delete.confirmButton")}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Stack>
  );
};

export const AccountsList = observer(AccountsListBase);
