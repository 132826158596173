import { useToast } from "@chakra-ui/react";
import { UpdateProgrammeForm } from "app/programmes/forms/update";
import { useProgrammeStoreContext } from "app/programmes/stores/ProgrammeStore";
import React from "react";
import { Events, EventType } from "utils/events";
import i18next from "i18next";
import { ErrorResponse } from "models";
import { ContentArea } from "app/shared";
import { NavLink } from "react-router-dom";
import { Button } from "@chakra-ui/react";
import { useHistory } from "react-router-dom"
interface Props {
  programmeId: string;
}

export const ProgrammeDetailsEdit: React.FC<Props> = ({ programmeId }) => {
  const { programmeStore } = useProgrammeStoreContext();

  const toast = useToast();
  const history = useHistory(); 

  const onProgrammeUpdateSuccess = () => {
    toast({
      title: i18next.t("programmes:form.update.success"),
      status: "success",
    });

    programmeStore.fetchProgramme(programmeId);
    Events.emit(EventType.PROGRAMME_UPDATED);
  };

  const onProgrammeUpdateError = (error: ErrorResponse, msg: string) => {
    toast({ title: msg, status: "error" });
  };



  return (
    <ContentArea className="limitInput">
      {programmeStore.selectedProgramme && (
        <UpdateProgrammeForm
          programme={programmeStore.selectedProgramme}
          onFormSubmitionError={onProgrammeUpdateError}
          onFormSubmitted={onProgrammeUpdateSuccess}
          onFormSubmitionCanceled={() => 
   +       history.push('/program')
          }
        />
      )}
      <NavLink className="programLinks" to={`/programs/${programmeId}/dashboard`}>
        <Button marginTop="3rem" border="2px" borderColor="#12a19a">
          {i18next.t("programmes:programmeDetails.buttonRow.back")}
        </Button>
      </NavLink>
    </ContentArea>
  );
};
