import i18next from "i18next";
import {
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  TableCaption,
} from "@chakra-ui/table";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { useDeploymentStoreContext } from "./stores/DeploymentStore";
import { formatDate } from "utils";
import { Stack, Text } from "@chakra-ui/layout";
import { Button } from "@chakra-ui/button";
import { useToast } from "@chakra-ui/toast";
import { ruleItemDescription } from "./forms/CreateDeployment";
import { handleAxiosError } from "utils/ErrorEventHandler";

interface DeploymentDetailsProps {
  programmeId: string;
  deploymentId: string;
}

const DeploymentDetailsBase: React.FC<DeploymentDetailsProps> = ({
  programmeId,
  deploymentId,
}) => {
  const { deploymentStore } = useDeploymentStoreContext();
  const toast = useToast();

  useEffect(() => {
    deploymentStore.fetchDeployment(programmeId, deploymentId);
  }, [deploymentStore, programmeId, deploymentId]);

  const dateTemplate = (date: string | undefined) => {
    return date ? formatDate(date) : "";
  };

  const activateDeployment = () => {
    deploymentStore
      .setActive(programmeId, true)
      .then((result) => {
        toast({
          title: i18next.t("deployments:transitState.activate.success"),
          status: "success",
          isClosable: true,
        });
        deploymentStore.fetchDeployment(programmeId, deploymentId);
      })
      .catch((error) => {
        handleAxiosError(error);
        console.warn("error activating:", error);
      });
  };

  const deactivateDeployment = () => {
    deploymentStore
      .setActive(programmeId, false)
      .then((result) => {
        toast({
          title: i18next.t("deployments:transitState.deactivate.success"),
          status: "success",
          isClosable: true,
        });
        deploymentStore.fetchDeployment(programmeId, deploymentId);
      })
      .catch((error) => {
        handleAxiosError(error);
        console.warn("error deactivating:", error);
      });
  };

  return (
    <Stack dir="column" spacing="3">
      <Text fontSize="2xl" fontWeight="600">
        {i18next.t("deployments:details.title", {
          version: deploymentStore.selectedDeployment?.version,
          name: deploymentStore.selectedDeployment?.id,
        })}
      </Text>
      <Table variant="simple">
        <Tbody>
          <Tr>
            <Td>{i18next.t("deployments:details.field.deployedBy.label")}</Td>
            <Td>{deploymentStore.selectedDeployment?.deployedBy}</Td>
          </Tr>
          <Tr>
            <Td>{i18next.t("deployments:details.field.deployedAt.label")}</Td>
            <Td>
              {dateTemplate(deploymentStore.selectedDeployment?.deployedOn)}
            </Td>
          </Tr>
          <Tr>
            <Td>{i18next.t("deployments:details.field.status.label")}</Td>
            <Td>{deploymentStore.selectedDeployment?.status}</Td>
          </Tr>
          {deploymentStore.selectedDeployment?.status === "Submitted" && (
            <Tr>
              <Td>&nbsp;</Td>
              <Td>
                <Button
                  onClick={() => activateDeployment()}
                  disabled={deploymentStore.state !== "done"}
                >
                  {i18next.t("deployments:transitState.activate.btn")}
                </Button>
              </Td>
            </Tr>
          )}
          {deploymentStore.selectedDeployment?.status === "Activated" && (
            <Tr>
              <Td>&nbsp;</Td>
              <Td>
                <Button
                  onClick={() => deactivateDeployment()}
                  disabled={deploymentStore.state !== "done"}
                >
                  {i18next.t("deployments:transitState.deactivate.btn")}
                </Button>
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>

      {/****** RULES ******/}
      <Table
        className="itemBorderColor"
        width="40rem"
        borderWidth="2px"
        borderRadius="0"
        size="sm"
        variant="simple"
        colorScheme="teal"
      >
        <TableCaption placement="top">
          {i18next.t("deployments:details.field.rules.title")}
        </TableCaption>

        {deploymentStore.selectedDeployment?.rules.map((rule, ruleIndex) => (
          <Tbody key={ruleIndex}>
            <Tr>
              <Th textAlign="center" colSpan={2}>
                {i18next.t("programmes:form.createDeployment.rule.title", {
                  name: rule.name,
                })}
              </Th>
            </Tr>
            <Tr>
              <Th>
                {i18next.t("programmes:form.createDeployment.rule.baseSubsidy")}
              </Th>
              <Td key={-1}>
                {" "}
                {rule.baseSubsidy}
                {deploymentStore.selectedDeployment?.currency}
              </Td>
            </Tr>
            <Tr>
              <Th>
                {i18next.t(
                  "programmes:form.createDeployment.rule.distanceSubsidy"
                )}
              </Th>
              <Td>
                {rule.distanceSubsidy}
                {deploymentStore.selectedDeployment?.currency}
              </Td>
            </Tr>
            <Tr>
              <Th>
                {i18next.t("programmes:form.createDeployment.rule.maxSubsidy")}
              </Th>
              <Td>
                {" "}
                {rule.maxSubsidy}
                {deploymentStore.selectedDeployment?.currency}
              </Td>
            </Tr>
            {rule.items.length > 0
              ? rule.items.map((item, itemIndex) => (
                  <Tr key={itemIndex}>
                    <Th>Criteria {itemIndex + 1}</Th>
                    <Td textAlign="center">{ruleItemDescription(item)}</Td>
                  </Tr>
                ))
              : ""}
          </Tbody>
        ))}
      </Table>

      {/****** STATUS TRANSITIONS ******/}

      <Text fontSize="xl" fontWeight="600">
        {i18next.t("deployments:details.field.statusTransitions.title")}
      </Text>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>
              {i18next.t(
                "deployments:details.field.statusTransitions.dateTime"
              )}
            </Th>
            <Th>
              {i18next.t(
                "deployments:details.field.statusTransitions.initialStatus"
              )}
            </Th>
            <Th>
              {i18next.t(
                "deployments:details.field.statusTransitions.finalStatus"
              )}
            </Th>
            <Th>
              {i18next.t("deployments:details.field.statusTransitions.reason")}
            </Th>
            <Th>
              {i18next.t("deployments:details.field.statusTransitions.comment")}
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {deploymentStore.selectedDeployment?.statusTransitions &&
            deploymentStore.selectedDeployment?.statusTransitions.length > 0 &&
            deploymentStore.selectedDeployment?.statusTransitions.map(
              (statusTransition, index) => {
                return (
                  <Tr key={index}>
                    <Td>{dateTemplate(statusTransition.createdAt)}</Td>
                    <Td>{statusTransition.initialStatus}</Td>
                    <Td>{statusTransition.finalStatus}</Td>
                    <Td>
                      {i18next.t(
                        `deployments:transitState.reason.${statusTransition.reason}`
                      )}
                    </Td>
                    <Td>{statusTransition.comment}</Td>
                  </Tr>
                );
              }
            )}
        </Tbody>
      </Table>
    </Stack>
  );
};

export const DeploymentDetails = observer(DeploymentDetailsBase);
