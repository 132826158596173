export interface RuleListItem {
  id: string;
  name: string;
}

export enum RuleItemType {
  Generic = "Generic",
  Geographic = "Geographic",
}

export interface RuleItemCriteria {
  id: string;
  name: string;
  type: string;
}

export interface RuleItem {
  isStart?: boolean;
  isEnd?: boolean;
  type: RuleItemType;
  criteria: RuleItemCriteria;
}

export interface Rule {
  id: string;
  name: string;
  baseSubsidy: number;
  distanceSubsidy: number;
  maxSubsidy: number;
  percentageSubsidy: boolean;
  items: RuleItem[];
}


