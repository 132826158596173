import { action, makeObservable, observable, runInAction } from "mobx";
import { BackendResponseStatus, isErrorResponse, PageResponse, SortOrder } from "models";
import React, { useContext } from "react";
import { CreateOrUpdateProgrammeCommand } from "../models/commands";
import {
  getProgramme,
  getProgrammes,
  getProgrammeTripRequests,
  getProgrammeSubsidizerMovements,
  getTripRequestStats,
  updateProgramme,
  createProgramme
} from "../services";
import {
  Programme,
  ProgrammeListItem,
  ProgrammeStats as TripRequestStats,
  ProgrammeSubsidizerMovement,
  TripRequest,
} from "./../models";
import { ErrorResponse } from "models";


export interface ProgrammeStatViewModel {
  confirmCount: number;
  confirmPct: number;
  initiateCount: number;
  initiatePct: number;
  quoteCount: number;
  quotePct: number;
}

class ProgrammeStore {
  selectedProgramme: Programme | null = null;

  // programmes list
  programmes: ProgrammeListItem[] = [];
  totalProgrammes = 0;
  state: BackendResponseStatus = "pending";

  // trip requests list
  tripRequests: TripRequest[] = [];
  totalTripRequests = 0;
  tripRequestState: BackendResponseStatus = "pending";

  // subsidizer movements list
  subsidizerMovements: ProgrammeSubsidizerMovement[] = [];
  totalSubsidizerMovements = 0;
  movementsState: BackendResponseStatus = "pending";

  // programme stats
  stats: ProgrammeStatViewModel = {
    confirmCount: 0,
    confirmPct: 0,
    initiateCount: 0,
    initiatePct: 0,
    quoteCount: 0,
    quotePct: 0,
  };
  statState: BackendResponseStatus = "pending";

  constructor() {
    makeObservable(this, {
      programmes: observable,
      selectedProgramme: observable,
      totalProgrammes: observable,
      state: observable,
      movementsState: observable,
      tripRequestState: observable,
      statState: observable,
      stats: observable,
      fetchProgrammes: action,
      fetchProgramme: action,
      fetchProgrammeTripRequests: action,
      fetchProgrammeSubsidizerMovements: action,
      fetchTripRequestStats: action,
      updateProgramme: action,
      createProgramme: action
    });
  }

  async fetchProgrammes(
    page: number,
    limit: number,
    sortField?: string,
    sortOrder?: SortOrder
  ) {
    try {
      runInAction(() => {
        this.state = "pending";
      });
      const pagedResults: PageResponse<ProgrammeListItem> = await getProgrammes(
        page,
        limit,
        sortField,
        sortOrder
      );
      runInAction(() => {
        this.state = "done";
        this.programmes = pagedResults.data;
        this.totalProgrammes = pagedResults.meta.totalElements;
      });
    } catch (error) {
      runInAction(() => {
        this.state = "error";
      });
    }
  }

  async fetchProgramme(id: string) {
    try {
      runInAction(() => {
        this.state = "pending";
      });
      const programme: Programme = await getProgramme(id);
      runInAction(() => {
        this.state = "done";
        this.selectedProgramme = programme;
      });
    } catch (error) {
      runInAction(() => {
        this.state = "error";
        this.selectedProgramme = null;
      });
    }
  }

  async fetchProgrammeTripRequests(
    id: string,
    page: number,
    size: number,
    sortField?: string,
    sortOrder?: string,
    requestType?: string
  ) {
    try {
      runInAction(() => {
        this.tripRequestState = "pending";
      });
      const response: PageResponse<TripRequest> = await getProgrammeTripRequests(
        id,
        page,
        size,
        sortField,
        sortOrder,
        requestType
      );
      runInAction(() => {
        this.tripRequestState = "done";
        this.tripRequests = response.data;
        this.totalTripRequests = response.meta.totalElements;
      });
    } catch (error) {
      runInAction(() => {
        this.tripRequestState = "error";
        this.tripRequests = [];
        this.totalTripRequests = 0;
      });
    }
  }

  async fetchProgrammeSubsidizerMovements(
    id: string,
    page: number,
    size: number,
    sortField?: string,
    sortOrder?: string
  ) {
    try {
      runInAction(() => {
        this.movementsState = "pending";
      });
      const response: PageResponse<ProgrammeSubsidizerMovement> = await getProgrammeSubsidizerMovements(
        id,
        page,
        size,
        sortField,
        sortOrder
      );
      runInAction(() => {
        this.movementsState = "done";
        this.subsidizerMovements = response.data;
        this.totalSubsidizerMovements = response.meta.totalElements;
      });
    } catch (error) {
      runInAction(() => {
        this.movementsState = "error";
        this.subsidizerMovements = [];
        this.totalSubsidizerMovements = 0;
      });
    }
  }

  async fetchTripRequestStats(programmeId: string) {
    try {
      runInAction(() => {
        this.statState = "pending";
      });

      const response: TripRequestStats = await getTripRequestStats(programmeId);
      runInAction(() => {
        this.statState = "done";
        const sum = Math.max(
          (response.quoteCount +
            response.confirmCount +
            response.initiateCount) *
            1.0,
          1.0
        ); // to float and avoid division by zero

        this.stats = {
          quotePct: (response.quoteCount / sum) * 100,
          initiatePct: (response.initiateCount / sum) * 100,
          confirmPct: (response.confirmCount / sum) * 100,
          ...response,
        };
        return response
      });
    } catch (error) {
      runInAction(() => {
        this.statState = "error";
        this.stats = {
          confirmCount: 0,
          confirmPct: 0,
          initiateCount: 0,
          initiatePct: 0,
          quoteCount: 0,
          quotePct: 0,
        };
      });
    }
  }

  async updateProgramme(programmeId:string, cmd: CreateOrUpdateProgrammeCommand):Promise<Programme | ErrorResponse>  {
    runInAction(() => {
      this.state = "pending";
    });
    const response = await updateProgramme(programmeId, cmd);
    runInAction(() => {
      if (isErrorResponse(response)) {
        this.state = "error";
      } else {
        this.state = "done";
      }
    });
    return response;
  }

  async createProgramme( cmd: CreateOrUpdateProgrammeCommand):Promise<Programme | ErrorResponse> {
    runInAction(() => {
      this.state = "pending";
    });
    const response = await createProgramme(cmd);
    runInAction(() => {
      if (isErrorResponse(response)) {
        this.state = "error";
      } else {
        this.state = "done";
      }
    });
    return response;
  }
  }

/**
 * Context
 */
interface ProgrammeStoreContextProps {
  programmeStore: ProgrammeStore;
}

const ProgrammeStoreContext = React.createContext<ProgrammeStoreContextProps>(
  {} as ProgrammeStoreContextProps
);

export const useProgrammeStoreContext = () => useContext(ProgrammeStoreContext);

/**
 * Provider
 */
interface Props {
  children: React.ReactNode;
}

export const ProgrammeStoreProvider: React.FC<Props> = ({ children }) => {
  const programmeStore = new ProgrammeStore();
  return (
    <ProgrammeStoreContext.Provider value={{ programmeStore }}>
      {children}
    </ProgrammeStoreContext.Provider>
  );
};
