import { Box, Flex, FormControl, FormLabel, Text } from "@chakra-ui/react";
import { Field } from "formik";
import i18next from "i18next";
import React from "react";
import { StyledInput } from "./StyledComponents";

export const DEFAULT_LABEL_WIDTH = "30%";

export interface GenericFieldProps {
  fieldName: string;
  /** override default placeholder */
  placeholderOverride?: string;
  formName: string;
  fieldType: string;
  i18nextPrefix: string;
  /** override default label */
  labelOverride?: string;
  hideLabel?: boolean;
  disabled?: boolean;
  labelWidth?: any;
  autoFocus?: boolean;
  className?: string;
}

export interface FieldInternalProps {
  field: any;
  form: any;
}

export const GenericInput: React.FC<GenericFieldProps> = (
  props: GenericFieldProps
) => {
  const {
    fieldName,
    formName,
    fieldType,
    hideLabel,
    i18nextPrefix,
    autoFocus,
    className,
    placeholderOverride,
    labelOverride,
  } = props;
  const disabled = props.disabled || false;

  return (
    <div className={className ? className : ""}>
      <Field name={fieldName} type={fieldType} placeholder={fieldName}>
        {(renderProps: FieldInternalProps) => (
          <FormControl
            mb="10px"
            isInvalid={
              renderProps.form.errors[fieldName] &&
              renderProps.form.touched[fieldName]
            }>
            <Flex width={"100%"}>
              {!hideLabel && (
                <Box width={props.labelWidth ?? DEFAULT_LABEL_WIDTH} pt={4.5}>
                  <FormLabel htmlFor={fieldName}>
                    {labelOverride ??
                      i18next.t(
                        `${i18nextPrefix}:form.${formName}.fields.${fieldName}.label`
                      )}
                  </FormLabel>
                </Box>
              )}
              <Flex flexDir="column" width="100%">
                <StyledInput
                  autoFocus={autoFocus === true}
                  type={fieldType === "password" ? "password" : "text"}
                  {...renderProps.field}
                  id={fieldName}
                  placeholder={
                    placeholderOverride ??
                    i18next.t(
                      `${i18nextPrefix}:form.${formName}.fields.${fieldName}.placeholder`
                    )
                  }
                  disabled={disabled}
                  fontSize="lg"
                  px={3}
                  py={1}
                />
                <Text variant="alert-text">
                  {renderProps.form.errors[fieldName]}
                </Text>
              </Flex>
            </Flex>
          </FormControl>
        )}
      </Field>
    </div>
  );
};
