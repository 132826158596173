import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { Form, Formik, FormikHelpers } from "formik";
import { DataGeneratorFormSchema, GenerateDataViewModel, viewModel2DataGeneratorCommand } from "./components/schema";
import { GenericInput } from "app/shared/forms/GenericInput";
import { Box, Text, useToast } from "@chakra-ui/react";
import { CheckboxGroup } from "app/shared/forms/CheckboxGroup";
import { isErrorResponse } from "models";
import { handleFormError } from "app/shared/forms/utils";
import { FormActions } from "app/shared/forms/FormActions";
import { generateData } from "./service";
import i18next from "i18next";
import { GenerateDataResponse } from "./models";
import { useProgrammeStoreContext } from "app/programmes/stores/ProgrammeStore";
import { ProgrammeListItem } from "./../programmes/models";

const DataGeneratorBase: React.FC = () => {

  const { programmeStore } = useProgrammeStoreContext();
  const toast = useToast();

  useEffect(() => {
    programmeStore.fetchProgrammes(0, 30); // TODO: review these defaults
  }, [programmeStore]);


  const submitHandler = async (
    formValues: GenerateDataViewModel,
    helpers: FormikHelpers<any>
  ) => {
    helpers.setSubmitting(true);
    const cmd = viewModel2DataGeneratorCommand(formValues);
    const response = await generateData(cmd);
    helpers.setSubmitting(false);
    if (isErrorResponse(response)) {
      handleFormError(
        response,
        helpers,
        submitErrorHandler,
        "simulator.form.dataGenerator"
      );
    } else {
      submitSuccessHandler(response);
    }
  };

  const submitErrorHandler = async () => {
    toast({
      title: i18next.t("simulator:form.dataGenerator.error"),
      status: "error",
      isClosable: true
    });
  }

  const submitSuccessHandler = async (response: GenerateDataResponse) => {
    toast({
      title: i18next.t("simulator:form.dataGenerator.success", { nCreatedEntries: response.nCreatedEntries }),
      status: "success",
      isClosable: true
    });
  }

  const defaultEmptyState: GenerateDataViewModel = {
    nEntries: 0,
    programmes: []
  };

  return (
    <Formik
      initialValues={defaultEmptyState}
      validationSchema={DataGeneratorFormSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={submitHandler}>
      {(formikProps) => { 

        const onProgrammeChange = async (
          programmeId: string,
          checked: boolean) => {
            const updatedProgrammes = formikProps.values.programmes.concat([]);
            const itemIndex = updatedProgrammes.findIndex((it) => it === programmeId);
            if (checked && itemIndex === -1) {
              updatedProgrammes.push(programmeId);
            } else if (!checked && itemIndex !== -1) {
              updatedProgrammes.splice(itemIndex, 1);
            }
            formikProps.setFieldValue("programmes", updatedProgrammes);
        }

        return (<Form>
          <Box py={2} p="6">
            <Text
              color="gray.500">{i18next.t('simulator:form.dataGenerator.hint')}</Text>

            <br />

            <GenericInput
              autoFocus={true}
              i18nextPrefix="simulator"
              formName="dataGenerator"
              fieldName="nEntries"
              fieldType="number"
            />

            { programmeStore.programmes.length > 0 &&
              <CheckboxGroup
                i18nextPrefix="simulator"
                formName="dataGenerator"
                fieldName="programmes"
                values={[]}
                items={programmeStore.programmes.map((programme: ProgrammeListItem) => ({
                  label: programme.name,
                  value: programme.id,
                }))}
                onChange={onProgrammeChange}
              />
            }

            {
              programmeStore.programmes.length === 0 &&
              <Text>{i18next.t('simulator:form.dataGenerator.noProgrammes')}</Text>
            }

            <FormActions
              hideCancel={true}
              submitText={i18next.t(`simulator:form.dataGenerator.submit`)}
              disableSubmit={programmeStore.programmes.length === 0 || formikProps.isSubmitting}/>
          </Box>
        </Form>
      )}}
    </Formik>
  );
};

export const DataGenerator = observer(DataGeneratorBase);
