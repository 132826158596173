import { Position } from "geojson";
import * as Yup from "yup";

export const SimulationFormSchema = Yup.object({
  timezone: Yup.string().required(),
  startDateTime: Yup.string().required(),
  endDateTime: Yup.string().required(),
  rideProvider: Yup.string().required(),
  user: Yup.string(),
  totalTripCost: Yup.number().nullable(),
  startPosition: Yup.mixed().required(),
  endPosition: Yup.mixed().required(),
  customAttributes: Yup.array(),
}).defined();

export type SimulatorViewModel = Yup.InferType<typeof SimulationFormSchema>;

export interface SimulationPoints {
  start: {
    marker: google.maps.Marker | undefined;
    position: Position | undefined;
  };
  end: {
    marker: google.maps.Marker | undefined;
    position: Position | undefined;
  };
}
