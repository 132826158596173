import { Position } from "geojson";

export interface SimulatorSetup {
  tripAttributes: SimulatorTripAttribute[];
  rideProviders: SimulatorRideProvider[];
}

export interface SimulatorTripAttribute {
  id: string;
  name: string;
  field: string;
  dataType: string;
  value: string;
}

export interface SimulatorRideProvider {
  id: string;
  name: string;
}

export interface SimulationRequest {
  quoteId?: string;
  startDateTime: string;
  endDateTime: string;
  startPosition: Position;
  endPosition: Position;
  rideProvider: string;
  user: string | null;
  customAttributes: SimulationTripAttribute[];
  totalTripCost: number | null;
}

export interface SimulationTripAttribute {
  field: string;
  value: any;
}

export interface SimulationResponse {
  id: string;
  totalSubsidizedValue: number;
  status: TripRequestType;
  startPosition: Position;
  endPosition: Position;
  startDateTime: string;
  endDateTime: string;
  rideProvider: string;
  user: string;
  customAttributes: SimulationTripAttribute[];
  items: SimulationResponseItem[];
}

export enum SimulationResponseItemType {
  monetaryValue = "monetaryValue",
  percentage = "percentage",
}

export interface SimulationResponseItem {
  programme: string;
  rule: string;
  currencyCode: string;
  type: SimulationResponseItemType;
}

export interface SimulationResponseItemPercentage
  extends SimulationResponseItem {
  percentage: number;
  subsidyValue: number;
}

export interface SimulationResponseItemMonetaryValue
  extends SimulationResponseItem {
  baseSubsidy: number;
  distanceSubsidy: number;
}

export enum TripRequestType {
  Quote = "Quote",
  Initiate = "Initiate",
  Confirm = "Confirm",
}
