import i18next from "i18next";

const ERROR_EVENT_TYPE: string = "error";
let toastRef: any;

export const registerErrorEventHandler = (toast: any) => {
  toastRef = toast;
  document.addEventListener(ERROR_EVENT_TYPE, handlerErrorEvent, false);
};

const handlerErrorEvent = (event: any) => {
  const errorDetail: ErrorEvent = event.detail; // TODO <- use error details as needed
  toastRef({
    title: errorDetail.message,
    status: "error",
    isClosable: true,
  });
};

export function handleAxiosError(error: any) {
  console.warn(error.response);
  if(error.response){
    switch(true){
      case (error.response.status >= 500 && error.response.status < 600):
        dispatchErrorEvent({ message: i18next.t("shared:error.500.generic") });
        break;

      case (error.response.status >= 400 && error.response.status < 500): 
        if(error.response.data.errors.length > 0 ){
          error.response.data.errors.map((e:any) => dispatchErrorEvent({ message: i18next.t(`shared:error.400.${e.field}`) }));
        } else {
          dispatchErrorEvent({ message: i18next.t('shared:error.400.generic') }); 
        }      
        break;
      default: dispatchErrorEvent({ message: i18next.t("shared:error.generic") });
    }

  }
  return error;
}

export const dispatchErrorEvent = (errorEvent: ErrorEvent) => {
  document.dispatchEvent(
    new CustomEvent(ERROR_EVENT_TYPE, { detail: errorEvent })
  );
};

export interface ErrorEvent {
  message: string;
}
