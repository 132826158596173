import React from "react";
import { RideProviderStoresContext } from "./context";
import RideProviderStore from "./RideProviderStore";

interface Props {
  children: React.ReactNode;
}

export const RideProviderStoreProvider: React.FC<Props> = ({ children }) => {
  const rideProviderStore = new RideProviderStore();

  return (
    <RideProviderStoresContext.Provider value={{ rideProviderStore }}>
      {children}
    </RideProviderStoresContext.Provider>
  );
};
