import { Apikey, ApiKeyListItem, CreateApiKeyCommand, UpdateApiKeyCommand, MaasApiProgramme } from "./../models/commands";
import { PageResponse, SortOrder } from "models";
import { axios } from "network";
import { handleAxiosError } from "utils/ErrorEventHandler";

export const getApiKeys = async (
  page: number,
  size: number,
  sortField?: string,
  sortOrder?: SortOrder,
  accountId?: string
): Promise<PageResponse<ApiKeyListItem>> => {
  const axiosInstance = await axios();
  const paginationParams = `/admin/api-keys?page[number]=${page}&page[size]=${size}${
      sortField && sortOrder ? `&sort[${sortField}]=${sortOrder}` : ""
    }${
      accountId ? `&account=${accountId}`: ""
    }`;
  const rawResponse = await axiosInstance.get<PageResponse<ApiKeyListItem>>(paginationParams);
  return { ...rawResponse.data };
};

export const getApiKey = async (apiKey: string): Promise<Apikey> => {
  const axiosInstance = await axios();
  return axiosInstance
    .get<Apikey>(`/admin/api-keys/${apiKey}`)
    .then((response) => response.data)
    .catch((error) => {
      handleAxiosError(error);
      return error.response.data;
    });
};

export const createApiKey = async (
  cmd: CreateApiKeyCommand
): Promise<Apikey> => {
  const axiosInstance = await axios();
  return axiosInstance
    .post<Apikey>("/admin/api-keys", cmd)
    .then((response) => response.data)
    .catch((error) => {
      handleAxiosError(error);
      return error.response.data;
    });
};

export const updateApiKey = async (
  apiKey: string,
  cmd: UpdateApiKeyCommand
): Promise<Apikey> => {
  const axiosInstance = await axios();
  return axiosInstance
    .put<Apikey>(`/admin/api-keys/${apiKey}`, cmd)
    .then((response) => response.data)
    .catch((error) =>{
      handleAxiosError(error);
      return error.response.data;
    });
};

export const revokeApiKey = async (
  apiKey: string
): Promise<void> => {
  const axiosInstance = await axios();
  return axiosInstance
    .delete<void>(`/admin/api-keys/${apiKey}`)
    .then((response) => response.data)
    .catch((error) => {
      handleAxiosError(error);
      return error.response.data;
    });
}

export const getAvailableProgrammes = async(): Promise<MaasApiProgramme[]> => {
  const axiosInstance = await axios();
  return axiosInstance
    .get<void>(`/admin/api-keys/available-programmes`)
    .then((response) => response.data)
    .catch((error) =>{
      handleAxiosError(error);
      return error.response.data;
    });
}