import { AccountsList } from "app/admin/accounts/AccountsList";
import { ContentArea } from "app/shared";
import React from "react";

const AccountsListPage: React.FC = () => {
  return (
    <ContentArea>
      <AccountsList />
    </ContentArea>
  );
};

export default AccountsListPage;
