import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  Heading,
  useToast,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
} from "@chakra-ui/react";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";
import i18next from "i18next";
import { observer } from "mobx-react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {
  Criteria,
  CriteriaType,
  TripAttributeCriteria,
  ScheduleCriteria,
  GeoCriteria,
} from "./models";
import React, { useEffect, useState } from "react";
import { isErrorResponse, SortOrder } from "models";
import { BiPencil, BiTrash } from "react-icons/bi";
import { getCriteria } from "./services";
import { useCriteriaStoresContext } from "./stores/CriteriaStore";
import { ListActionButton } from "app/shared/list/ListActionButton";
import { useProgrammeStoreContext } from "app/programmes/stores/ProgrammeStore";
import { ManagedModal } from "app/shared/modal";
import { UpdateTripAttributeCriteriaForm } from "./forms/tripAttribute";
import { UpdateGeoCriteriaForm } from "./forms/geographic";
import { UpdateScheduleCriteriaForm } from "./forms/schedule";
import { NavLink } from "react-router-dom";
import { InfoOutlineIcon } from "@chakra-ui/icons";
import { handleAxiosError } from "utils/ErrorEventHandler";

export interface CriteriaTableProps {
  programmeId: string;
}

const CriteriaTableBase: React.FC<CriteriaTableProps> = ({ programmeId }) => {
  const { criteriaStore } = useCriteriaStoresContext();
  const { programmeStore } = useProgrammeStoreContext();
  const toast = useToast();
  const [page, setPage] = useState(0);
  const [sortField, setSortField] = useState("name");
  const [sortOrder, setSortOrder] = useState<SortOrder>("asc");
  const [
    selectedCriteriaForUpdate,
    setSelectedCriteriaForUpdate,
  ] = useState<Criteria | null>(null);
  const [
    selectedCriteriaForDeletion,
    setSelectedCriteriaForDeletion,
  ] = useState<Criteria | null>(null);
  const [busy, setBusy] = useState(false);
  const resultsSize = 30;
  const cancelRef = undefined;

  useEffect(() => {
    criteriaStore.fetchCriteriaPage(
      programmeId,
      page,
      resultsSize,
      sortField,
      sortOrder
    );
  }, [criteriaStore, programmeId, page, resultsSize, sortField, sortOrder]);

  /***** UPDATE ******/

  const onEditClicked = async (row: Criteria) => {
    await getCriteria(programmeId, row.id)
      .then((fetchedCriteriaResponse) => {
        if (isErrorResponse(fetchedCriteriaResponse)) {
          toast({
            title: i18next.t("programmes:criteriaTable.update.notFound"),
            status: "error",
            isClosable: true,
          });
        } else {
          setSelectedCriteriaForUpdate(fetchedCriteriaResponse as Criteria);
        }
      })
      .catch((error) => {
        handleAxiosError(error);
      });
  };

  const updateButtonTemplate = (row: Criteria) => {
    return (
      <ListActionButton
        icon={BiPencil}
        clickHandler={() => onEditClicked(row)}
      />
    );
  };

  const onCriteriaUpdateSuccess = () => {
    toast({
      title: i18next.t("programmes:form.updateCriteria.success"),
      status: "success",
      isClosable: true,
    });
    criteriaStore.fetchCriteriaPage(
      programmeId,
      page,
      resultsSize,
      sortField,
      sortOrder
    );
    programmeStore.fetchProgramme(programmeId);
    setSelectedCriteriaForUpdate(null);
  };

  const onCriteriaUpdateError = (err: any, msg: string) => {
    setSelectedCriteriaForUpdate(null);
    toast({ title: msg, status: "error", isClosable: true });
  };

  /***** DELETE ******/

  const deleteButtonTemplate = (row: Criteria) => {
    return (
      <ListActionButton
        icon={BiTrash}
        clickHandler={() => setSelectedCriteriaForDeletion(row)}
      />
    );
  };

  const deleteCriteriaAction = async (criteria: Criteria) => {
    setBusy(true);
    await criteriaStore.deleteCriteria(programmeId, criteria.id)
      .then((response) => {
        toast({
          title: i18next.t("programmes:criteriaTable.delete.success"),
          status: "success",
          isClosable: true,
        });
        criteriaStore.fetchCriteriaPage(
          programmeId,
          page,
          resultsSize,
          sortField,
          sortOrder
        );
        programmeStore.fetchProgramme(programmeId);
        setBusy(false);
        setSelectedCriteriaForDeletion(null);
      })
      .catch((e) => {
        setBusy(false);
        handleAxiosError(e);
      });
  };

  return (
    <Box>
      <Flex flexDir="row" alignItems="center">
        <Heading m={4}>{i18next.t("programmes:criteriaTable.header")}</Heading>
        <Popover gutter={15} isLazy>
          <PopoverTrigger>
            <InfoOutlineIcon />
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverBody fontSize="md" fontWeight="400">
              {i18next.t("programmes:criteriaTable.description")}
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Flex>

      {criteriaStore.state !== "error" && (
        <DataTable
          paginatorTemplate="RowsPerPageDropdown PageLinks FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          paginator
          lazy={true}
          rows={resultsSize}
          value={criteriaStore.criteria}
          first={page * resultsSize}
          onPage={(e: any) => {
            setPage(e.page);
          }}
          totalRecords={criteriaStore.totalCriteria}
          sortField={sortField}
          sortOrder={sortOrder === "asc" ? 1 : -1}
          onSort={(e) => {
            setSortField(e.sortField);
            setSortOrder(e.sortOrder === 1 ? "asc" : "desc");
          }}
        >
          <Column
            field="name"
            header={i18next.t(
              "programmes:criteriaTable.dataTable.columns.name"
            )}
            sortable={true}
          />
          <Column
            field="type"
            header={i18next.t(
              "programmes:criteriaTable.dataTable.columns.type"
            )}
            sortable={true}
          />
          <Column
            body={updateButtonTemplate}
            headerStyle={{ width: "8em", textAlign: "center" }}
            bodyStyle={{ textAlign: "center", overflow: "visible" }}
          />
          <Column
            body={deleteButtonTemplate}
            headerStyle={{ width: "8em", textAlign: "center" }}
            bodyStyle={{ textAlign: "center", overflow: "visible" }}
          />
        </DataTable>
      )}
      <NavLink to={`/programs/${programmeId}/dashboard`}>
        <Button marginTop="3rem" border="2px" borderColor="#12a19a">
          {i18next.t("programmes:programmeDetails.buttonRow.back")}
        </Button>
      </NavLink>

      {/* UPDATE SCHEDULE CRITERIA */}
      <ManagedModal
        openModal={
          selectedCriteriaForUpdate !== null &&
          selectedCriteriaForUpdate.type === CriteriaType.Schedule
        }
        title={i18next.t("programmes:form.createScheduleCriteria.title")}
        content={
          <UpdateScheduleCriteriaForm
            programmeId={programmeId}
            scheduleCriteria={selectedCriteriaForUpdate as ScheduleCriteria}
            onFormSubmitted={onCriteriaUpdateSuccess}
            onFormSubmitionCanceled={() => setSelectedCriteriaForUpdate(null)}
            onFormSubmitionError={onCriteriaUpdateError}
          />
        }
        mainActionHandler={() => {
          /** NO-OP */
        }}
        mainActionDisabled={false}
        mainActionText={i18next.t(
          "programmes:programmesTable.forms.mainAction"
        )}
        closeText={i18next.t("programmes:programmesTable.forms.cancel")}
        closeHandler={() => setSelectedCriteriaForUpdate(null)}
        contentMinHeight={"350px"}
        showMainAction={false}
        showClose={false}
        closeOnOverlayClick={false}
      />

      {/* UPDATE GEO CRITERIA */}
      <ManagedModal
        openModal={
          selectedCriteriaForUpdate !== null &&
          selectedCriteriaForUpdate.type === CriteriaType.Geo
        }
        title={i18next.t("programmes:form.createGeoCriteria.title")}
        content={
          <UpdateGeoCriteriaForm
            programmeId={programmeId}
            criteria={selectedCriteriaForUpdate as GeoCriteria}
            onFormSubmitted={onCriteriaUpdateSuccess}
            onFormSubmitionCanceled={() => setSelectedCriteriaForUpdate(null)}
            onFormSubmitionError={onCriteriaUpdateError}
          />
        }
        mainActionHandler={() => {
          /** NO-OP */
        }}
        mainActionDisabled={false}
        mainActionText={i18next.t(
          "programmes:programmesTable.forms.mainAction"
        )}
        closeText={i18next.t("programmes:programmesTable.forms.cancel")}
        closeHandler={() => setSelectedCriteriaForUpdate(null)}
        size={"full"}
        showMainAction={false}
        showClose={false}
        closeOnOverlayClick={false}
      />

      {/* UPDATE TRIP ATTRIBUTE CRITERIA */}
      <ManagedModal
        openModal={
          selectedCriteriaForUpdate !== null &&
          selectedCriteriaForUpdate.type === CriteriaType.TripAttribute
        }
        title={i18next.t("programmes:form.createTripAttributeCriteria.title")}
        content={
          <UpdateTripAttributeCriteriaForm
            programmeId={programmeId}
            tripAttributeCriteria={
              selectedCriteriaForUpdate as TripAttributeCriteria
            }
            onFormSubmitted={onCriteriaUpdateSuccess}
            onFormSubmitionCanceled={() => setSelectedCriteriaForUpdate(null)}
            onFormSubmitionError={onCriteriaUpdateError}
          />
        }
        mainActionHandler={() => {
          /** NO-OP */
        }}
        mainActionDisabled={false}
        mainActionText={i18next.t(
          "programmes:programmesTable.forms.mainAction"
        )}
        closeText={i18next.t("programmes:programmesTable.forms.cancel")}
        closeHandler={() => setSelectedCriteriaForUpdate(null)}
        contentMinHeight={"350px"}
        showMainAction={false}
        showClose={false}
        closeOnOverlayClick={false}
      />

      {/* DELETE CRITERIA */}
      <AlertDialog
        isOpen={selectedCriteriaForDeletion != null}
        leastDestructiveRef={cancelRef}
        onClose={() => setSelectedCriteriaForDeletion(null)}
        closeOnEsc={false}
        closeOnOverlayClick={false}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {i18next.t("programmes:criteriaTable.delete.header")}
            </AlertDialogHeader>

            <AlertDialogBody>
              {i18next.t("programmes:criteriaTable.delete.confirm", {
                name: selectedCriteriaForDeletion?.name,
              })}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={cancelRef}
                onClick={() => setSelectedCriteriaForDeletion(null)}
                disabled={busy}
              >
                {i18next.t("shared:cancel")}
              </Button>
              <Button
                colorScheme="orange"
                onClick={() =>
                  deleteCriteriaAction(selectedCriteriaForDeletion!!)
                }
                ml={3}
                isLoading={busy}
              >
                {i18next.t("programmes:criteriaTable.delete.confirmButton")}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export const CriteriaTable = observer(CriteriaTableBase);
