import { AmplifyAuthenticator, AmplifySignIn } from "@aws-amplify/ui-react";
import { useAuthContext } from "authentication";
import { observer } from "mobx-react";
import React from "react";
import { Redirect, RouteComponentProps } from "react-router-dom";

const LoginBase: React.FC<RouteComponentProps> = ({ location }) => {
  const { from } = (location.state as any) || { from: { pathname: "/" } };

  const { authStore } = useAuthContext();

  return authStore.authed ? (
    <Redirect to={from} />
  ) : (
    <AmplifyAuthenticator>
      <AmplifySignIn slot="sign-in" hideSignUp={true} />
    </AmplifyAuthenticator>
  );
};

export const Login = observer(LoginBase);
