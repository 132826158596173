import { RideProvidersTable } from "app/rideProviders";
import { ContentArea } from "app/shared";
import React from "react";

const RideProvidersListPage: React.FC = () => {
  return (
    <ContentArea>
      <RideProvidersTable />
    </ContentArea>
  );
};

export default RideProvidersListPage;
