/* eslint-disable @typescript-eslint/no-var-requires */
const env = process.env.REACT_APP_ENV || "development";
const environment = {
  production: env === "production",
  api: process.env.REACT_APP_RIDEAL_API_URL,
  googleMapsApiKey: process.env.REACT_APP_GMAPS_API_KEY,
  defaults: {
    currency: "EUR",
    timezone: "Europe/Madrid"
  }
};

if (!environment.api) {
  throw new Error("The RIDEAL_API_URL environment variable is REQUIRED.");
}

module.exports = environment;
