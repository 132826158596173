import { CreateOrUpdateRideProviderCommand } from "./../models/commands";
import { RideProvider } from "./../models/index";
import { axios } from "network";
import { PageResponse } from "models";
import { SortOrder } from "models";
import { RideProviderListItem } from "../models";
import { handleAxiosError } from "utils/ErrorEventHandler";

export const getRideProviders = async (
  page: number,
  size: number,
  sortField?: string,
  sortOrder?: SortOrder
): Promise<PageResponse<RideProviderListItem>> => {
  try{
  const axiosInstance = await axios();
  const paginationParams = `/ride-providers?page[number]=${page}&page[size]=${size}${
    sortField && sortOrder ? `&sort[${sortField}]=${sortOrder}` : ""
  }`;
  const rawResponse = await axiosInstance.get<
    PageResponse<RideProviderListItem>
  >(paginationParams);
  return { ...rawResponse.data }}
  catch (err) {
    handleAxiosError(err);
    return err.response.data;
  }
};

export const createRideProvider = async (
  cmd: CreateOrUpdateRideProviderCommand
): Promise<RideProvider> => {
  const axiosInstance = await axios();
  return axiosInstance
    .post<RideProvider>("/ride-providers", cmd)
    .then((response) => response.data)
    .catch((error) => handleAxiosError(error));
};

export const updateRideProvider = async (
  rideProviderId: string,
  cmd: CreateOrUpdateRideProviderCommand
): Promise<RideProvider> => {
  const axiosInstance = await axios();
  return axiosInstance
    .put<RideProvider>(`/ride-providers/${rideProviderId}`, cmd)
    .then((response) => response.data)
    .catch((error) => handleAxiosError(error));
};

export const getRideProvider = async (id: string): Promise<RideProvider> => {
  const axiosInstance = await axios();
  return axiosInstance
    .get<RideProvider>(`/ride-providers/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => handleAxiosError(error));
};
