import { Box, Button, Flex, useToast } from "@chakra-ui/react";
import { GenericInput } from "app/shared/forms/GenericInput";
import { Form, Formik, FormikHelpers } from "formik";
import i18next from "i18next";
import React, { useCallback, useEffect, useRef, useState } from "react";
import CURRENCIES from "../../static/currencies.json";
import {
  CreateOrUpdateProgrammeFormSchema,
  CreateOrUpdateProgrammeFormViewModel,
} from "./schema";
import { TimezoneSelector } from "app/shared/forms/TimezoneSelector";
import { GenericSelect } from "app/shared/forms/GenericSelect";
import { DatePickerInput } from "app/shared/forms/DatePickerInput";
import { FormActions } from "app/shared/forms/FormActions";
import { observer } from "mobx-react";
import { useRideProviderStoresContext } from "app/rideProviders/stores/context";
import { useSubsidizerStoreContext } from "app/subsidizers/stores";
import { Subsidizer } from "app/subsidizers/models";
import { SelectOption } from "../../../shared/select";
import { useProgrammeStoreContext } from "app/programmes/stores/ProgrammeStore";
import { LimitComponent } from "./limit";
import {
  ProgrammeLimitPeriod,
  ProgrammeLimitType,
} from "app/programmes/models";
import { handleAxiosError } from "utils/ErrorEventHandler";
import { ManagedModal } from "app/shared/modal";
import { CreateSubsidizerForm } from "app/subsidizers/forms/create";
import { ErrorResponse } from "models";
import { AddIcon } from "@chakra-ui/icons";
import { CreateablePicker } from "../../../shared/forms/GenericMultiSelect";
import { viewModel2CreateOrUpdateRideProviderCommand } from "app/rideProviders/forms/common/schema";

export interface Props {
  formName: string;
  initialValues: CreateOrUpdateProgrammeFormViewModel;
  submitHandler: (
    formValues: CreateOrUpdateProgrammeFormViewModel,
    helpers: FormikHelpers<any>
  ) => void;
  cancelHandler: () => void;
}

const ProgrammeFormBase: React.FC<Props> = (props: Props) => {
  const { rideProviderStore } = useRideProviderStoresContext();
  const { subsidizerStore } = useSubsidizerStoreContext();
  const { programmeStore } = useProgrammeStoreContext();

  const [loaded, setLoaded] = useState(false);
  const [subsidizersList, setSubsidizersList] = useState<Array<SelectOption>>(
    []
  );
  const [selectedSubsidizer, setSelectedSubsidizer] = useState<
    SelectOption | undefined
  >(undefined);

  const createdSubsidizerId = useRef<string | undefined>();

  const toast = useToast();

  const [openCreateSubsidizerModal, setOpenCreateSubsidizerModal] = useState(
    false
  );

  const buildSubsidizerOptions = useCallback(() => {
    return subsidizerStore.subsidizers.map((subElement) => {
      return {
        label: subElement.name,
        value: subElement.id,
      };
    });
  }, [subsidizerStore]);

  const [pickerItems, setPickerItems] = useState<Array<SelectOption>>([]);
  const [selectedItems, setSelectedItems] = useState<Array<SelectOption>>([]);

  const buildRideProviderOptions = useCallback(() => {
    rideProviderStore.fetchRideProviders(0, 1000, "name", "asc");
    return rideProviderStore.rideProviders.map((subElementRide) => ({
      label: subElementRide.name,
      value: subElementRide.id,
    }));
  }, [rideProviderStore]);

  useEffect(() => {
    const rideProvidersPromise = rideProviderStore.fetchRideProviders(
      0,
      1000,
      "name",
      "asc"
    );
    const subsidizersPromise = subsidizerStore.fetchSubsidizers(
      0,
      1000,
      "name",
      "asc"
    );

    Promise.all([rideProvidersPromise, subsidizersPromise])
      .then(() => {
        
        setSubsidizersList(buildSubsidizerOptions());
        setPickerItems(buildRideProviderOptions());
        if(programmeStore.selectedProgramme && props.formName === 'update') { 
          setSelectedSubsidizer({
            label: programmeStore.selectedProgramme?.subsidizer.name!!,
            value: programmeStore.selectedProgramme?.subsidizer.id!!,
          });
          setSelectedItems(
            programmeStore.selectedProgramme.rideProviders.map((item) => {
              return { label: item.name, value: item.id };
            })
          );
        }
        setLoaded(true);
      })
      .catch((error) => handleAxiosError(error));
  }, [
    programmeStore,
    rideProviderStore,
    subsidizerStore,
    loaded,
    buildSubsidizerOptions,
    buildRideProviderOptions,
    props.formName
  ]);

  useEffect(() => {
    const selectedOption = subsidizersList?.find(
      (sponsorElement) => sponsorElement.value === createdSubsidizerId.current
    );
    setSelectedSubsidizer(selectedOption);
    createdSubsidizerId.current = undefined;
  }, [subsidizersList]);

  return (
    <>
      <Formik
        initialValues={props.initialValues}
        validationSchema={CreateOrUpdateProgrammeFormSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={props.submitHandler}
      >
        {(formikProps) => {

          const handleSelectedItemsChange = (
            selectedItems: Array<SelectOption>
          ) => {
            if (selectedItems) {
              setSelectedItems(selectedItems);
            }
            formikProps.setFieldValue(
              "rideProviders",
              selectedItems.map((item) => item.value)
            );
          };

          const onSubsidizerChanged = (selectedSubsidizer: SelectOption) => {
            formikProps.setFieldValue("subsidizer", selectedSubsidizer.value);
          };

          const onStartDateChanged = (newDate: Date) => {
            if (formikProps.values.endDate) {
              const startDate = new Date(newDate);
              const starDateTimestamp = startDate.getTime();
              const endDate = new Date(formikProps.values.endDate);
              const endDateTimestamp = endDate.getTime();

              if (starDateTimestamp >= endDateTimestamp) {
                const newEndDate = startDate;
                newEndDate.setDate(newEndDate.getDate() + 1);
                formikProps.setFieldValue("endDate", newEndDate);
              }
            }
          };

          const onEndDateChanged = (newDate: Date) => {
            if (formikProps.values.startDate) {
              const startDate = new Date(formikProps.values.startDate);
              const starDateTimestamp = startDate.getTime();
              const endDate = new Date(newDate);
              const endDateTimestamp = endDate.getTime();

              if (endDateTimestamp <= starDateTimestamp) {
                const newStartDate = endDate;
                newStartDate.setDate(newStartDate.getDate() - 1);
                formikProps.setFieldValue("startDate", newStartDate);
              }
            }
          };

          const onSubsidizerCreatedSuccess = (subsidizer: Subsidizer) => {
            createdSubsidizerId.current = subsidizer.id;
            formikProps.setFieldValue("subsidizer", subsidizer.id);

            toast({
              title: i18next.t("subsidizers:form.create.success"),
              status: "success",
              isClosable: true,
            });
            setOpenCreateSubsidizerModal(false);
            // TODO: review these defaults
            subsidizerStore.fetchSubsidizers(0, 30).then(() => {
              setSubsidizersList(buildSubsidizerOptions());
            });
          };

          const onSubsidizerCreateError = (
            error: ErrorResponse,
            msg: string
          ) => {
            toast({ title: msg, status: "error", isClosable: true });
            setOpenCreateSubsidizerModal(false);
          };

          const handleCreateItem = (item: any) => {
            rideProviderStore
              .createRideProvider(
                viewModel2CreateOrUpdateRideProviderCommand({
                  name: item.value,
                })
              )
              .then((res) => {
                if (res) {
                  const newItem = {
                    value: res.id,
                    label: res.name,
                  };
                  setSelectedItems((curr) => [newItem, ...curr]);
                  setPickerItems((curr) => [newItem, ...curr]);
                  const resultArray =  selectedItems.map((item) => item.value)
                  resultArray.push(res.id);
                  formikProps.setFieldValue(
                    "rideProviders",
                    resultArray
                  );
                }
                
              });
          };

          return (
            <>
              <Form>
                <Box py={2}>
                  <GenericInput
                    autoFocus={true}
                    i18nextPrefix="programmes"
                    formName="create"
                    fieldName="name"
                    fieldType="string"
                  />

                  <DatePickerInput
                    i18nextPrefix="programmes"
                    formName="create"
                    fieldName="startDate"
                    onDateChange={(newDate) => {
                      if (typeof newDate !== "object") return;

                      onStartDateChanged(newDate as Date);
                    }}
                  />

                  <DatePickerInput
                    i18nextPrefix="programmes"
                    formName="create"
                    fieldName="endDate"
                    onDateChange={(newDate) => {
                      if (typeof newDate !== "object") return;

                      onEndDateChanged(newDate as Date);
                    }}
                  />

                  <GenericSelect
                    i18nextPrefix="programmes"
                    formName="create"
                    fieldName="currency"
                    fieldType="string"
                    options={CURRENCIES.map((currency) => ({
                      value: currency,
                      label: currency,
                    }))}
                    selectedOption={{
                      value: props.initialValues.currency,
                      label: props.initialValues.currency,
                    }}
                  />
                  <Flex>
                    <GenericSelect
                      i18nextPrefix="programmes"
                      formName="create"
                      fieldName="sponsors"
                      fieldType="string"
                      labelWidth="33%"
                      options={subsidizersList}
                      selectedOption={selectedSubsidizer}
                      onChange={onSubsidizerChanged}
                      value={selectedSubsidizer}
                    />
                    <Button
                      className="newSponsorBtn"
                      onClick={() => setOpenCreateSubsidizerModal(true)}
                    >
                      <AddIcon />
                    </Button>
                  </Flex>
                  <GenericInput
                    i18nextPrefix="programmes"
                    formName="create"
                    fieldName="budget"
                    fieldType="number"
                  />

                  <LimitComponent
                    type={ProgrammeLimitType.Subsidy}
                    period={
                      props.initialValues.subsidyLimitPeriod ??
                      ProgrammeLimitPeriod.Day
                    }
                  />
                  <LimitComponent
                    type={ProgrammeLimitType.Trip}
                    period={
                      props.initialValues.tripLimitPeriod ??
                      ProgrammeLimitPeriod.Day
                    }
                  />

                  <TimezoneSelector
                    i18nextPrefix="programmes"
                    formName="create"
                    fieldName="timezone"
                    selectedOption={{
                      value: props.initialValues.timezone,
                      label: props.initialValues.timezone,
                    }}
                  />
                  {pickerItems.length > 0 && (
                    <CreateablePicker
                      placeholder={i18next.t(
                        "programmes:form.create.fields.rideProviders.placeholder"
                      )}
                      onCreateItem={handleCreateItem}
                      items={pickerItems}
                      selectedItems={selectedItems}
                      onSelectedItemsChange={(changes: any) =>
                        handleSelectedItemsChange(changes.selectedItems)
                      }
                      formName="create"
                      fieldName="rideProviders"
                      fieldType="text"
                      i18nextPrefix="programmes"
                    />
                  )}
                </Box>
                <FormActions
                  submitText={i18next.t(
                    `subsidizers:form.${props.formName}.submit`
                  )}
                  cancelHandler={props.cancelHandler}
                  disableSubmit={programmeStore.state === "pending"}
                />
              </Form>
              {openCreateSubsidizerModal && (
                <ManagedModal
                  openModal={openCreateSubsidizerModal}
                  title={i18next.t("subsidizers:form.create.title")}
                  content={
                    <CreateSubsidizerForm
                      onFormSubmitionError={onSubsidizerCreateError}
                      onFormSubmitted={onSubsidizerCreatedSuccess}
                      onFormSubmitionCanceled={() => {
                        setOpenCreateSubsidizerModal(false);
                      }}
                    />
                  }
                  closeHandler={() => setOpenCreateSubsidizerModal(false)}
                  showMainAction={false}
                  showClose={false}
                  closeOnOverlayClick={false}
                />
              )}
            </>
          );
        }}
      </Formik>
    </>
  );
};

export const ProgrammeForm = observer(ProgrammeFormBase);
