export type BackendResponseStatus = "pending" | "done" | "error";
export type SortOrder = "asc" | "desc";

export interface PageResponse<T> {
  meta: {
    page: number;
    size: number;
    totalElements: number;
  };
  data: T[];
  links: {
    first: string | null;
    last: string | null;
    self: string;
    next: string | null;
    prev: string | null;
  };
}

export interface FieldError {
  errorCode: string;
  field: string;
  args?: unknown;
}

export interface ErrorResponse {
  httpStatusCode: number;
  id: string;
  messageCode: string;
  title: string;
  errors?: FieldError[];
}

export const isErrorResponse = (
  response: ErrorResponse | unknown
): response is ErrorResponse => {
  return (response as any)?.httpStatusCode !== undefined;
};

export type DayOfWeek =
  | "MONDAY"
  | "TUESDAY"
  | "WEDNESDAY"
  | "THURSDAY"
  | "FRIDAY"
  | "SATURDAY"
  | "SUNDAY";

export const DayOfWeekList: DayOfWeek[] = [
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY",
  "SUNDAY",
];

export enum Operator {
  GreaterThan = "GreaterThan",
  GreaterThanOrEqual = "GreaterThanOrEqual",
  LessThan = "LessThan",
  LessThanOrEqual = "LessThanOrEqual",
  Equal = "Equal",
  NotEqual = "NotEqual",
}

export const OperatorList: Operator[] = Object.values(Operator);

export enum DataType {
  String = "String",
  Integer = "Integer",
  Float = "Float",
  Boolean = "Boolean",
}
export const DataTypeList: DataType[] = Object.values(DataType);
