import { Box, Flex, Heading } from "@chakra-ui/layout";
import {
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react";
import { observer } from "mobx-react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import i18next from "i18next";
import React, { useEffect, useState } from "react";
import { useDeploymentStoreContext } from "./stores/DeploymentStore";
import { useHistory } from "react-router";
import { SortOrder } from "models";
import { DeploymentListItem } from "./models";
import { formatDate } from "utils";
import { NavLink } from "react-router-dom";
import { InfoOutlineIcon } from "@chakra-ui/icons";
import { handleAxiosError } from "utils/ErrorEventHandler";

interface DeploymentTableProps {
  programmeId: string;
}

const DeploymentsTableBase: React.FC<DeploymentTableProps> = ({
  programmeId,
}) => {
  const { deploymentStore } = useDeploymentStoreContext();
  const history = useHistory();
  const [page, setPage] = useState(0);
  const [sortField, setSortField] = useState("version");
  const [sortOrder, setSortOrder] = useState<SortOrder>("desc");
  const resultsSize = 30;

  useEffect(() => {
    deploymentStore.fetchDeployments(
      programmeId,
      page,
      resultsSize,
      sortField,
      sortOrder
    );
  }, [deploymentStore, programmeId, page, resultsSize, sortField, sortOrder]);

  const dateTemplate = (rowData: DeploymentListItem) => {
    return formatDate(rowData.deployedOn);
  };

  const onRowClick = (e: {
    originalEvent: Event;
    data: DeploymentListItem;
    index: number;
  }) => {
    deploymentStore
      .fetchDeployment(programmeId, e.data.id)
      .then(() => {
        history.push(`/programs/${programmeId}/deployments/${e.data.id}`);
      })
      .catch((error) => {
        handleAxiosError(error);
        console.error(error);
      });
  };

  return (
    <Box>
      <Flex flexDir="row" alignItems="center">
        <Heading m={4}>{i18next.t("deployments:table.header")}</Heading>
        <Popover gutter={15} isLazy>
          <PopoverTrigger>
            <InfoOutlineIcon />
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverBody fontSize="md" fontWeight="400">
              {i18next.t("deployments:table.description")}
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Flex>
      {deploymentStore.state !== "error" && (
        <DataTable
          className="clickable-table"
          rowHover={true}
          onRowClick={onRowClick}
          paginatorTemplate="RowsPerPageDropdown PageLinks FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
          paginator
          lazy={true}
          rows={resultsSize}
          value={deploymentStore.deployments}
          first={page * resultsSize}
          onPage={(e: any) => {
            setPage(e.page);
          }}
          totalRecords={deploymentStore.totalDeployments}
          sortField={sortField}
          sortOrder={sortOrder === "asc" ? 1 : -1}
          onSort={(e) => {
            setSortField(e.sortField);
            setSortOrder(e.sortOrder === 1 ? "asc" : "desc");
          }}
        >
          <Column
            field="version"
            header={i18next.t("deployments:table.dataTable.columns.version")}
            sortable={true}
          />
          <Column
            field="status"
            header={i18next.t("deployments:table.dataTable.columns.status")}
            sortable={true}
            sortField="current_status"
          />
          <Column
            field="deployedOn"
            header={i18next.t("deployments:table.dataTable.columns.deployedAt")}
            body={dateTemplate}
            sortable={true}
            sortField="version_date_time"
          />
        </DataTable>
      )}
      <NavLink to={`/programs/${programmeId}/dashboard`}>
        <Button marginTop="3rem" border="2px" borderColor="#12a19a">
          {i18next.t("deployments:table.dataTable.columns.button")}{" "}
        </Button>
      </NavLink>
    </Box>
  );
};

export const DeploymentsTable = observer(DeploymentsTableBase);
