import { makeObservable, observable, runInAction } from "mobx";
import { BackendResponseStatus, PageResponse, SortOrder } from "models";
import React, { useContext } from "react";
import { Apikey, ApiKeyListItem } from "../models/commands";
import { getApiKeys } from "../services";

export interface ProgrammeStatViewModel {
  confirmCount: number;
  confirmPct: number;
  initiateCount: number;
  initiatePct: number;
  quoteCount: number;
  quotePct: number;
}

class ApiKeyStore {
  selectedApiKey: Apikey | null = null;

  // api keys list
  apiKeys: ApiKeyListItem[] = [];
  totalApiKeys = 0;
  state: BackendResponseStatus = "pending";

  constructor() {
    makeObservable(this, {
      apiKeys: observable,
      selectedApiKey: observable,
      totalApiKeys: observable,
      state: observable
    });
  }

  async fetchApiKeys(
    page: number,
    limit: number,
    sortField?: string,
    sortOrder?: SortOrder,
    accountId?: string
  ) {
    try {
      runInAction(() => {
        this.state = "pending";
      });
      const pagedResults: PageResponse<ApiKeyListItem> = await getApiKeys(
        page,
        limit,
        sortField,
        sortOrder,
        accountId
      );
      runInAction(() => {
        this.state = "done";
        this.apiKeys = pagedResults.data;
        this.totalApiKeys = pagedResults.meta.totalElements;
      });
    } catch (error) {
      runInAction(() => {
        this.state = "error";
      });
    }
  }
}

/**
 * Context
 */
interface ApiKeyStoreContextProps {
  apiKeyStore: ApiKeyStore;
}

const ApiKeyStoreContext = React.createContext<ApiKeyStoreContextProps>(
  {} as ApiKeyStoreContextProps
);

export const useApiKeyStoreContext = () => useContext(ApiKeyStoreContext);

/**
 * Provider
 */
interface Props {
  children: React.ReactNode;
}

export const ApiKeyStoreProvider: React.FC<Props> = ({ children }) => {
  const apiKeyStore = new ApiKeyStore();
  return (
    <ApiKeyStoreContext.Provider value={{ apiKeyStore }}>
      {children}
    </ApiKeyStoreContext.Provider>
  );
};
