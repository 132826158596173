import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";
import { Auth } from "aws-amplify";
import { action, computed, makeObservable, observable } from "mobx";
import React, { useContext, useEffect } from "react";
import { handleAxiosError } from "utils/ErrorEventHandler";

const formatPermissions = (permissions: string) => {
  const formattedPermissions = permissions.split(",");
  return formattedPermissions;
};

/**
 * Mobx store that keeps track of the authentication state.
 * The `update` action is called when the status of the authentication changes.
 */
class AuthStore {
  authState: AuthState;
  user: any | undefined;
  username: string;
  accessToken: string;
  idToken: string;
  permissions: string[];
  account: string;
  id: string;

  constructor() {
    this.authState = {} as AuthState;
    this.user = undefined;
    this.username = "";
    this.accessToken = "";
    this.idToken = "";
    this.permissions = [];
    this.account = "";
    this.id = "";

    makeObservable(this, {
      authState: observable,
      user: observable,
      username: observable,
      accessToken: observable,
      idToken: observable,
      permissions: observable,
      account: observable,
      id: observable,
      authed: computed,
      update: action,
    });
  }

  get authed() {
    return this.authState === AuthState.SignedIn;
  }

  update(nextAuthState: AuthState, authData: any) {
    this.authState = nextAuthState;
    if (authData && authData.attributes) {
      this.user = authData;
      this.username = authData.attributes.email;
      this.permissions = formatPermissions(
        authData.attributes["custom:scopes"]
      );
      this.account = authData.attributes["custom:account"];
      this.id = authData.username;
    }

    Auth.currentSession()
      .then((credentials) => {
        const idToken = credentials.getIdToken().getJwtToken();
        const accessToken = credentials.getAccessToken();
        this.accessToken = accessToken.getJwtToken();
        this.idToken = idToken;
      })
      .catch((err) => {
        if (!this.authed) {
          console.warn("User not logged in and/or authenticated");
        } else{
          handleAxiosError(err);
          console.warn(err);
        }
      });
  }
}

/**
 * React context and provider boilerplate.
 */
export const AuthContext = React.createContext<{ authStore: AuthStore }>({
  authStore: {} as AuthStore,
});

export const useAuthContext = () => useContext(AuthContext);

interface Props {
  children: React.ReactNode;
}

export const AuthProvider: React.FC<Props> = ({ children }) => {
  const authStore = new AuthStore();

  useEffect(() => {
    onAuthUIStateChange((nextAuthState, authData: any) => {
      authStore.update(nextAuthState, authData);
    });
  });

  return (
    <AuthContext.Provider value={{ authStore }}>
      {children}
    </AuthContext.Provider>
  );
};
