import { Text } from "@chakra-ui/react";
import React from "react";
import { ContentArea } from "../app/shared";

const NotFoundPage: React.FC = () => (
  <ContentArea>
    <Text>Not Found Page</Text>
  </ContentArea>
);

export default NotFoundPage;
