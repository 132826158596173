import { ContentArea } from "app/shared";
import { SubsidizerList } from "app/subsidizers/SubsidizerList";
import React from "react";

const SubsidizersListPage: React.FC = () => {
  return (
    <ContentArea>
      <SubsidizerList />
    </ContentArea>
  );
};

export default SubsidizersListPage;
