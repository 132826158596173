import { CreateApiKeyCommand, UpdateApiKeyCommand } from "./../models/commands";
import * as Yup from "yup";
import { FormikHelpers } from "formik";
import i18next from "i18next";

export const CreateOrUpdateApiKeyFormSchema = Yup.object({
  account: Yup.object().shape({
    id: Yup.string()
      .min(36, "Too Short!")
      .max(36, "Too Long!")
      .required("Required"),
    name: Yup.string().min(1),
  }),
  email: Yup.string().email("Invalid email"),
  //.required('Required'),
  description: Yup.string()
    .min(3, "Too Short!")
    .max(255, "Too Long!")
    .required("Required"),
  permissions: Yup.string().min(3, "Too Short!").required("Required"),
  maasProgrammes: Yup.array().of(Yup.string()),
}).defined();

export type CreateOrUpdateApiKeyFormViewModel = Yup.InferType<
  typeof CreateOrUpdateApiKeyFormSchema
>;

export const viewModel2CreateAccountCommand = (
  formValues: CreateOrUpdateApiKeyFormViewModel
): CreateApiKeyCommand => {
  return {
    email: formValues.email,
    description: formValues.description,
    permissions: formValues.permissions,
    accountId: formValues.account.id,
    maasProgrammes: formValues.maasProgrammes,
  };
};

export const viewModel2UpdateAccountCommand = (
  formValues: CreateOrUpdateApiKeyFormViewModel,
  syncWithCognito: boolean
): UpdateApiKeyCommand => {
  return {
    description: formValues.description,
    permissions: formValues.permissions,
    maasProgrammes: formValues.maasProgrammes,
    syncWithCognito: syncWithCognito,
  };
};

/**
 * We mannually validate email presence due to usage of same form for create and update api
 * keys. When updating an api key, we have situations where the email is not required, for
 * instance api keys created outside rideal control center. For these situations we don't
 * require an email, but for any other we require it.
 *
 * @param formValues
 * @param helpers
 * @returns
 */
export const validateEmail = (
  formValues: CreateOrUpdateApiKeyFormViewModel,
  helpers: FormikHelpers<any>,
  syncedWithCognito: boolean
): boolean => {
  if (
    syncedWithCognito === true &&
    (!formValues.email || formValues.email.trim() === "")
  ) {
    helpers.setErrors({
      email: i18next.t("api-keys:form.create.fields.email.error.required"),
    });
    return false;
  }
  helpers.setErrors({});
  return true;
};
