import { ErrorResponse, PageResponse, SortOrder } from "models";
import { axios } from "network";
import { handleAxiosError } from "utils/ErrorEventHandler";
import { Subsidizer } from "../models";
import { CreateOrUpdateSubsidizerCommand } from "../models/commands";

export const getSubsidizers = async (
  page: number,
  size: number,
  sortField?: string,
  sortOrder?: SortOrder
): Promise<PageResponse<Subsidizer>> => {
  try{
  const axiosInstance = await axios();
  const paginationParams = `/sponsors?page[number]=${page}&page[size]=${size}${
    sortField && sortOrder ? `&sort[${sortField}]=${sortOrder}` : ""
  }`;
  const rawResponse = await axiosInstance.get<PageResponse<Subsidizer>>(
    paginationParams
  );
  return { ...rawResponse.data }}
  catch (err) {
    handleAxiosError(err);
    return err.response.data;
  }
};

export const createSubsidizer = async (
  cmd: CreateOrUpdateSubsidizerCommand
): Promise<Subsidizer | ErrorResponse> => {
  try {
    const axiosInstance = await axios();
    const response = await axiosInstance.post<Subsidizer>(`/sponsors`, cmd);
    return response.data;
  } catch (err) {
    handleAxiosError(err);
    return err.response.data;
  }
};

export const updateSubsidizer = async (
  subsidizerId: string,
  cmd: CreateOrUpdateSubsidizerCommand
): Promise<Subsidizer | ErrorResponse> => {
  try {
    const axiosInstance = await axios();
    const response = await axiosInstance.put<Subsidizer>(
      `/sponsors/${subsidizerId}`,
      cmd
    );
    return response.data;
  } catch (err) {
    handleAxiosError(err);
    return err.response.data;
  }
};

export const deleteSubsidizer = async (
  subsidizerId: string
): Promise<{ success: true } | ErrorResponse> => {
  try {
    const axiosInstance = await axios();
    await axiosInstance.delete<void>(`/sponsors/${subsidizerId}`);
    return { success: true };
  } catch (err) {
    handleAxiosError(err);
    return err.response.data;
  }
};
