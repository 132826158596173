import { CreateOrUpdateAccountCommand } from './../models/commands';
import * as Yup from "yup";

export const CreateOrUpdateAccountFormSchema = Yup.object({
  name: Yup.string()
    .min(3, "Too Short!")
    .max(255, "Too Long!")
    .required("Required"),
  adminEmail: Yup.string()
    .email('Invalid email')
    .required('Required'),
  description: Yup.string()
    .min(3, "Too Short!")
    .max(255, "Too Long!")
    .required("Required"),
}).defined();

export type CreateOrUpdateAccountFormViewModel = Yup.InferType<
  typeof CreateOrUpdateAccountFormSchema
>;

export const viewModel2CreateOrUpdateAccountCommand = (
    formValues: CreateOrUpdateAccountFormViewModel
  ): CreateOrUpdateAccountCommand => {
    return {
      name: formValues.name,
      adminEmail: formValues.adminEmail,
      description: formValues.description
    };
  };
  