import { SimulationRequest } from './../models/index';
import { axios } from "network";
import { SimulationResponse, SimulatorSetup } from "../models";

export const getPossibleTripAttributes = async (): Promise<SimulatorSetup> => {
  const axiosInstance = await axios();
  const rawResponse = await axiosInstance.get<SimulatorSetup>('/simulator/setup');
  return rawResponse.data;
};

export const simulate = async (simulationRequest: SimulationRequest): Promise<SimulationResponse> => {
  const axiosInstance = await axios();
  const rawResponse = await axiosInstance.post<SimulationResponse>('/simulator/quote', simulationRequest);
  return rawResponse.data;
};

export const initiate = async (simulationRequest: SimulationRequest): Promise<SimulationResponse> => {
  const axiosInstance = await axios();
  const rawResponse = await axiosInstance.post<SimulationResponse>('/simulator/initiate', simulationRequest);
  return rawResponse.data;
};

export const confirm = async (simulationRequest: SimulationRequest): Promise<SimulationResponse> => {
  const axiosInstance = await axios();
  const rawResponse = await axiosInstance.post<SimulationResponse>('/simulator/confirm', simulationRequest);
  return rawResponse.data;
};