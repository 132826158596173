import { Subsidizer } from "./../../subsidizers/models/index";
import { TripRequestType } from "./../../simulator/models/index";
import { RideProvider } from "app/rideProviders/models";

export type ProgrammeStatus = "draft" | "published" | "suspended" | "approved";

export interface Programme {
  id: string;
  name: string;
  currency: string;
  budget: number;
  remainingBudget: number;
  startDate: string;
  endDate: string;
  creator?: string;
  createdAt?: string;
  timezone: string;
  lastModified?: string;
  status?: ProgrammeStatus;
  currentVersion?: string;
  deployed: boolean;
  rideProviders: RideProvider[];
  subsidizer: Subsidizer;
  tripLimit?: number;
  tripLimitPeriod?: ProgrammeLimitPeriod;
  subsidyLimit?: number;
  subsidyLimitPeriod?: ProgrammeLimitPeriod;
}

export interface ProgrammeListItem {
  id: string;
  name: string;
  // timezone: string;
  currency: string;
  currentVersion: number;
  active: boolean;
  createdAt: string;
  totalAvailableBudget: string;
}

export interface ProgrammeSubsidizerMovement {
  programmeId: string;
  subsidizerId: string;
  rideProvider: string;
  amount: number;
  createdAt: string;
}

export interface ProgrammesBudget {
  currencyCode: string;
  total: number;
  remaining: number;
}

export interface TripRequest {
  id: string;
  externalId: string;
  requestType: TripRequestType;
  rideProvider: string;
  totalSubsidies: number;
  distanceSubsidies: number;
  baseSubsidies: number;
  createdAt: string;
}

export interface ProgrammeStats {
  confirmCount: number;
  initiateCount: number;
  quoteCount: number;
}

export enum ProgrammeLimitType {
  Subsidy = 'Subsidy',
  Trip = 'Trip'
}

export enum ProgrammeLimitPeriod {
  Day = 'Day',
  Week = 'Week',
  Month = 'Month'
}