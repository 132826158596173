import { Operator, DataType } from "../../../models/index";
import { Polygon } from "geojson";
import { DayOfWeek } from "models";

export type Criteria =
  | GeoCriteria
  | ScheduleCriteria
  | TripAttributeCriteria;

export interface GeoCriteria {
  type: CriteriaType.Geo;
  id: string;
  name: string;
  place: {
    geometry: Polygon;
  };
}

export interface ScheduleCriteria {
  type: CriteriaType.Schedule;
  id: string;
  name: string;
  daysOfWeek: DayOfWeek[];
  startTime: string;
  endTime: string;
}

export interface TripAttributeCriteria {
  type: CriteriaType.TripAttribute;
  id: string;
  name: string;
  field: string;
  value: string;
  operator: Operator;
  dataType: DataType;
}

export interface CriteriaPageItem {
  id: string;
  name: string;
  type: string;
}

export enum CriteriaType {
  Geo = 'Geographic',
  TripAttribute = 'TripAttribute',
  Schedule = 'Schedule',
  Limit = 'Limit'
}
