import i18next from "i18next";
import { DayOfWeekList } from "../../../../models/index";
import {
  CreateCriteriaCommand,
  UpdateCriteriaCommand,
} from "../../models/commands";
import * as Yup from "yup";
import { CriteriaType, ScheduleCriteria } from "../../models";

const TIME_REGEX = /^([0-1][0-9]|2[0-3]):[0-5][0-9]$/i;
const formI18nPrefix = "programmes:criteria.schedule.errors.validation";

export const ScheduleCriteriaFormSchema = Yup.object({
  name: Yup.string().required(i18next.t(`${formI18nPrefix}.name.notblank`)),
  daysOfWeek: Yup.array()
    .of(
      Yup.string()
        .oneOf(DayOfWeekList))
    .required(i18next.t(`${formI18nPrefix}.dayOfWeek.in`)),
  startTime: Yup.string()
    .matches(TIME_REGEX, i18next.t(`${formI18nPrefix}.startTime.matches`))
    .required(i18next.t(`${formI18nPrefix}.startTime.size`))
    .test(
      'start_time_before_end',
      'Start time must be at least one hour before the end time',
      function(value: string) {
        const endTime = this.parent.endTime
        if(!TIME_REGEX.test(value) || !TIME_REGEX.test(endTime)) return true;
        
        const startHours = parseInt(value.split(':')[0])
        const endHours = parseInt(endTime.split(':')[0])

        return startHours < endHours
      }),
  endTime: Yup.string()
    .notRequired()
    .matches(TIME_REGEX, i18next.t(`${formI18nPrefix}.endTime.matches`))
    .required(i18next.t(`${formI18nPrefix}.endTime.size`)),
}).defined();

export type ScheduleCriteriaViewModel = Yup.InferType<
  typeof ScheduleCriteriaFormSchema
>;

export const selectDayOfWeekValues = [
  {
    value: "MONDAY",
    label: i18next.t("shared:daysOfWeek.monday"),
  },
  {
    value: "TUESDAY",
    label: i18next.t("shared:daysOfWeek.tuesday"),
  },
  {
    value: "WEDNESDAY",
    label: i18next.t("shared:daysOfWeek.wednesday"),
  },
  {
    value: "THURSDAY",
    label: i18next.t("shared:daysOfWeek.thursday"),
  },
  {
    value: "FRIDAY",
    label: i18next.t("shared:daysOfWeek.friday"),
  },
  {
    value: "SATURDAY",
    label: i18next.t("shared:daysOfWeek.saturday"),
  },
  {
    value: "SUNDAY",
    label: i18next.t("shared:daysOfWeek.sunday"),
  },
];

export const scheduleCriteria2ViewModel = (
  criteria: ScheduleCriteria
): ScheduleCriteriaViewModel => {
  return {
    name: criteria.name,
    daysOfWeek: criteria.daysOfWeek,
    startTime: criteria.startTime,
    endTime: criteria.endTime,
  };
};

export const viewModel2CreateScheduleCriteriaCommand = (
  formValues: ScheduleCriteriaViewModel
): CreateCriteriaCommand => {
  return {
    type: CriteriaType.Schedule,
    name: formValues.name,
    daysOfWeek: formValues.daysOfWeek,
    startTime: formValues.startTime,
    endTime: formValues.endTime,
  };
};

export const viewModel2UpdateScheduleCriteriaCommand = (
  id: string,
  formValues: ScheduleCriteriaViewModel
): UpdateCriteriaCommand => {
  return {
    type: CriteriaType.Schedule,
    id: id,
    name: formValues.name,
    daysOfWeek: formValues.daysOfWeek,
    startTime: formValues.startTime,
    endTime: formValues.endTime,
  };
};
