import { ErrorResponse, PageResponse } from "models";
import { SortOrder } from "../../../models/index";
import {
  CreateCriteriaCommand,
  UpdateCriteriaCommand,
} from "../models/commands";
import { axios } from "network";
import { Criteria } from "../models";

export const getCriteriaPage = async (
  programmeId: string,
  page: number,
  size: number,
  sortField?: string,
  sortOrder?: SortOrder
): Promise<PageResponse<Criteria>> => {
  const axiosInstance = await axios();
  const paginationParams = `/programmes/${programmeId}/criteria?page[number]=${page}&page[size]=${size}${
    sortField && sortOrder ? `&sort[${sortField}]=${sortOrder}` : ""
  }`;
  const rawResponse = await axiosInstance.get<PageResponse<Criteria>>(
    paginationParams
  );
  return { ...rawResponse.data };
};

export const getCriteria = async (
  programmeId: string,
  id: string
): Promise<Criteria | ErrorResponse> => {
  try {
    const axiosInstance = await axios();
    const response = await axiosInstance.get<Criteria>(
      `/programmes/${programmeId}/criteria/${id}`
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

export const createCriteria = async (
  programmeId: string,
  cmd: CreateCriteriaCommand
): Promise<Criteria | ErrorResponse> => {
  try {
    const axiosInstance = await axios();
    const response = await axiosInstance.post<Criteria>(
      `/programmes/${programmeId}/criteria`,
      cmd
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

export const updateCriteria = async (
  programmeId: string,
  criteriaId: string,
  cmd: UpdateCriteriaCommand
): Promise<Criteria | ErrorResponse> => {
  try {
    const axiosInstance = await axios();
    const response = await axiosInstance.put<Criteria>(
      `/programmes/${programmeId}/criteria/${criteriaId}`,
      cmd
    );
    return response.data;
  } catch (err) {
    return err.response.data;
  }
};

export const deleteCriteria = async (
  programmeId: string,
  criteriaId: string
): Promise< ErrorResponse | void > => {
    const axiosInstance = await axios();
   const reponse = await axiosInstance.delete<void>(
      `/programmes/${programmeId}/criteria/${criteriaId}`
    );
    return reponse.data;
};
