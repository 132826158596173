import {
  Button,
  Modal as ChakraModal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import React, { ReactNode } from "react";

interface ManagedModalProps {
  size?: string;
  title?: string;
  content: ReactNode;
  showMainAction: boolean;
  mainActionText?: string;
  mainActionHandler?: () => void;
  mainActionDisabled?: boolean;
  showClose?: boolean;
  closeDisabled?: boolean;
  closeText?: string;
  closeHandler: () => void;
  contentMinHeight?: string;
  openModal: boolean;
  closeOnOverlayClick?: boolean;
}

export const ManagedModal: React.FC<ManagedModalProps> = ({
  title,
  content,
  showMainAction = true,
  mainActionHandler,
  mainActionText,
  closeText,
  size,
  mainActionDisabled,
  showClose = true,
  closeDisabled,
  closeHandler,
  contentMinHeight,
  openModal,
  closeOnOverlayClick
}) => {
  const { isOpen, onClose } = useDisclosure({ isOpen: openModal });

  const closeHandlerFn = () => {
    if(closeHandler){
      closeHandler();
    }
    onClose();
  };

  return (
    <ChakraModal
      isOpen={isOpen}
      onClose={closeHandlerFn}
      size={size ?? "3xl"}
      isCentered={true}
      closeOnOverlayClick={closeOnOverlayClick ?? true}>
      <ModalOverlay />
      <ModalContent borderRadius="0" minHeight={contentMinHeight} mb="0" mt="0">
        {title && <ModalHeader>{title}</ModalHeader>}
        <ModalCloseButton />
        {content && <ModalBody>{content}</ModalBody>}
        {(showClose || showMainAction) &&
          <ModalFooter px={0} pb={0} pt={0} justifyContent="space-between">
            {showClose && (
              <Button
                onClick={closeHandlerFn}
                w="50%"
                m={0}
                disabled={closeDisabled}
                variant="modal-btn-cancel">
                {closeText ?? "Close"}
              </Button>
            )}
            {showMainAction && (
              <Button
                onClick={mainActionHandler}
                w="50%"
                variant="modal-btn-submit"
                disabled={mainActionDisabled}>
                {mainActionText}
              </Button>
            )}
          </ModalFooter>
        }
      </ModalContent>
    </ChakraModal>
  );
};

interface Props {
  openView?: (onClick: () => void) => ReactNode;
  title?: string;
  content?: ReactNode;
  mainAction: () => void;
  mainActionText: string;
  cancelText?: string;
  size?: string;
  mainActionDisabled?: boolean;
  closeHandler?: () => void;
  contentMinHeight?: string;
  isOpenManaged?: boolean;
}

export const Modal: React.FC<Props> = ({
  openView,
  title,
  content,
  mainAction,
  mainActionText,
  cancelText,
  size,
  mainActionDisabled,
  closeHandler,
  contentMinHeight
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const closeHandlerFn = () => {
    closeHandler && closeHandler();
    onClose();
  };

  const mainActionHandler = () => {
    mainAction();
  };

  return (
    <>
      {openView && openView(onOpen)}
      <ChakraModal
        isOpen={isOpen}
        onClose={closeHandlerFn}
        size={size ?? "xl"}
        isCentered={true}>
        <ModalOverlay />
        <ModalContent borderRadius="0" minHeight={contentMinHeight}>
          {title && <ModalHeader>{title}</ModalHeader>}
          <ModalCloseButton />
          {content && <ModalBody>{content}</ModalBody>}
          <ModalFooter px={0} pb={0} justifyContent="space-between">
            <Button
              onClick={closeHandlerFn}
              w="50%"
              m={0}
              variant="modal-btn-cancel">
              {cancelText ?? "Close"}
            </Button>
            <Button
              onClick={mainActionHandler}
              w="50%"
              variant="modal-btn-submit"
              disabled={mainActionDisabled}>
              {mainActionText}
            </Button>
          </ModalFooter>
        </ModalContent>
      </ChakraModal>
    </>
  );
};
