import { CreateOrUpdateProgrammeCommand } from "./../models/commands";
import {
  TripRequest,
  ProgrammeSubsidizerMovement,
  ProgrammeStats,
  ProgrammesBudget,
} from "./../models/index";
import { PageResponse, SortOrder } from "models";
import { axios } from "network";
import { Programme, ProgrammeListItem } from "../models";
import { handleAxiosError } from "utils/ErrorEventHandler";

export const getProgrammes = async (
  page: number,
  size: number,
  sortField?: string,
  sortOrder?: SortOrder
): Promise<PageResponse<ProgrammeListItem>> => {
  const axiosInstance = await axios();
  const paginationParams = `/programmes?page[number]=${page}&page[size]=${size}${
    sortField && sortOrder ? `&sort[${sortField}]=${sortOrder}` : ""
  }`;
  const rawResponse = await axiosInstance.get<PageResponse<ProgrammeListItem>>(
    paginationParams
  );
  return { ...rawResponse.data };
};

export const getProgramme = async (id: string): Promise<Programme> => {
  const axiosInstance = await axios();
  return axiosInstance
    .get<Programme>(`/programmes/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => handleAxiosError(error));
};

export const createProgramme = async (
  cmd: CreateOrUpdateProgrammeCommand
): Promise<Programme> => {
  const axiosInstance = await axios();
  return axiosInstance
    .post<Programme>("/programmes", cmd)
    .then((response) => response.data)
    .catch((error) => handleAxiosError(error));
};

export const updateProgramme = async (
  programmeId: string,
  cmd: CreateOrUpdateProgrammeCommand
): Promise<Programme> => {
  const axiosInstance = await axios();
  return axiosInstance
    .put<Programme>(`/programmes/${programmeId}`, cmd)
    .then((response) => response.data)
    .catch((error) => handleAxiosError(error));
};

export const getProgrammeSubsidizerMovements = async (
  id: string,
  page: number,
  size: number,
  sortField?: string,
  sortOrder?: string
): Promise<PageResponse<ProgrammeSubsidizerMovement>> => {
  const axiosInstance = await axios();
  return axiosInstance
    .get<PageResponse<ProgrammeSubsidizerMovement>>(
      `/programmes/${id}/incentive-movements?` +
        `page[number]=${page}&page[size]=${size}${
          sortField && sortOrder ? `&sort[${sortField}]=${sortOrder}` : ""
        }`
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => handleAxiosError(error));
};

export const getProgrammeTripRequests = async (
  id: string,
  page: number,
  size: number,
  sortField?: string,
  sortOrder?: string,
  requestType?: string
): Promise<PageResponse<TripRequest>> => {
  const axiosInstance = await axios();
  return axiosInstance
    .get<PageResponse<TripRequest>>(
      `/programmes/${id}/trip-requests?` +
        `page[number]=${page}&page[size]=${size}${
          sortField && sortOrder ? `&sort[${sortField}]=${sortOrder}` : ""
        }${requestType === "Confirm" ? `&type=${requestType}` : ""}`
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => handleAxiosError(error));
};

export const getProgrammesBudget = async () => {
  const axiosInstance = await axios();
  return axiosInstance
    .get<ProgrammesBudget[]>(`/programmes/overall-budget`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => handleAxiosError(error));
};

export const getTripRequestStats = async (
  programmeId: string
): Promise<ProgrammeStats> => {
  const axiosInstance = await axios();
  const response = await axiosInstance.get<ProgrammeStats>(
    `/programmes/${programmeId}/trip-requests-stats`
  );

  return response.data;
};
