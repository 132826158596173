import { HStack, Stack } from "@chakra-ui/react";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { SimulationForm } from "./components/simulation-form";
import { SimulationResult } from "./components/simulation-result";
import { SimulationRequest, SimulationResponse } from "./models";

const SimulatorBase: React.FC = () => {
  const [simulationRequest, setSimulationRequest] = useState<
    SimulationRequest | undefined
  >();
  const [simulationResult, setSimulationResult] = useState<
    SimulationResponse | undefined
  >();

  const simulationResultHandler = (
    request: SimulationRequest,
    result: SimulationResponse
  ) => {
    setSimulationRequest(request);
    setSimulationResult(result);
  };

  return (
    <HStack spacing="3" align="stretch" w="100%" p="6">
      <Stack w="100%">
        <SimulationForm simulationResultHandler={simulationResultHandler} />
      </Stack>
      <Stack w="100%">
        <SimulationResult
          request={simulationRequest!!}
          result={simulationResult}
        />
      </Stack>
    </HStack>
  );
};

export const Simulator = observer(SimulatorBase);
