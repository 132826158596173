import React from "react";
import { handleFormError } from "app/shared/forms/utils";
import { Subsidizer } from "app/subsidizers/models";
import { FormikHelpers } from "formik";
import { ErrorResponse, isErrorResponse } from "models";
import {
  SubsidizerViewModel,
  viewModel2CreateOrUpdateSubsidizerCommand,
} from "../common/schema";
import { SubsidizerForm } from "../common/form";
import { useSubsidizerStoreContext } from "app/subsidizers/stores";

interface Props {
  subsidizer: Subsidizer;
  onFormSubmitted: () => void;
  onFormSubmitionCanceled: () => void;
  onFormSubmitionError: (errorResponse: ErrorResponse, msg: string) => void;
}

export const UpdateSubsidizerForm: React.FC<Props> = (props: Props) => {
  const formI18nPrefix = "subsidizers:form.create";
  const { subsidizer } = props;
  const { subsidizerStore } = useSubsidizerStoreContext();

  const subsidizerViewModel = {
    name: subsidizer.name,
    description: subsidizer.description,
  };

  const submitHandler = async (
    formValues: SubsidizerViewModel,
    helpers: FormikHelpers<any>
  ) => {
    if(subsidizerStore.state === 'pending') return;
    
    helpers.setSubmitting(true);
    const cmd = viewModel2CreateOrUpdateSubsidizerCommand(formValues);
    const response = await subsidizerStore.updateSubsidizer(subsidizer.id, cmd);
    helpers.setSubmitting(false);
    if (isErrorResponse(response)) {
      handleFormError(
        response,
        helpers,
        props.onFormSubmitionError,
        formI18nPrefix
      );
    } else {
      props.onFormSubmitted();
    }
  };

  return (
    <SubsidizerForm
      formName="update"
      initialValue={subsidizerViewModel}
      submitHandler={submitHandler}
      cancelHandler={props.onFormSubmitionCanceled}
    />
  );
};
