import { MainLayout } from "app/shared";
import { SideMenuArea } from "app/shared/layout";
import { SideMenu } from "app/shared/sideMenu";
import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { PrivateRoute } from "route";
import "../styles/aws-styles.css";
import "../styles/rideal-styles.css";
import HomePage from "./HomePage";
import { Login } from "./Login";
import NotFoundPage from "./NotFoundPage";
import { ProgrammeDetailsPage } from "./ProgrammeDetailsPage";
import ProgrammesListPage from "./ProgrammesListPage";
import RideProvidersListPage from "./RideProvidersListPage";
import SubsidizersListPage from "./SubsidizerListPage";
import { DeploymentDetailsPage } from "./DeploymentDetailsPage";
import { Simulator } from "app/simulator/Simulator";
import { DataGenerator } from "app/data-generator/DataGenerator";
import environment from "configurations";
import AccountsListPage from "./admin/AccountsListPage";
import ApiKeysListPage from "./admin/ApiKeysListPage";
import "moment-timezone";
import { Dashboard } from "app/programmes/dashboard";
import { useToast } from "@chakra-ui/react";
import { registerErrorEventHandler } from "utils/ErrorEventHandler";

const App = () => (
  <BrowserRouter>
    {
      registerErrorEventHandler(useToast())
    }
    <Switch>
      {/* Full page without main layout */}
      <Route path="/login" component={Login} />
      {/* Pages with main layout */}
      <Route>
        <MainLayout>
          <SideMenuArea>
            <SideMenu />
          </SideMenuArea>
          <Switch>
            {/* HOME */}
            <PrivateRoute exact path="/" Component={HomePage} />

            {/* PROGRAMMES */}
            <PrivateRoute path="/program" Component={ProgrammesListPage} />
            <PrivateRoute
              exact
              path="/programs/:id/details"
              Component={ProgrammeDetailsPage}
            />
            <PrivateRoute
              exact
              path="/programs/:id/dashboard" 
              Component={Dashboard}
            />

            {/* DEPLOYMENTS */}
            <PrivateRoute
              exact
              path="/programs/:programmeId/deployments/:deploymentId"
              Component={DeploymentDetailsPage}
            />
            <PrivateRoute
              path="/ride-providers"
              Component={RideProvidersListPage}
            />

            {/* SUBSIDIZERS */}
            <PrivateRoute
              path="/sponsors"
              Component={SubsidizersListPage}
            />

            {/* ADMIN - ACCOUNTS */}
            <PrivateRoute
              path="/admin/accounts"
              Component={AccountsListPage}
            />

            {/* ADMIN - API KEYS */}
            <PrivateRoute
              path="/admin/api-keys"
              Component={ApiKeysListPage}
            />

            {/* SHOW SIMULATOR ONLY IN PROD */}
            {environment.production && (
              <PrivateRoute path="/simulator" Component={Simulator} />
            )}

            {/* SHOW SIMULATOR AND DATA GENERATOR IN NON-PROD */}
            {!environment.production && (
              <PrivateRoute
                path="/simulation/simulator"
                Component={Simulator}
              />
            )}
            {!environment.production && (
              <PrivateRoute
                path="/simulation/data-generator"
                Component={DataGenerator}
              />
            )}

            <PrivateRoute path="/404" Component={NotFoundPage} />
            <Redirect from="*" to="/404" />
          </Switch>
        </MainLayout>
      </Route>
    </Switch>
  </BrowserRouter>
);

export default App;