import React, { useRef, useState, useEffect } from "react";
import { matchSorter } from "match-sorter";
import { usePopper } from "react-popper";
import { useCombobox, useMultipleSelection } from "downshift";
import { Box, Flex, FormControl, FormLabel, Text } from "@chakra-ui/react";
import { StyledInput } from "app/shared/forms/StyledComponents";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { Field } from "formik";
import { FieldInternalProps } from "app/shared/forms/GenericInput";
import i18next from "i18next";
import { SelectOption } from "app/shared/select";

function defaultOptionFilterFunc(
  items: Array<SelectOption>,
  inputValue: string
) {
  return matchSorter(items, inputValue, { keys: ["label"] });
}
export function CreateablePicker(props: any) {
  const {
    items,
    optionFilterFunc = defaultOptionFilterFunc,
    placeholder,
    onCreateItem,
    selectedItems,
    hideLabel = false,
    labelWidth = "30%",
    width,
    menuWidth,
    disabled = false,
    fieldName,
    formName,
    fieldType,
    flex,
    i18nextPrefix,
    ...downshiftProps
  } = props;

  const [isCreating, setIsCreating] = useState(false);
  const [inputItems, setInputItems] = useState(() => items);
  const disclosureRef = useRef(null);
  const popoverRef = useRef(null);
  const { attributes, forceUpdate } = usePopper(
    disclosureRef.current,
    popoverRef.current,
    {
      placement: "top-start",
      modifiers: [
        {
          name: "offset",
          options: {
            offset: [0, 8],
          },
        },
      ],
    }
  );
  const {
    getSelectedItemProps,
    getDropdownProps,
    addSelectedItem,
    removeSelectedItem,
    activeIndex,
  } = useMultipleSelection({
    ...downshiftProps,
    selectedItems,
    stateReducer: (_, actionAndChanges) => {
      const { type, changes } = actionAndChanges;
      switch (type) {
        case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem:
          return {
            ...changes,
            activeIndex: null,
          };
        default:
          return changes;
      }
    },
  });

  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    getItemProps,
    openMenu,
    selectItem,
    inputValue,
  } = useCombobox({
    selectedItem: null,
    items: inputItems,
    onInputValueChange: ({ inputValue }) => {
      const filteredItems = optionFilterFunc(items, inputValue || "");
      if (isCreating && filteredItems.length > 0) {
        setIsCreating(false);
      }
      setInputItems(filteredItems);
    },
    stateReducer: (state, actionAndChanges) => {
      const { changes, type } = actionAndChanges;
      switch (type) {
        case useCombobox.stateChangeTypes.InputBlur:
          return {
            ...changes,
            inputValue: "",
          };
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          return {
            ...changes,
            isOpen: true,
            inputValue: "",
          };
        default:
          return changes;
      }
    },
    onStateChange: ({ type, selectedItem }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          if (selectedItem) {
            if (selectedItemValues.includes(selectedItem["value"])) {
              removeSelectedItem(selectedItem);
            } else {
              if (onCreateItem && isCreating) {
                onCreateItem(selectedItem);
                setIsCreating(false);
                setInputItems(items);
              } else {
                addSelectedItem(selectedItem);
              }
            }

            selectItem(null);
          }
          break;
        default:
          break;
      }
    },
  });

  useEffect(() => {
    if (
      inputItems.length === 0 &&
      activeIndex === -1 &&
      inputValue.length > 0
    ) {
      setIsCreating(true);
      setInputItems([{ label: `${inputValue}`, value: inputValue }]);
    }
  }, [inputItems, setIsCreating,inputValue, activeIndex]);

  useEffect(() => {
    if (selectedItems && forceUpdate) {
      forceUpdate();
    }
  }, [selectedItems, forceUpdate]);

  const selectedItemValues = selectedItems.map(
    (item: SelectOption) => item.value
  );
  return (
    <Flex mb="10px" flex={flex} w="100%">
      {!hideLabel && (
        <Box width={labelWidth}>
          <FormLabel
            {...getLabelProps({
              className: "font-medium text-gray-700 text-xs mb-2 block",
            })}
          >
            {i18next.t(
              `${i18nextPrefix}:form.${formName}.fields.${fieldName}.label`
            )}
          </FormLabel>
        </Box>
      )}
      <Box width="100%">
        <div className="selRideProvider">
          {selectedItems.map((selectedItem: SelectOption, index: number) => (
            <span
              key={`selected-item-${index}`}
              className="textSelected_decoration" 
              {...getSelectedItemProps({ selectedItem, index })}
            >
              {selectedItem.label}
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  removeSelectedItem(selectedItem);
                }}
                type="button"
                className="text_X"
                aria-label="Remove small badge"
              >
                &#10005;
              </button>
            </span>
          ))}
        </div>

        <div className="relative" {...getComboboxProps()}>
          <Field name={fieldName} type={fieldType} placeholder={fieldName}>
            {(renderProps: FieldInternalProps) => (
              <FormControl
                isInvalid={
                  renderProps.form.errors[fieldName] &&
                  renderProps.form.touched[fieldName]
                }
              >
                <StyledInput
                  type={fieldType === "password" ? "password" : "text"}
                  {...renderProps.field}
                  id={fieldName}
                  fontSize="lg"
                  px={3}
                  py={1}
                  disabled={disabled}
                  {...getInputProps(
                    getDropdownProps({
                      className:
                        "w-full p-2 text-sm focus:outline-none focus:shadow-outline rounded border border-gray-400",
                      placeholder,
                      onClick: isOpen ? () => {} : openMenu,
                      onFocus: isOpen ? () => {} : openMenu,
                      ref: disclosureRef,
                    })
                  )}
                />
                <Text variant="alert-text">
                  {renderProps.form.errors[fieldName]}
                </Text>
              </FormControl>
            )}
          </Field>
          <div className="arrow_RP_dropdown">
            <button
              type="button"
              className="arrowBtnPosition"
              {...getToggleButtonProps()}
              aria-label={"toggle menu"}
            >
              <ChevronDownIcon boxSize="25px" />
            </button>
          </div>
        </div>
        <div
          className="popper"
          {...attributes.popper}
          {...getMenuProps({ ref: popoverRef, className: " w-full" })}
        >
          <ul className={isOpen ? "ul-RPlist" : ""}>
            {isOpen &&
              inputItems.map((item: typeof items, index: number) => (
                <li
                  className="listDropDown"
                  key={`${item.value}${index}`}
                  {...getItemProps({ item, index })}
                >
                  {isCreating ? (
                    <p>
                      <span>Create</span>{" "}
                      <span className="font-medium bg-yellow-300 text-yellow-900">
                        {item.label}
                      </span>
                    </p>
                  ) : (
                    <div className="flex items-center space-x-2">
                      {selectedItemValues.includes(item.value) && (
                        <span role="img" aria-label="Selected">
                          ✅
                        </span>
                      )}
                       <span>{item.label}</span> 
                    </div>
                  )}
                </li>
              ))}
          </ul>
        </div>
      </Box>
    </Flex>
  );
}
