import { Button, Icon, Stack, Text } from "@chakra-ui/react";
import { useAuthContext } from "authentication";
import { Auth } from "aws-amplify";
import { observer } from "mobx-react";
import React from "react";
import { BiLogOutCircle } from "react-icons/bi";

const UserLogoutBase: React.FC = () => {
  const { authStore } = useAuthContext();

  return (
    <Stack dir="column" spacing={1}>
      <Text variant="side-menu-user">{authStore.username}</Text>

      <Button
        rightIcon={<Icon as={BiLogOutCircle} color="black" boxSize="20px" />}
        variant="outline"
        onClick={async () => {
          await Auth.signOut();
          window.location.reload();
        }}>
        <Text variant="side-menu-logout">Logout</Text>
      </Button>
    </Stack>
  );
};

export const UserLogout = observer(UserLogoutBase);
