import React from "react";
import {
  CreateOrUpdateProgrammeFormViewModel,
  viewModel2CreateOrUpdateProgrammeCommand,
} from "../common/schema";
import { ProgrammeForm } from "../common/form";
import { ErrorResponse, isErrorResponse } from "models";
import environment from "configurations";
import { ProgrammeLimitPeriod } from "app/programmes/models";
import { useProgrammeStoreContext } from "app/programmes/stores/ProgrammeStore";

interface Props {
  onFormSubmitted: () => void;
  onFormSubmitionCanceled: () => void;
  onFormSubmitionError: (errorResponse: ErrorResponse, msg: string) => void;
}

export const CreateProgrammeForm: React.FC<Props> = (props: Props) => {
  const { programmeStore } = useProgrammeStoreContext();
  
  const defaultEmptyState: CreateOrUpdateProgrammeFormViewModel = {
    name: "",
    currency: environment.defaults.currency,
    timezone: environment.defaults.timezone,
    startDate: "",
    endDate: "",
    rideProviders: [],
    subsidizer: '',
    budget: 0,
    tripLimit: '',
    tripLimitPeriod: ProgrammeLimitPeriod.Day,
    subsidyLimit: '',
    subsidyLimitPeriod: ProgrammeLimitPeriod.Day
  };

  const submitHandler = async (
    formValues: CreateOrUpdateProgrammeFormViewModel
  ) => {
    if (programmeStore.state === 'pending') return;

    const res = await programmeStore.createProgramme(
      viewModel2CreateOrUpdateProgrammeCommand(formValues)
    );
    if (isErrorResponse(res)) {
      props.onFormSubmitionError(res, "");
    } else {
      props.onFormSubmitted();
    }
  };

  return (
    <ProgrammeForm
      formName="create"
      initialValues={defaultEmptyState}
      submitHandler={submitHandler}
      cancelHandler={props.onFormSubmitionCanceled}
    />
  );
};
