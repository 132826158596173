import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
} from "@chakra-ui/react";
import i18next from "i18next";
import { GenericInput } from "app/shared/forms/GenericInput";
import { RULE_LABELS_WIDTH } from ".";
import { FormikProps } from "formik";

export enum SubsidyUnit {
  Percentage = "percentage",
  MonetaryValue = "monetaryValue",
}

interface SubsidyUnitComponentProps {
  formikProps: FormikProps<any>;
  defaultSusbsidyUnit: SubsidyUnit;
}

export const SubsidyUnitComponent: React.FC<SubsidyUnitComponentProps> = (
  props: SubsidyUnitComponentProps
) => {
  const [subsidyUnit, setSubsidyUnit] = useState(props.defaultSusbsidyUnit);

  const onSubsidyUnitChanged = useCallback(
    (su: SubsidyUnit) => {
      props.formikProps.setFieldValue(
        "percentageSubsidy",
        su === SubsidyUnit.Percentage
      );
      if (su === SubsidyUnit.Percentage) {
        props.formikProps.setFieldValue("maxSubsidy", 100);
        props.formikProps.setFieldValue("distanceSubsidy", null);
      }
      setSubsidyUnit(su);
    },
    [props.formikProps]
  );

  // update selected subsidy unit (triggered on update to override default)
  useEffect(() => {
    setSubsidyUnit(props.defaultSusbsidyUnit);
  }, [props.defaultSusbsidyUnit]);

  return (
    <Box>
      <FormControl mb="10px">
        <Flex width={"100%"}>
          <Box width={RULE_LABELS_WIDTH} pt={1}>
            <FormLabel htmlFor="subsidyUnit">
              {i18next.t("rules:rule.subsidyUnit.label")}
            </FormLabel>
          </Box>

          <Flex flexDir="column" width="100%">
            <RadioGroup value={subsidyUnit} onChange={onSubsidyUnitChanged}>
              <Stack spacing={5} direction="row">
                <Radio value={SubsidyUnit.MonetaryValue}>
                  {i18next.t("rules:rule.subsidyUnit.monetaryValue.label")}
                </Radio>
                <Radio value={SubsidyUnit.Percentage}>
                  {i18next.t("rules:rule.subsidyUnit.percentage.label")}
                </Radio>
              </Stack>
            </RadioGroup>
          </Flex>
        </Flex>
      </FormControl>

      <GenericInput
        i18nextPrefix="rules"
        formName="common"
        fieldName="baseSubsidy"
        placeholderOverride={i18next.t(
          subsidyUnit === SubsidyUnit.MonetaryValue
            ? "rules:form.common.fields.baseSubsidy.placeholder"
            : "rules:form.common.fields.subsidyPercentage.placeholder"
        )}
        labelOverride={i18next.t(
          subsidyUnit === SubsidyUnit.MonetaryValue
            ? "rules:form.common.fields.baseSubsidy.placeholder"
            : "rules:form.common.fields.subsidyPercentage.placeholder"
        )}
        fieldType="number"
        labelWidth={RULE_LABELS_WIDTH}
      />

      {subsidyUnit === SubsidyUnit.MonetaryValue && (
        <GenericInput
          i18nextPrefix="rules"
          formName="common"
          fieldName="distanceSubsidy"
          fieldType="number"
          labelWidth={RULE_LABELS_WIDTH}
        />
      )}

      {subsidyUnit === SubsidyUnit.MonetaryValue && (
        <GenericInput
          i18nextPrefix="rules"
          formName="common"
          fieldName="maxSubsidy"
          fieldType="number"
          labelWidth={RULE_LABELS_WIDTH}
        />
      )}
    </Box>
  );
};
