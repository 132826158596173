import { RuleItemType } from "./../../models/index";
import * as Yup from "yup";
import i18next from "i18next";
import { CreateOrUpdateRuleCommand } from "app/rules/models/commands";

const formI18nPrefix = "rules:rule.errors.validation";

export const CreateOrUpdateRuleFormSchema = Yup.object({
  name: Yup.string().required(i18next.t(`${formI18nPrefix}.name.notblank`)),
  baseSubsidy: Yup.number()
    .min(0, i18next.t(`${formI18nPrefix}.baseSubsidy.min`))
    .required(i18next.t(`${formI18nPrefix}.baseSubsidy.notblank`)),
  distanceSubsidy: Yup.number()
    .min(0, i18next.t(`${formI18nPrefix}.baseSubsidy.min`))
    .nullable(),
  maxSubsidy: Yup.number()
    .min(
      Yup.ref("baseSubsidy"),
      i18next.t(`${formI18nPrefix}.maxSubsidy.underBaseSubsidy`)
    )
    .required(i18next.t(`${formI18nPrefix}.maxSubsidy.notblank`)),
  percentageSubsidy: Yup.boolean().required(),
  items: Yup.array()
    .of(
      Yup.object().shape({
        isStart: Yup.boolean(),
        isEnd: Yup.boolean(),
        type: Yup.mixed<RuleItemType>()
          .required()
          .oneOf(Object.values(RuleItemType)),
        criteria: Yup.object().shape({
          id: Yup.string().required(),
          name: Yup.string().required(),
          type: Yup.string().required(),
        }),
      })
    )
    .min(1, i18next.t(`${formI18nPrefix}.items.min`)),
}).defined();

export type RuleViewModel = Yup.InferType<typeof CreateOrUpdateRuleFormSchema>;

export const viewModel2CreateOrUpdateRuleCommand = (
  formValues: RuleViewModel
): CreateOrUpdateRuleCommand => {
  return {
    name: formValues.name,
    baseSubsidy: Number(formValues.baseSubsidy),
    maxSubsidy: formValues.percentageSubsidy
      ? 100
      : Number(formValues.maxSubsidy),
    distanceSubsidy: formValues.percentageSubsidy
      ? 0
      : Number(formValues.distanceSubsidy ? formValues.distanceSubsidy : 0),
    items: formValues.items,
    percentageSubsidy: formValues.percentageSubsidy,
  };
};
