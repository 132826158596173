import * as Yup from "yup";
import i18next from "i18next";
import { CreateOrUpdateSubsidizerCommand } from "app/subsidizers/models/commands";

const formI18nPrefix = "subsidizers:subsidizer.errors.validation";

export const CreateOrUpdateSubsidizerFormSchema = Yup.object({
  name: Yup.string().required(i18next.t(`${formI18nPrefix}.name.notblank`)),
  description: Yup.string().required(
    i18next.t(`${formI18nPrefix}.description.notblank`)
  ),
}).defined();

export type SubsidizerViewModel = Yup.InferType<
  typeof CreateOrUpdateSubsidizerFormSchema
>;

export const viewModel2CreateOrUpdateSubsidizerCommand = (
  formValues: SubsidizerViewModel
): CreateOrUpdateSubsidizerCommand => {
  return {
    name: formValues.name,
    description: formValues.description,
  };
};
