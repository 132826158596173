import {
  Tab as ChakraTab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs as ChakraTabs,
} from "@chakra-ui/react";
import i18next from "i18next";
import React, { ReactNode } from "react";

export interface Tab {
  i18nTitle: string;
  content: ReactNode;
  disabled?: boolean;
}

interface Props {
  tabs: Tab[];
}

export const Tabs: React.FC<Props> = ({ tabs }) => {
  return (
    <ChakraTabs isFitted>
      <TabList>
        {tabs.map((tab, index) => (
          <ChakraTab
            key={`tab_${index}`}
            _active={{
              outline: "none",
              boxShadow: "unset",
              background: "unset",
            }}
            _focus={{
              outline: "none",
              boxShadow: "unset",
            }}
            _selected={{
              fontWeight: "bold",
              color:"#12a19a",
            }}
            isDisabled={tab.disabled}>
            {i18next.t(tab.i18nTitle)}
          </ChakraTab>
        ))}
      </TabList>
      <TabPanels>
        {tabs.map((tab, index) => (
          <TabPanel key={`tab-panel_${index}`}>{tab.content}</TabPanel>
        ))}
      </TabPanels>
    </ChakraTabs>
  );
};
