import { Text, Box, Flex, FormControl, FormLabel } from "@chakra-ui/react";
import { Field } from "formik";
import i18next from "i18next";
import React from "react";
import { StyledCheckbox } from "..";
import { DEFAULT_LABEL_WIDTH } from "./GenericInput";

export interface CheckboxItem {
  value: string;
  label: string;
}

export interface CheckboxGroupProps {
  fieldName: string;
  formName: string;
  i18nextPrefix: string;
  hideLabel?: boolean;
  labelWidth?: string;
  items: CheckboxItem[];
  values: string[];
  onChange: (value: string, checked: boolean) => void;
}

export interface FieldInternalProps {
  field: any;
  form: any;
}

export const CheckboxGroup: React.FC<CheckboxGroupProps> = (
  props: CheckboxGroupProps
) => {
  const {
    onChange,
    values,
    items,
    fieldName,
    formName,
    hideLabel,
    i18nextPrefix,
  } = props;
  return (
    <Field name={fieldName}>
      {(renderProps: FieldInternalProps) => (
        <FormControl
          mb="10px"
          isInvalid={
            renderProps.form.errors[fieldName] &&
            renderProps.form.touched[fieldName]
          }>
          <Flex width={"100%"}>
            {!hideLabel && (
              <Box width={props.labelWidth ?? DEFAULT_LABEL_WIDTH} pt={1}>
                <FormLabel htmlFor={fieldName}>
                  {i18next.t(
                    `${i18nextPrefix}:form.${formName}.fields.${fieldName}.label`
                  )}
                </FormLabel>
              </Box>
            )}
            <Flex flexDir="column" width="100%">
              {items.map((item: CheckboxItem, index: number) => {
                return (
                  <Box key={index}>
                    <StyledCheckbox
                      size="md"
                      key={item.value}
                      defaultChecked={
                        values.findIndex((v) => v === item.value) !== -1
                      }
                      onChange={(e) => onChange(item.value, e.target.checked)}>
                      {item.label}
                    </StyledCheckbox>
                  </Box>
                );
              })}
              <Text variant="alert-text">
                {renderProps.form.errors[fieldName]}
              </Text>
            </Flex>
          </Flex>
        </FormControl>
      )}
    </Field>
  );
};
