import React from "react";
import { ProgrammeLimitPeriod, ProgrammeLimitType } from "app/programmes/models";
import i18next from "i18next";
import { GenericInputSelect } from "app/shared/forms/GenericInputSelect";

interface LimitProps {
  type: ProgrammeLimitType,
  period: ProgrammeLimitPeriod
}

export const LimitComponent: React.FC<LimitProps> = (props: LimitProps) => {

  const periodOptions = Object.keys(ProgrammeLimitPeriod)
    .map(period => {
      return {
        value: period,
        label: i18next.t(`programmes:enums.ProgrammeLimitPeriod.${period}`)
      }
    });
  return (
    <GenericInputSelect
        i18nextPrefix="programmes"
        formName="create"
        inputName={props.type.toLocaleLowerCase() + "Limit"}
        selectName={props.type.toLocaleLowerCase() + "LimitPeriod"}
        inputType="string"
        selectType="string"
        options={periodOptions}
        selectedOption={periodOptions.filter(p => p.value === props.period)[0]}
    />
  );
};
