import { action, makeObservable, observable, runInAction } from "mobx";
import {
  BackendResponseStatus,
  SortOrder,
  PageResponse,
  isErrorResponse,
  ErrorResponse,
} from "models";
import React, { useContext } from "react";
import { RuleListItem, Rule } from "../models";
import { CreateOrUpdateRuleCommand } from "../models/commands";
import { getRulePage, getRule, createRule, updateRule } from "../services";

/**
 * Store
 */
export default class RuleStore {
  rules: RuleListItem[] = [];
  selectedRule: Rule | null = null;
  totalRules = 0;
  state: BackendResponseStatus = "pending";

  constructor() {
    makeObservable(this, {
      rules: observable,
      selectedRule: observable,
      totalRules: observable,
      state: observable,
      fetchRules: action,
      fetchRule: action,
      createRule: action,
      updateRule: action
    });
  }

  async fetchRules(
    programmeId: string,
    page: number,
    limit: number,
    sortField?: string,
    sortOrder?: SortOrder
  ) {
    try {
      runInAction(() => {
        this.state = "pending";
      });
      const pagedResults: PageResponse<RuleListItem> = await getRulePage(
        programmeId,
        page,
        limit,
        sortField,
        sortOrder
      );
      runInAction(() => {
        this.state = "done";
        this.rules = pagedResults.data;
        this.totalRules = pagedResults.meta.totalElements;
      });
    } catch (error) {
      runInAction(() => {
        this.state = "error";
      });
    }
  }

  async fetchRule(programmeId: string, id: string) {
    runInAction(() => {
      this.state = "pending";
    });
    const response = await getRule(programmeId, id);
    runInAction(() => {
      if (isErrorResponse(response)) {
        this.state = "error";
        this.selectedRule = null;
      } else {
        this.state = "done";
        this.selectedRule = response;
      }
    });
  }

  async createRule(programmeId: string, cmd: CreateOrUpdateRuleCommand): Promise<Rule | ErrorResponse> {
    runInAction(() => {
      this.state = "pending";
    });
    const response = await createRule(programmeId, cmd);
    runInAction(() => {
      if (isErrorResponse(response)) {
        this.state = "error";
      } else {
        this.state = "done";
      }
    });
    return response;
  }

  async updateRule(
    programmeId: string,
    ruleId:string,
    cmd: CreateOrUpdateRuleCommand
  ): Promise<Rule | ErrorResponse> {
    runInAction(() => {
      this.state = "pending";
    });
    const response = await updateRule(programmeId, ruleId, cmd);
    runInAction(() => {
      if (isErrorResponse(response)) {
        this.state = "error";
      } else {
        this.state = "done";
      }
    });
    return response;
  }
}

/**
 * Context
 */
interface RuleStoreContextProps {
  ruleStore: RuleStore;
}

export const RuleStoreContext = React.createContext<RuleStoreContextProps>(
  {} as RuleStoreContextProps
);

export const useRuleStoreContext = () => useContext(RuleStoreContext);

/**
 * Provider
 */
interface Props {
  children: React.ReactNode;
}

export const RuleStoreProvider: React.FC<Props> = ({ children }) => {
  const ruleStore = new RuleStore();

  return (
    <RuleStoreContext.Provider value={{ ruleStore }}>
      {children}
    </RuleStoreContext.Provider>
  );
};
