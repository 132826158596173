import { observable, makeObservable, action, runInAction } from "mobx";
import {
  BackendResponseStatus,
  SortOrder,
  PageResponse,
  isErrorResponse,
  ErrorResponse,
} from "models";
import React, { useContext } from "react";
import { Subsidizer } from "../models";
import { CreateOrUpdateSubsidizerCommand } from "../models/commands";
import { getSubsidizers, createSubsidizer, updateSubsidizer } from "../services";

/**
 * Store
 */
export default class SubsidizerStore {
  subsidizers: Subsidizer[] = [];
  totalSubsidizers = 0;
  state: BackendResponseStatus = "pending";

  constructor() {
    makeObservable(this, {
      subsidizers: observable,
      totalSubsidizers: observable,
      state: observable,
      fetchSubsidizers: action,
      createSubsidizer: action,
      updateSubsidizer: action
    });
  }

  async fetchSubsidizers(
    page: number,
    limit: number,
    sortField?: string,
    sortOrder?: SortOrder
  ) {
    try {
      runInAction(() => {
        this.state = "pending";
      });
      const pagedResults: PageResponse<Subsidizer> = await getSubsidizers(
        page,
        limit,
        sortField,
        sortOrder
      );
      runInAction(() => {
        this.state = "done";
        this.subsidizers = pagedResults.data;
        this.totalSubsidizers = pagedResults.meta.totalElements;
      });
    } catch (error) {
      runInAction(() => {
        this.state = "error";
      });
    }
  }

  async createSubsidizer(cmd: CreateOrUpdateSubsidizerCommand): Promise<Subsidizer | ErrorResponse> {
    runInAction(() => {
      this.state = "pending";
    });
    const response = await createSubsidizer(cmd);
    runInAction(() => {
      if (isErrorResponse(response)) {
        this.state = "error";
      } else {
        this.state = "done";
      }
    });
    return response;
  }

  async updateSubsidizer(subsidizerId: string, cmd: CreateOrUpdateSubsidizerCommand): Promise<Subsidizer | ErrorResponse> {
    runInAction(() => {
      this.state = "pending";
    });
    const response = await updateSubsidizer(subsidizerId, cmd);
    runInAction(() => {
      if (isErrorResponse(response)) {
        this.state = "error";
      } else {
        this.state = "done";
      }
    });
    return response;
  }
}

/**
 * Context
 */
interface SubsidizerStoreContextProps {
  subsidizerStore: SubsidizerStore;
}

const SubsidizerStoreContext = React.createContext<SubsidizerStoreContextProps>(
  {} as SubsidizerStoreContextProps
);

export const useSubsidizerStoreContext = () =>
  useContext(SubsidizerStoreContext);

/**
 * Provider
 */
interface Props {
  children: React.ReactNode;
}

export const SubsidizerStoreProvider: React.FC<Props> = ({ children }) => {
  const subsidizerStore = new SubsidizerStore();
  return (
    <SubsidizerStoreContext.Provider value={{ subsidizerStore }}>
      {children}
    </SubsidizerStoreContext.Provider>
  );
};
