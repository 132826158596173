import { Deployment, DeploymentListItem } from "../models/index";
import { PageResponse, SortOrder } from "models";
import { ProgrammeDeploymentCandidate } from "../models/index";
import { axios } from "network";
import { handleAxiosError } from "utils/ErrorEventHandler";

export const getDeployments = async (
  programmeId: string,
  page: number,
  size: number,
  sortField?: string,
  sortOrder?: SortOrder
): Promise<PageResponse<DeploymentListItem>> => {
  const axiosInstance = await axios();
  const paginationParams = `/programmes/${programmeId}/deployments?page[number]=${page}&page[size]=${size}${
    sortField && sortOrder ? `&sort[${sortField}]=${sortOrder}` : ""
  }`;
  const rawResponse = await axiosInstance.get<PageResponse<DeploymentListItem>>(
    paginationParams
  );
  return { ...rawResponse.data };
};

export const getDeployment = async (
  programmeId: string,
  deploymentId: string
): Promise<Deployment> => {
  const axiosInstance = await axios();
  return axiosInstance
    .get<Deployment>(`/programmes/${programmeId}/deployments/${deploymentId}`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => handleAxiosError(error));
};

export const getDeploymentCandidate = async (
  programmeId: string
): Promise<ProgrammeDeploymentCandidate> => {
  const axiosInstance = await axios();
  return axiosInstance
    .get<ProgrammeDeploymentCandidate>(
      `/programmes/${programmeId}/deployment-candidate`
    )
    .then((response) => {
      return response.data;
    })
    .catch((error) => handleAxiosError(error));
};

export const deploy = async (programmeId: string): Promise<void> => {
  const axiosInstance = await axios();
  const response = await axiosInstance
    .post<void>(`/programmes/${programmeId}/deploy`);
    return response.data;
};

export const activate = async (programmeId: string): Promise<void> => {
  const axiosInstance = await axios();
  return axiosInstance
    .post<void>(`/programmes/${programmeId}/activate`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => handleAxiosError(error));
};

export const deactivate = async (programmeId: string): Promise<void> => {
  const axiosInstance = await axios();
  return axiosInstance
    .post<void>(`/programmes/${programmeId}/deactivate`)
    .then((response) => {
      return response.data;
    })
    .catch((error) => handleAxiosError(error));
};
