import { FormikHelpers } from "formik";
import i18next from "i18next";
import { ErrorResponse, FieldError } from "models";

/**
 * Used in the context of a form (Formik) to handle server side errors
 */
export const handleFormError = (
  errorResponse: ErrorResponse,
  helpers: FormikHelpers<FieldError>,
  errorHandler: (error: ErrorResponse, msg: string) => void,
  i18nPrefix?: string
) => {
  switch (errorResponse.httpStatusCode) {
    case 400: {
      errorResponse?.errors?.forEach((fieldError: FieldError) => {
        helpers.setFieldError(
          fieldError.field,
          i18nPrefix ? i18next.t(`${i18nPrefix}.${fieldError.errorCode}`) : ""
        );
      });
      break;
    }
    case 401: {
      errorHandler(
        errorResponse,
        i18nPrefix ? i18next.t(`shared:network.errors.authentication`) : ""
      );
      break;
    }
    case 403: {
      errorHandler(
        errorResponse,
        i18nPrefix ? i18next.t(`shared:network.errors.authorization`) : ""
      );
      break;
    }
    default: {
      errorHandler(
        errorResponse,
        i18nPrefix ? i18next.t(`shared:network.errors.unexpectedError`) : ""
      );
    }
  }
};
