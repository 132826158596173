import { ChevronDownIcon, CloseIcon } from "@chakra-ui/icons";
import { Box, Text } from "@chakra-ui/react";
import i18next from "i18next";
import React from "react";
import { components, default as ReactSelect } from "react-select";
import { customColors } from "styles/theme";

export interface SelectOption {
  value: string;
  label: string;
}

interface Props {
  defaultValue?: SelectOption;
  value?: SelectOption[];
  disabled?: boolean;
  className?: string;
  label?: string;
  placeholder?: string;
  noOptionsMessage?: string;
  onChange: (options: any) => void;
  options: SelectOption[];
  width?: string;
  menuWidth?: number;
  menuMarginTop?: number;
  fontSize?: number;
  onInputChange?: (value: string) => void;
  isMulti?: boolean;
  hideDropdownIndicator?: boolean;
  borderBottom?: number;
}

const selectStyles = {
  control: (base: any, state: any) => ({
    ...base,
    backgroundColor: "white",
    border: state.isFocused ? 0 : 0,
    width: state.selectProps.width ?? base.width,
    boxShadow: state.isFocused ? 0 : 0,
    "&:hover": {
      border: state.isFocused ? 0 : 0,
    },
    padding: 0,
    cursor: "pointer",
    minHeight: "initial"
  }),
  option: (base: any, state: any) => ({
    ...base,
    textTransform: "uppercase",
    fontSize: state.selectProps.fontSize ?? "16px",
    backgroundColor: state.isSelected
      ? customColors.brand[500]
      : state.data.bg ?? "white",
    color: state.isSelected ? "white" : state.data.color ?? "black",
    "&:hover": {
      backgroundColor: customColors.brand[500],
      opacity: 0.3,
      color: "white",
    },
  }),
  indicatorSeparator: (base: any) => ({
    ...base,
    display: "none",
  }),
  clearIndicator: (base: any) => ({
    ...base,
    padding: 0,
  }),
  dropdownIndicator: (base: any, state: any) => ({
    ...base,
    color: "black",
    "&:hover": {
      color: "black",
    },
    display: state.selectProps.hideDropdownIndicator ? "none" : undefined,
    padding: 0,
  }),
  menu: (base: any, state: any) => ({
    ...base,
    width: state.selectProps.menuWidth ?? base.width,
    borderRadius: 0,
    border: "1px solid",
    borderColor: "black",
    marginTop: state.selectProps.menuMarginTop ?? base.marginTop,
    marginLeft: -1,
    boxShadow: 0,
    zIndex: 3,
  }),
  valueContainer: (base: any, state: any) => ({
    ...base,
    fontSize: state.selectProps.fontSize ?? "16px",
    textTransform: "uppercase",
    padding: 0,
  }),
  multiValue: (base: any, state: any) => ({
    ...base,
    margin: "0 5px 5px 0",
  }),
  multiValueLabel: (base: any, state: any) => ({
    ...base,
    backgroundColor: "white",
    color: "black",
    borderRadius: 0,
    borderColor: "black",
    border: "1px solid",
    borderRight: 0,
  }),
  multiValueRemove: (base: any, state: any) => ({
    ...base,
    backgroundColor: "white",
    borderRadius: 0,
    borderColor: "black",
    border: "1px solid",
    borderLeft: 0,
    "&:hover": {
      color: "black",
      backgroundColor: "white",
    },
  }),
  placeholder: (base: any, state: any) => ({
    ...base,
    fontSize: "12px",
    color: "black",
  }),
};

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <ChevronDownIcon boxSize="24px" color="grey.600" />
    </components.DropdownIndicator>
  );
};

const MultiValueRemove = (props: any) => {
  return (
    <components.MultiValueRemove {...props}>
      <CloseIcon boxSize="24px" color="grey.600" />
    </components.MultiValueRemove>
  );
};

export const Select: React.FC<Props> = ({
  disabled,
  className,
  label,
  placeholder,
  onChange,
  options,
  defaultValue,
  width,
  menuWidth,
  menuMarginTop,
  isMulti,
  onInputChange,
  value,
  hideDropdownIndicator,
  borderBottom,
  noOptionsMessage,
  ...rest
}) => {
  const fontSize = "16px";

  return (
    <Box {...(rest as any)}>
      {label && (
        <Text fontSize={1} textTransform="uppercase">
          {label}
        </Text>
      )}
      <ReactSelect
        className={`selectBox ${className ? className : ''}`}
        isDisabled={disabled}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        options={options}
        styles={selectStyles}
        components={{ DropdownIndicator, MultiValueRemove }}
        defaultValue={defaultValue}
        width={width}
        menuWidth={menuWidth}
        menuMarginTop={menuMarginTop}
        fontSize={fontSize}
        isMulti={isMulti}
        onInputChange={onInputChange}
        hideDropdownIndicator={hideDropdownIndicator}
        noOptionsMessage={() => (noOptionsMessage ? noOptionsMessage : null)}
        captureMenuScroll={false}
      />
    </Box>
  );
};

Select.defaultProps = {
  disabled: false,
  borderBottom: 0,
  noOptionsMessage: i18next.t("shared:select.noResults"),
};
