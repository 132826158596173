import {
  Box,
  Button,
  Checkbox,
  FormLabel,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useCriteriaStoresContext } from "app/criteria/stores/CriteriaStore";
import { FormActions } from "app/shared/forms/FormActions";
import { GenericInput } from "app/shared/forms/GenericInput";
import { handleFormError } from "app/shared/forms/utils";
import { Form, Formik, FormikHelpers } from "formik";
import i18next from "i18next";
import { ErrorResponse, isErrorResponse } from "models";
import React, { useReducer } from "react";
import { ScheduleCriteria } from "../../models";
import {
  ScheduleCriteriaFormSchema,
  ScheduleCriteriaViewModel,
  selectDayOfWeekValues,
  viewModel2CreateScheduleCriteriaCommand,
  viewModel2UpdateScheduleCriteriaCommand,
} from "./schema";

const formI18nPrefix = "programmes:form.createScheduleCriteria";

interface FormProps {
  initialState: ScheduleCriteriaViewModel;
  submitHandler: (
    formValues: ScheduleCriteriaViewModel,
    helpers: FormikHelpers<any>
  ) => void;
  cancelHandler: () => void;
}

const ScheduleCriteriaForm: React.FC<FormProps> = (props: FormProps) => {
  const { initialState, submitHandler } = props;

  const selectedDaysOfWeek: any[] = props.initialState.daysOfWeek;
  const { criteriaStore } = useCriteriaStoresContext();
  const [, forceUpdate] = useReducer((x) => x + 1, 0);

  const onDayOfWeekChanged = (dayOfWeek: string, checked: boolean) => {
    if (checked) {
      selectedDaysOfWeek.push(dayOfWeek);
    } else {
      selectedDaysOfWeek.splice(selectedDaysOfWeek.indexOf(dayOfWeek), 1);
    }
    forceUpdate();
  };

  const onFormSubmitted = (
    formValues: ScheduleCriteriaViewModel,
    helpers: FormikHelpers<any>
  ) => {
    formValues.daysOfWeek = selectedDaysOfWeek;
    submitHandler(formValues, helpers);
  };

  return (
    <Formik
      initialValues={initialState}
      validationSchema={ScheduleCriteriaFormSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={onFormSubmitted}
    >
      {(formikProps) => (
        <Form>
          <Box py={2}>
            <GenericInput
              i18nextPrefix="programmes"
              formName="createScheduleCriteria"
              fieldName="name"
              fieldType="string"
            />

            <GenericInput
              i18nextPrefix="programmes"
              formName="createScheduleCriteria"
              fieldName="startTime"
              fieldType="string"
            />
            <GenericInput
              i18nextPrefix="programmes"
              formName="createScheduleCriteria"
              fieldName="endTime"
              fieldType="string"
            />

            <br />
            <Stack spacing={3}>
              <Text fontSize="xl">
                {i18next.t(
                  "programmes:form.createScheduleCriteria.fields.daysOfWeek.label"
                )}
              </Text>
              {selectDayOfWeekValues &&
                selectDayOfWeekValues.map((doy, index) => {
                  return (
                    <Checkbox
                      size="md"
                      key={index}
                      isChecked={selectedDaysOfWeek.indexOf(doy.value) >= 0}
                      onChange={(e) =>
                        onDayOfWeekChanged(doy.value, e.target.checked)
                      }
                    >
                      {doy.label}
                    </Checkbox>
                  );
                })}
              <Box>
                <Box margin="10px">
                  <FormLabel width="30%">
                    {i18next.t(
                      "programmes:form.createScheduleCriteria.fields.predefined.label"
                    )}
                  </FormLabel>
                </Box>
                <Box>
                  <Button
                    margin="5px"
                    onClick={() => {
                      selectedDaysOfWeek.splice(0, selectedDaysOfWeek.length);
                      selectedDaysOfWeek.push(
                        "MONDAY",
                        "TUESDAY",
                        "WEDNESDAY",
                        "THURSDAY",
                        "FRIDAY"
                      );
                      initialState.startTime = "09:00";
                      initialState.endTime = "18:00";
                      forceUpdate();
                    }}
                  >
                    {i18next.t(
                      "programmes:form.createScheduleCriteria.fields.predefined.work_week"
                    )}
                  </Button>
                  <Button
                    margin="5px"
                    onClick={() => {
                      selectedDaysOfWeek.splice(0, selectedDaysOfWeek.length);
                      selectedDaysOfWeek.push("FRIDAY", "SATURDAY", "SUNDAY");
                      initialState.startTime = "10:00";
                      initialState.endTime = "22:00";
                      forceUpdate();
                    }}
                  >
                    {i18next.t(
                      "programmes:form.createScheduleCriteria.fields.predefined.weekends"
                    )}
                  </Button>
                  <Button
                    margin="5px"
                    onClick={() => {
                      selectedDaysOfWeek.splice(0, selectedDaysOfWeek.length);
                      selectedDaysOfWeek.push(
                        "MONDAY",
                        "TUESDAY",
                        "WEDNESDAY",
                        "THURSDAY",
                        "FRIDAY",
                        "SATURDAY",
                        "SUNDAY"
                      );
                      initialState.startTime = "00:00";
                      initialState.endTime = "23:59";
                      forceUpdate();
                    }}
                  >
                    {i18next.t(
                      "programmes:form.createScheduleCriteria.fields.predefined.everyday"
                    )}
                  </Button>
                </Box>
              </Box>
              <Text variant="alert-text">
                {formikProps.errors["daysOfWeek"]}
              </Text>
            </Stack>
            <FormActions
              submitText={i18next.t(
                "programmes:form.createScheduleCriteria.submit"
              )}
              cancelHandler={props.cancelHandler}
              disableSubmit={criteriaStore.state === "pending"}
            />
          </Box>
        </Form>
      )}
    </Formik>
  );
};

interface CreateScheduleFormProps {
  programmeId: string;
  onFormSubmitted: () => void;
  onFormSubmitionError: (error: ErrorResponse, message: string) => void;
  onFormSubmitionCanceled: () => void;
}

export const CreateScheduleCriteriaForm: React.FC<CreateScheduleFormProps> = (
  props: CreateScheduleFormProps
) => {
  const { programmeId } = props;
  const { criteriaStore } = useCriteriaStoresContext();

  const submitHandler = async (
    formValues: ScheduleCriteriaViewModel,
    helpers: FormikHelpers<any>
  ) => {
    if (criteriaStore.state === "pending") return;
    helpers.setSubmitting(true);
    const response = await criteriaStore.createCriteria(
      programmeId,
      viewModel2CreateScheduleCriteriaCommand(formValues)
    );
    helpers.setSubmitting(false);
    if (isErrorResponse(response)) {
      handleFormError(
        response,
        helpers,
        props.onFormSubmitionError,
        formI18nPrefix
      );
    } else {
      props.onFormSubmitted();
    }
  };

  const emptyState: ScheduleCriteriaViewModel = {
    name: "",
    daysOfWeek: [],
    startTime: "00:00",
    endTime: "00:00",
  };

  return (
    <ScheduleCriteriaForm
      submitHandler={submitHandler}
      cancelHandler={props.onFormSubmitionCanceled}
      initialState={emptyState}
    />
  );
};

interface UpdateScheduleFormProps {
  programmeId: string;
  scheduleCriteria: ScheduleCriteria;
  onFormSubmitted: () => void;
  onFormSubmitionCanceled: () => void;
  onFormSubmitionError: (error: ErrorResponse, message: string) => void;
}

export const UpdateScheduleCriteriaForm: React.FC<UpdateScheduleFormProps> = (
  props: UpdateScheduleFormProps
) => {
  const { programmeId, scheduleCriteria } = props;
  const { criteriaStore } = useCriteriaStoresContext();

  const submitHandler = async (
    formValues: ScheduleCriteriaViewModel,
    helpers: FormikHelpers<any>
  ) => {
    if (criteriaStore.state === "pending") return;
    helpers.setSubmitting(true);
    const response = await criteriaStore.updateCriteria(
      programmeId,
      scheduleCriteria.id,
      viewModel2UpdateScheduleCriteriaCommand(scheduleCriteria.id, formValues)
    );
    helpers.setSubmitting(false);
    if (isErrorResponse(response)) {
      handleFormError(
        response,
        helpers,
        props.onFormSubmitionError,
        formI18nPrefix
      );
    } else {
      props.onFormSubmitted();
    }
  };

  const currentScheduleCriteria: ScheduleCriteriaViewModel = scheduleCriteria;

  return (
    <ScheduleCriteriaForm
      submitHandler={submitHandler}
      cancelHandler={props.onFormSubmitionCanceled}
      initialState={currentScheduleCriteria}
    />
  );
};
