import React, { useContext } from "react";
import RideProviderStore from "./RideProviderStore";

interface StoreContextProps {
  rideProviderStore: RideProviderStore;
}

export const RideProviderStoresContext = React.createContext<StoreContextProps>(
  {} as StoreContextProps
);

export const useRideProviderStoresContext = () =>
  useContext(RideProviderStoresContext);
