import { Box } from "@chakra-ui/react";
import { FormActions } from "app/shared/forms/FormActions";
import { GenericInput } from "app/shared/forms/GenericInput";
import { useSubsidizerStoreContext } from "app/subsidizers/stores";
import { Form, Formik, FormikHelpers } from "formik";
import i18next from "i18next";
import React from "react";
import {
  CreateOrUpdateSubsidizerFormSchema,
  SubsidizerViewModel,
} from "./schema";

export interface Props {
  formName: string;
  initialValue: SubsidizerViewModel;
  submitHandler: (
    formValues: SubsidizerViewModel,
    helpers: FormikHelpers<any>
  ) => void;
  cancelHandler: () => void;
}

export const SubsidizerForm: React.FC<Props> = (props: Props) => {
  const { subsidizerStore } = useSubsidizerStoreContext();

  return (
    <Formik
      initialValues={props.initialValue}
      validationSchema={CreateOrUpdateSubsidizerFormSchema}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={props.submitHandler}>
      {(formikProps) => (
        <Form>
          <Box py={2}>
            <GenericInput
              autoFocus={true}
              i18nextPrefix="subsidizers"
              formName={props.formName}
              fieldName="name"
              fieldType="string"
            />

            <GenericInput
              i18nextPrefix="subsidizers"
              formName={props.formName}
              fieldName="description"
              fieldType="string"
            />
          </Box>
          <FormActions
            cancelHandler={props.cancelHandler}
            submitText={i18next.t(`subsidizers:form.${props.formName}.submit`)}
            disableSubmit={subsidizerStore.state === "pending"}/>
        </Form>
      )}
    </Formik>
  );
};
