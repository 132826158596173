import { Button, Stack, useToast } from "@chakra-ui/react";
import { ManagedModal } from "app/shared/modal";
import i18next from "i18next";
import { observer } from "mobx-react";
import { ErrorResponse } from "models";
import React from "react";
import { useState } from "react";
import { CreateSubsidizerForm } from "./forms/create";
import { SubsidizerStoreProvider, useSubsidizerStoreContext } from "./stores";
import { SubsidizerTable } from "./SubsidizerTable";

const SubsidizerListBase: React.FC = () => {
  const [openCreateSubsidizerModal, setOpenCreateSubsidizerModal] = useState(
    false
  );
  const { subsidizerStore } = useSubsidizerStoreContext();
  const toast = useToast();

  const onSubsidizerCreatedSuccess = () => {
    toast({
      title: i18next.t("subsidizers:form.create.success"),
      status: "success",
      isClosable: true,
    });
    setOpenCreateSubsidizerModal(false);
    // TODO: review these defaults
    subsidizerStore.fetchSubsidizers(0, 30);
  };

  const onSubsidizerCreateError = (error: ErrorResponse, msg: string) => {
    toast({ title: msg, status: "error", isClosable: true });
    setOpenCreateSubsidizerModal(false);
  };

  return (
    <SubsidizerStoreProvider>
      <Stack dir="column" spacing="3">
        <Button onClick={() => setOpenCreateSubsidizerModal(true)}>
          {i18next.t("subsidizers:form.create.openBtn")}
        </Button>
        <SubsidizerTable />

        {/* create subsidizer modal */}
        <ManagedModal
          openModal={openCreateSubsidizerModal}
          title={i18next.t("subsidizers:form.create.title")}
          content={
            <CreateSubsidizerForm
              onFormSubmitionError={onSubsidizerCreateError}
              onFormSubmitted={onSubsidizerCreatedSuccess}
              onFormSubmitionCanceled={() => setOpenCreateSubsidizerModal(false)}
            />
          }
          closeHandler={() => setOpenCreateSubsidizerModal(false)}
          showMainAction={false}
          showClose={false}
          closeOnOverlayClick={false}
        />
      </Stack>
    </SubsidizerStoreProvider>
  );
};

export const SubsidizerList = observer(SubsidizerListBase);
