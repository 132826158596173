import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { CreateScheduleCriteriaForm } from "./forms/schedule";
import i18next from "i18next";
import { CriteriaTable } from "./CriteriaTable";
import { ManagedModal } from "app/shared/modal";
import { CreateGeoCriteriaForm } from "./forms/geographic";
import { CreateTripAttributeCriteriaForm } from "./forms/tripAttribute";
import { useCriteriaStoresContext } from "./stores/CriteriaStore";

interface Props {
  programmeId: string;
}

type MenuOptions = "Schedule" | "Geographic" | "TripAttribute";

export const CriteriaList: React.FC<Props> = (props) => {
  const { programmeId } = props;

  const { criteriaStore } = useCriteriaStoresContext();
  const toast = useToast();
  const [selectedItem, setSelectedItem] = useState<MenuOptions | null>(null);

  const onError = (err: unknown, msg: string) => {
    setSelectedItem(null);
    toast({ title: msg, status: "error", isClosable: true });
  };

  const onSuccess = () => {
    setSelectedItem(null);
    toast({
      title: i18next.t("programmes:criteriaList.createSuccessful"),
      status: "success",
      isClosable: true,
    });
    // TODO: refactor these defaults
    criteriaStore.fetchCriteriaPage(programmeId, 0, 30, "id", "asc");
  };

  return (
    <Stack dir="column" spacing="3">
      <Menu closeOnSelect={true}>
        <MenuButton as={Button}>
          {i18next.t("programmes:criteriaList.newCriteriaBtn")}
        </MenuButton>
        <MenuList>
          <MenuItem
            onClick={() => {
              setSelectedItem("Schedule");
            }}>
            {i18next.t("programmes:criteria.schedule.label")}
          </MenuItem>
          <MenuItem
            onClick={() => {
              setSelectedItem("Geographic");
            }}>
            {i18next.t("programmes:criteria.geographic.label")}
          </MenuItem>
          <MenuItem
            onClick={() => {
              setSelectedItem("TripAttribute");
            }}>
            {i18next.t("programmes:criteria.tripAttribute.label")}
          </MenuItem>
        </MenuList>
      </Menu>

      {/* SCHEDULE CRITERIA MODAL */}
      <ManagedModal
        openModal={selectedItem === "Schedule"}
        title={i18next.t("programmes:form.createScheduleCriteria.title")}
        content={
          <CreateScheduleCriteriaForm
            programmeId={programmeId}
            onFormSubmitted={onSuccess}
            onFormSubmitionCanceled={() => {
              setSelectedItem(null);
            }}
            onFormSubmitionError={onError}
          />
        }
        closeHandler={() => setSelectedItem(null)}
        showMainAction={false}
        showClose={false}
        closeOnOverlayClick={false}
      />

      {/* GEOGRAPHIC CRITERIA MODAL */}
      <ManagedModal
        openModal={selectedItem === "Geographic"}
        title={i18next.t("programmes:form.createGeoCriteria.title")}
        content={
          <CreateGeoCriteriaForm
            programmeId={programmeId}
            onFormSubmitted={onSuccess}
            onFormSubmitionError={onError}
            onFormSubmitionCanceled={() => {
              setSelectedItem(null);
            }}
          />
        }
        closeHandler={() => setSelectedItem(null)}
        contentMinHeight={"350px"}
        showMainAction={false}
        showClose={false}
        size={"full"}
        closeOnOverlayClick={false}
      />

      {/* TRIP ATTRIBUTE CRITERIA MODAL */}
      <ManagedModal
        openModal={selectedItem === "TripAttribute"}
        title={i18next.t("programmes:form.createTripAttributeCriteria.title")}
        content={
          <CreateTripAttributeCriteriaForm
            programmeId={programmeId}
            onFormSubmitted={onSuccess}
            onFormSubmitionCanceled={() => setSelectedItem(null)}
            onFormSubmitionError={onError}
          />
        }
        closeHandler={() => setSelectedItem(null)}
        contentMinHeight={"350px"}
        showMainAction={false}
        showClose={false}
        closeOnOverlayClick={false}
      />

      <CriteriaTable programmeId={programmeId} />
    </Stack>
  );
};
