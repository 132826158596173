import { CreateCriteriaCommand } from './../../models/commands';
import i18next from "i18next";
import {
  DataType,
  DataTypeList,
  Operator,
  OperatorList,
} from "../../../../models/index";
import {
  UpdateCriteriaCommand,
} from "../../models/commands";
import * as Yup from "yup";
import { CriteriaType, TripAttributeCriteria } from "../../models";

const formI18nPrefix = "programmes:criteria.tripAttribute.errors.validation";

export const TripAttributeCriteriaFormSchema = Yup.object({
  name: Yup.string().required(i18next.t(`${formI18nPrefix}.name.notblank`)),
  field: Yup.string().required(i18next.t(`${formI18nPrefix}.field.notblank`)),
  value: Yup.string().required(i18next.t(`${formI18nPrefix}.value.notblank`)),
  operator: Yup.mixed<Operator>().oneOf(OperatorList),
  dataType: Yup.mixed<DataType>().oneOf(DataTypeList),
}).defined();

export type TripAttributeCriteriaViewModel = Yup.InferType<
  typeof TripAttributeCriteriaFormSchema
>;

export const selectOperatorValues = OperatorList.map((op) => {
  return {
    value: op,
    label: i18next.t(`shared:operator.${op}`),
  };
});

export const selectDataTypeValues = DataTypeList.map((dt) => {
  return {
    value: dt,
    label: i18next.t(`shared:dataType.${dt}`),
  };
});

export const tripAttributeCriteria2ViewModel = (
  criteria: TripAttributeCriteria
): TripAttributeCriteriaViewModel => {
  return {
    name: criteria.name,
    field: criteria.field,
    value: criteria.value,
    operator: criteria.operator,
    dataType: criteria.dataType,
  };
};

export const viewModel2CreateTripAttributeCriteriaCommand = (
  formValues: TripAttributeCriteriaViewModel
): CreateCriteriaCommand => {
  return {
    type: CriteriaType.TripAttribute,
    name: formValues.name,
    field: formValues.field,
    value: formValues.value,
    operator: formValues.operator,
    dataType: formValues.dataType,
  };
};

export const viewModel2UpdateTripAttributeCriteriaCommand = (
  id: string,
  formValues: TripAttributeCriteriaViewModel
): UpdateCriteriaCommand => {
  return {
    type: CriteriaType.TripAttribute,
    id: id,
    name: formValues.name,
    field: formValues.field,
    value: formValues.value,
    operator: formValues.operator,
    dataType: formValues.dataType,
  };
};
