import React from "react";
import { ErrorResponse, isErrorResponse } from "models";
import { handleFormError } from "app/shared/forms/utils";
import {
  SubsidizerViewModel,
  viewModel2CreateOrUpdateSubsidizerCommand,
} from "../common/schema";
import { SubsidizerForm } from "../common/form";
import { FormikHelpers } from "formik";
import { useSubsidizerStoreContext } from "app/subsidizers/stores";
import { Subsidizer } from "app/subsidizers/models";

const emptyState: SubsidizerViewModel = {
  name: "",
  description: "",
};

interface Props {
  onFormSubmitted: (subsidizer:Subsidizer) => void;
  onFormSubmitionCanceled: () => void;
  onFormSubmitionError: (errorResponse: ErrorResponse, msg: string) => void;
}

export const CreateSubsidizerForm: React.FC<Props> = (props: Props) => {
  const { subsidizerStore } = useSubsidizerStoreContext();
  const formI18nPrefix = "subsidizers:form.create";

  const submitHandler = async (
    formValues: SubsidizerViewModel,
    helpers: FormikHelpers<any>
  ) => {
    if(subsidizerStore.state === 'pending') return;
    
    helpers.setSubmitting(true);
    const cmd = viewModel2CreateOrUpdateSubsidizerCommand(formValues);
    const response = await subsidizerStore.createSubsidizer(cmd);
    helpers.setSubmitting(false);
    if (isErrorResponse(response)) {
      handleFormError(
        response,
        helpers,
        props.onFormSubmitionError,
        formI18nPrefix
      );
    } else {
      props.onFormSubmitted(response);
    }
  };

  return (
    <SubsidizerForm
      formName="create"
      initialValue={emptyState}
      submitHandler={submitHandler}
      cancelHandler={props.onFormSubmitionCanceled}
    />
  );
};
