import * as DEPLOYMENTS_TRANSLATIONS from '../app/deployments/locales/index';
import * as PROGRAMMES_TRANSLATIONS from "app/programmes/locales";
import * as RIDE_PROVIDERS_TRANSLATIONS from "app/rideProviders/locales";
import * as RULE_TRANSLATIONS from "app/rules/locales";
import * as SHARED_TRANSLATIONS from "app/shared/locales";
import * as SUBSIDIZERS_TRANSLATIONS from "app/subsidizers/locales";
import * as SIMULATOR_TRANSLATIONS from "app/simulator/locales";
import * as ACCOUNTS_TRANSLATIONS from "app/admin/accounts/locales";
import * as API_KEYS_TRANSLATIONS from "app/admin/api-keys/locales";
import { default as i18n, default as i18next } from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {},
    fallbackLng: "en",
    debug: false,
    defaultNS: "common",
    interpolation: {
      escapeValue: false,
    },
  });

i18next.addResourceBundle(
  "en",
  SHARED_TRANSLATIONS.SHARED_NAMESPACE,
  SHARED_TRANSLATIONS.EN
);

i18next.addResourceBundle(
  "en",
  SHARED_TRANSLATIONS.SHARED_NAMESPACE,
  SHARED_TRANSLATIONS.EN_TIMEZONES
);

i18next.addResourceBundle(
  "en",
  PROGRAMMES_TRANSLATIONS.PROGRAMMES_NAMESPACE,
  PROGRAMMES_TRANSLATIONS.EN
);

i18next.addResourceBundle(
  "en",
  DEPLOYMENTS_TRANSLATIONS.DEPLOYMENTS_NAMESPACE,
  DEPLOYMENTS_TRANSLATIONS.EN
);

i18next.addResourceBundle(
  "en",
  RIDE_PROVIDERS_TRANSLATIONS.RIDE_PROVIDERS_NAMESPACE,
  RIDE_PROVIDERS_TRANSLATIONS.EN
);

i18next.addResourceBundle(
  "en",
  RULE_TRANSLATIONS.RULE_TRANSLATIONS_NAMESPACE,
  RULE_TRANSLATIONS.EN
);

i18next.addResourceBundle(
  "en",
  SUBSIDIZERS_TRANSLATIONS.SUBSIDIZERS_TRANSLATIONS_NAMESPACE,
  SUBSIDIZERS_TRANSLATIONS.EN
);

i18next.addResourceBundle(
  "en",
  SIMULATOR_TRANSLATIONS.SIMULATOR_NAMESPACE,
  SIMULATOR_TRANSLATIONS.EN
);

i18next.addResourceBundle(
  "en",
  ACCOUNTS_TRANSLATIONS.ACCOUNTS_NAMESPACE,
  ACCOUNTS_TRANSLATIONS.EN
);

i18next.addResourceBundle(
  "en",
  API_KEYS_TRANSLATIONS.API_KEYS_NAMESPACE,
  API_KEYS_TRANSLATIONS.EN
);


export default i18n;
